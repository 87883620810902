import { AnyAction } from "redux";

import {
    IModelState,
} from "../../common";

import { ADD_MODEL_REQUEST, ADD_MODEL_SUCCESS, CLEAR_ADD_MODEL_SUCCESS, DELETE_MODEL, GET_MODELS_ERROR, GET_MODELS_REQUEST, GET_MODELS_SUCCESS, SELECT_MODEL, SET_COLLECTIONS_TAB_ACTIVE, SET_CONFIGURATION_TAB_ACTIVE, SET_DATA_TAB_ACTIVE, SET_PLANOGRAMS_TAB_ACTIVE, IS_PLANOGRAM_ENABLED_CHANGED } from '../types';

const initialState: IModelState = {
    models: [],
    total: 0,
    isFetching: false,
    isFetchError: false,
    isFetchSuccess: false,
    fetchErrorMessage: '',
    isUpdating: false,
    activeTab: '/data',
    isPlanogramEnabledChanged: false
}

const model = (state: IModelState = initialState, action: AnyAction): IModelState => {
    const { type, payload } = action;
    switch (type) {
        case GET_MODELS_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case GET_MODELS_SUCCESS:
            return {
                ...state,
                models: payload.models,
                total: payload.total,
                isFetching: false,
            };
        case GET_MODELS_ERROR:
            return {
                ...state,
                fetchErrorMessage: payload,
                isFetchError: true,
                isFetching: false,
            };
        case ADD_MODEL_REQUEST:
            return {
                ...state,
                isAdding: true,
            }
        case ADD_MODEL_SUCCESS:
            return {
                ...state,
                isAdding: false,
                isAddSuccess: true,
                selected: payload
            }
        case CLEAR_ADD_MODEL_SUCCESS:
            return {
                ...state,
                isAddSuccess: false,
                selected: undefined,
            }
        case SELECT_MODEL:
            return {
                ...state,
                selected: payload
            }
        case SET_DATA_TAB_ACTIVE:
            return {
                ...state,
                activeTab: '/data'
            }
        case SET_COLLECTIONS_TAB_ACTIVE:
            return {
                ...state,
                activeTab: '/collections'
            }
        case SET_CONFIGURATION_TAB_ACTIVE:
            return {
                ...state,
                activeTab: '/configuration'
            }
        case SET_PLANOGRAMS_TAB_ACTIVE:
            return {
                ...state,
                activeTab: '/planograms'
            }
        case IS_PLANOGRAM_ENABLED_CHANGED:
            return {
                ...state,
                isPlanogramEnabledChanged: !state.isPlanogramEnabledChanged
            }
        case DELETE_MODEL: 
            return {
                ...state,
                models: state.models.filter(model => model._id !== payload.modelId)
            }
        default:
            return state
    }
}

export default model;
