import { AxiosResponse } from 'axios';

import { IProjectQueryParams, IProjectsResponse, queryObjectToString, IProjectUpdatePayload, IProject, IProjectNamesQueryParams, IProjectNamesResponse } from '../common';

import { API } from './API';
// import { socket } from './config';

const URL_STRINGS = {
    PROJECT: 'project',
    PUBLIC_KEY: "dd792a0e"
}

class ProjectService extends API {

    getProjects(data?: IProjectQueryParams): Promise<AxiosResponse<IProjectsResponse>> {
        return this.api.get(`${URL_STRINGS.PROJECT}?${queryObjectToString(data)}`);
    }

    getProjectNames(data?: IProjectNamesQueryParams): Promise<AxiosResponse<IProjectNamesResponse>> {
        return this.api.get(`${URL_STRINGS.PROJECT}/projectNames?${queryObjectToString(data)}`);
    }

    addProject(body: IProjectUpdatePayload): Promise<AxiosResponse<unknown>> {
        return this.api.post(URL_STRINGS.PROJECT, body);
    }

    getPublicKey(){
        return this.api.get(URL_STRINGS.PUBLIC_KEY);
    }

    getProjectDetails(projectId: string) {
        return this.api.get(`${URL_STRINGS.PROJECT}/${projectId}`)
    }

    // add(body: IProjectUpdatePayload) {
    //     socket.emit('createProject', body);
    // }

    getProjectDetailsById(data: {id: string}): Promise<AxiosResponse<IProject>> {
        return this.api.get(`${URL_STRINGS.PROJECT}/${data.id}`)
    }
}

export default new ProjectService();
