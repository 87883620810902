import { FETCH_DATA_SET_COLLECTIONS_FOR_FILTER_REQUEST_ACTION_PAYLOAD, FETCH_DATA_SET_COLLECTIONS_FOR_FILTER_SUCCESS_ACTION_PAYLOAD, ADD_DATA_SET_COLLECTION_IN_DATA_SET_COLLECTIONS_FILTER_ACTION_PAYLOAD, IS_EDIT_TEXT_SELECTED_ACTION_PAYLOAD as SET_SHOULD_BLOCK_DATA_TABLE_KEYBOARD_EVENTS_ACTION_PAYLOAD, UPDATE_RESOURCE_IMAGE_GROUP_ANNOTATIONS_Action_Payload, UPDATE_RESOURCE_IMAGE_GROUP_ANNOTATIONS_REQUEST_Action_Payload, UPDATE_RESOURCE_IMAGE_ANNOTATIONS_Action_Payload,UPDATE_RESOURCE_MULTIPLE_IMAGE_ANNOTATIONS_Action_Payload, UPDATE_RESOURCE_IMAGE_ANNOTATIONS_REQUEST_Action_Payload, ResourceRemarksText, UPDATE_RESOURCE_REMARKS_REQUEST_Action_Payload, UPDATE_RESOURCE_REMARKS_Action_Payload, UpdateItemsCountPayload, DataSetCollectionQueryParams, UpdateAllResourcesQueryParam, DeleteBulkResourcesQueryParam, UPDATE_RESOURCE_PROMPT_Action_Payload, UPDATE_RESOURCE_VIDEO_ANNOTATIONS_Action_Payload } from './../../common/constants/interfaces/data';
import { Dispatch } from "redux";

import { IDataQueryParams, actionsErrorHandling, IDataUpdatePayload, IData, IDataResponse, ResourceDataSetCollection, apiRequestCancelErrorHandling } from "../../common";

import {
    GET_DATA_REQUEST,
    GET_DATA_SUCCESS,
    GET_DATA_ERROR,
    UPDATE_DATA_REQUEST,
    UPDATE_DATA_SUCCESS,
    UPDATE_DATA_ERROR,
    SET_DATA_FOR_DRAWER,
    CLEAR_DATA_FOR_DRAWER,
    SET_SELECTED_DATA,
    SET_RESOURCES,
    SET_RESOURCES_TOTAL_COUNT,
    SET_SELECTED_ITEMS
} from '../types';
import { DataService } from "../../services";
import { AxiosResponse, Canceler } from "axios";
import { ADD_RESOURCE_ERROR, ADD_RESOURCE_REQUEST, ADD_RESOURCE_SUCCESS, CLEAR_ADD_RESOURCE_SUCCESS, DELETE_RESOURCE_TRIMMED_TEXT_IN_UI, GET_API_REQUEST_CANCELLED, REMOVE_DATA_SET_COLLECTIONS_FROM_RESOURCES, SET_ADD_COUNT, SET_DISCARDED_COUNT, SET_IS_EDIT_TEXT_SELECTED, SET_IS_FETCHING_RESOURCE_TEXT_FILE_CONTENTS, SET_RESOURCE_DATA_BOOST, SET_RESOURCE_TEXT_FILE_CONTENTS, SET_SOLD_COUNT, UPDATE_RESOURCE_IN_UI, UPDATE_RESOURCE_PROMPT, UPDATE_RESOURCE_VIDEO_ANNOTATIONS, UPDATE_RESOURCE_VIDEO_ANNOTATIONS_REQUEST, UPDATE_VIEW_OF_DATA_MODEL, UPDATE_WHICH_RESOURCES_TO_BE_UPDATED_IN_DB } from '../types/actions';
import { UPDATE_RESOURCE_IMAGE_GROUP_ANNOTATIONS, UPDATE_RESOURCE_IMAGE_GROUP_ANNOTATIONS_REQUEST, GET_DATA_SUCCESS_FOR_SELECTING_ALL_RESOURCES_MATCHING_FILTER, UPDATE_RESOURCE_IMAGE_ANNOTATIONS_REQUEST, UPDATE_RESOURCE_IMAGE_ANNOTATIONS,UPDATE_RESOURCE_MULTIPLE_IMAGE_ANNOTATIONS, UPDATE_RESOURCE_REMARKS_REQUEST, UPDATE_RESOURCE_REMARKS } from '../types/actions';
import { ADD_DATA_SET_COLLECTION_IN_DATA_SET_COLLECTIONS_FILTER, ADD_RESOURCES_TO_DATA_SET_COLLECTION_REQUEST, ADD_RESOURCES_TO_DATA_SET_COLLECTION_SUCCESS, FETCH_DATA_SET_COLLECTIONS_FOR_FILTER_REQUEST, FETCH_DATA_SET_COLLECTIONS_FOR_FILTER_SUCCESS, SET_DATA_SET_COLLECTIONS_IN_RESOURCE_REQUEST, SET_DATA_SET_COLLECTIONS_IN_RESOURCE_SUCCESS, SET_IS_EDIT_TEXT_SELECTED as SET_SHOULD_BLOCK_DATA_TABLE_KEYBOARD_EVENTS } from '../types/actions';
import { GetDataSuccessForSelectingAllResourcesMatchingFilterActionPayload, ResourceRemarks } from '../../common/constants/interfaces/data';
import moment from 'moment';
import { triggerFetchLatestDataSetCollectionResourceLabelsIfCurrentlyShowingInScreenEvent } from './collectionActions';

export const getData = (data: IDataQueryParams, refresh = false, index = -1, userWantsToSelectAllResourcesMatchingThisFilterScenario: boolean = false, onGettingCancelApiFunction: ((cancelApiFunction: Canceler)=>void) | undefined = undefined) => async (dispatch: Dispatch) => {
    return new Promise<AxiosResponse<IDataResponse>>(async (resolve, reject)=>{
        if (!refresh) {
            dispatch({
                type: GET_DATA_REQUEST,
                payload: null,
            });
        }
        try {
            const res: AxiosResponse<IDataResponse> = await DataService.getData(data, {
                onGettingCancelApiFunction: onGettingCancelApiFunction
            });
            if (!userWantsToSelectAllResourcesMatchingThisFilterScenario) {
                if (index > -1) {
                    dispatch({
                        type: SET_DATA_FOR_DRAWER,
                        payload: res.data.resources[index],
                    });
                };
            }
            if (!userWantsToSelectAllResourcesMatchingThisFilterScenario) {
                dispatch({
                    type: GET_DATA_SUCCESS,
                    payload: res.data,
                });
            }
            if (userWantsToSelectAllResourcesMatchingThisFilterScenario) {
                dispatch<{type: typeof GET_DATA_SUCCESS_FOR_SELECTING_ALL_RESOURCES_MATCHING_FILTER, payload: GetDataSuccessForSelectingAllResourcesMatchingFilterActionPayload}>({
                    type: GET_DATA_SUCCESS_FOR_SELECTING_ALL_RESOURCES_MATCHING_FILTER,
                    payload: {
                        resources: res.data.resources
                    }
                });
            }
            resolve(res);
        } catch (err) {
            console.log(err);
            if(err.constructor.name === 'Cancel'){
                apiRequestCancelErrorHandling(err, GET_API_REQUEST_CANCELLED, dispatch);
            }
            else{
                actionsErrorHandling(err, GET_DATA_ERROR, dispatch);
            }
            reject(err);
        }
    })
}

type UpdateDataOptions = {
    shouldFetchLatestData?: boolean
};
export const updateData = (data: IDataUpdatePayload, qParams: IDataQueryParams, { shouldFetchLatestData = false }: UpdateDataOptions = {}, areAllDataSelected?: boolean) => async (dispatch: Dispatch) => {
    if (areAllDataSelected) {
        delete qParams.offset;
        delete qParams.limit
    }
    const queryParamToUpdateBulkResources: UpdateAllResourcesQueryParam | DeleteBulkResourcesQueryParam =
      data.status === "deleted" && qParams.status === "deleted"
        ? {
            status: data.status || "approval",
            resourceDeleteFilterQueryParams: {
            ...(qParams as unknown as DataSetCollectionQueryParams),
            resourceStatusLastModifiedDateMinValue: qParams.resourceStatusLastModifiedDateMinValue ? new Date(qParams.resourceStatusLastModifiedDateMinValue).toISOString() : qParams.resourceStatusLastModifiedDateMinValue as string,
            resourceStatusLastModifiedDateMaxValue: qParams.resourceStatusLastModifiedDateMaxValue ? new Date(qParams.resourceStatusLastModifiedDateMaxValue).toISOString() : qParams.resourceStatusLastModifiedDateMaxValue as string,
            forecastDateMinValue: qParams.forecastDateMinValue ? moment.tz(qParams.forecastDateMinValue, "America/New_York").toISOString() : qParams.forecastDateMinValue as string,
            forecastDateMaxValue: qParams.forecastDateMaxValue ? moment.tz(qParams.forecastDateMaxValue, "America/New_York").toISOString() : qParams.forecastDateMaxValue as string,
              limit: "10000",
            },
            }
        : {
            status: data.status || "approval",
            resourceUpdateFilterQueryParams: {
            ...(qParams as unknown as DataSetCollectionQueryParams),
            resourceStatusLastModifiedDateMinValue: qParams.resourceStatusLastModifiedDateMinValue ? new Date(qParams.resourceStatusLastModifiedDateMinValue).toISOString() : qParams.resourceStatusLastModifiedDateMinValue as string,
              resourceStatusLastModifiedDateMaxValue: qParams.resourceStatusLastModifiedDateMaxValue ? new Date(qParams.resourceStatusLastModifiedDateMaxValue).toISOString() : qParams.resourceStatusLastModifiedDateMaxValue as string,
              forecastDateMinValue: qParams.forecastDateMinValue ? moment.tz(qParams.forecastDateMinValue, "America/New_York").toISOString() : qParams.forecastDateMinValue as string,
            forecastDateMaxValue: qParams.forecastDateMaxValue ? moment.tz(qParams.forecastDateMaxValue, "America/New_York").toISOString() : qParams.forecastDateMaxValue as string,
              limit: data.status !== 'backlog' ? "50000" : "10000",
            },
          };
    return new Promise<void>(async (resolve, reject) => {
        dispatch({
            type: UPDATE_DATA_REQUEST,
            payload: data,
        });
        try {
            data.status === 'deleted' &&
                qParams?.status === 'deleted' ?
                await DataService.deleteData(!areAllDataSelected ? data : queryParamToUpdateBulkResources, '') :
                await DataService.updateData(!areAllDataSelected ? data : queryParamToUpdateBulkResources);
                if (shouldFetchLatestData) {
                    await getData(qParams, true)(dispatch);
                } else {

                }
            dispatch({
                type: UPDATE_DATA_SUCCESS,
                payload: data,
            });

            triggerFetchLatestDataSetCollectionResourceLabelsIfCurrentlyShowingInScreenEvent()(dispatch);

            resolve();
        } catch (err) {
            console.log(err);
            actionsErrorHandling(err, UPDATE_DATA_ERROR, dispatch);
            reject(err.message);
        }
    })
}

export const updateResourceImageGroupAnnotationsInDB = (data: UPDATE_RESOURCE_IMAGE_GROUP_ANNOTATIONS_Action_Payload) => async (dispatch: Dispatch) => {
    return new Promise<void>(async (resolve, reject)=>{

        dispatch<{type: typeof UPDATE_RESOURCE_IMAGE_GROUP_ANNOTATIONS_REQUEST, payload: UPDATE_RESOURCE_IMAGE_GROUP_ANNOTATIONS_REQUEST_Action_Payload}>({
            type: "UPDATE_RESOURCE_IMAGE_GROUP_ANNOTATIONS_REQUEST",
            payload: {resourceId: data.resourceId},
        });

        await DataService.updateData({
            id: [data.resourceId],
            imageGroupAnnotations: data.imageGroupAnnotations
        })

        dispatch<{type: typeof UPDATE_RESOURCE_IMAGE_GROUP_ANNOTATIONS, payload: UPDATE_RESOURCE_IMAGE_GROUP_ANNOTATIONS_Action_Payload}>({
            type: "UPDATE_RESOURCE_IMAGE_GROUP_ANNOTATIONS",
            payload: {
                resourceId: data.resourceId,
                imageGroupAnnotations: data.imageGroupAnnotations
            },
        });  
        
        resolve()
    })
}

export const addResource = (data: FormData) => async (dispatch: Dispatch) => {
    dispatch({
        type: ADD_RESOURCE_REQUEST
    });
    try {
        const res = await DataService.addResourceInModel(data);

        /* Required when upload resource with signed URL  */
        // const { resourceFileSignedUrlForUpload } = res.data as any;
        // if (resourceFileSignedUrlForUpload) {
        //     try {
        //         await DataService.uploadResourceToGCS({resource: data.get("resource") as string, resourceContentType: data.get("resourceContentType") as string, resourceFileSignedUrlForUpload: resourceFileSignedUrlForUpload});
        //     }
        //     catch (error) {
        //         console.log("Resource Upload Failed");
        //     }
        // }
        /* delete res?.data?.resourceFileSignedUrlForUpload */

        dispatch({
            type: ADD_RESOURCE_SUCCESS,
            payload: res.data,
        });
        dispatch({
            type: CLEAR_ADD_RESOURCE_SUCCESS
        });
    } 
    catch (err) {
        console.log(err);
        actionsErrorHandling(err, ADD_RESOURCE_ERROR, dispatch);
    }
}

export const updateResourceImageAnnotationsInDB = (data: UPDATE_RESOURCE_IMAGE_ANNOTATIONS_Action_Payload) => async (dispatch: Dispatch) => {
    return new Promise<void>(async (resolve, reject)=>{

        dispatch<{type: typeof UPDATE_RESOURCE_IMAGE_ANNOTATIONS_REQUEST, payload: UPDATE_RESOURCE_IMAGE_ANNOTATIONS_REQUEST_Action_Payload}>({
            type: "UPDATE_RESOURCE_IMAGE_ANNOTATIONS_REQUEST",
            payload: {resourceId: data.resourceId},
        });

        await DataService.updateData({
            id: [data.resourceId],
            imageAnnotations: data.imageAnnotations
        })

        dispatch<{type: typeof UPDATE_RESOURCE_IMAGE_ANNOTATIONS, payload: UPDATE_RESOURCE_IMAGE_ANNOTATIONS_Action_Payload}>({
            type: "UPDATE_RESOURCE_IMAGE_ANNOTATIONS",
            payload: {
                resourceId: data.resourceId,
                imageAnnotations: data.imageAnnotations
            },
        });  
        
        resolve()
    })
}
export const updateResourceMultipleImageAnnotationsInDB = (data: UPDATE_RESOURCE_MULTIPLE_IMAGE_ANNOTATIONS_Action_Payload) => async (dispatch: Dispatch) => {
    return new Promise<void>(async (resolve, reject)=>{

       

        await DataService.updateMultipleImageAnnotationData({
                  resourceId:data.resourceId,
                  imageAnnotations: data.imageAnnotations,
                   fileId:data.fileId
              })

        dispatch<{type: typeof UPDATE_RESOURCE_MULTIPLE_IMAGE_ANNOTATIONS, payload: UPDATE_RESOURCE_MULTIPLE_IMAGE_ANNOTATIONS_Action_Payload}>({
            type: "UPDATE_RESOURCE_MULTIPLE_IMAGE_ANNOTATIONS",
            payload: {
                resourceId: data.resourceId,
                imageAnnotations: data.imageAnnotations,
                fileId:data.fileId
            },
        });  
        
        resolve()
    })
}

export const updateResourceVideoAnnotationsInDB = (data: UPDATE_RESOURCE_VIDEO_ANNOTATIONS_Action_Payload) => async (dispatch: Dispatch) => {
  return new Promise<void>(async (resolve, reject) => {

    dispatch<{type: typeof UPDATE_RESOURCE_VIDEO_ANNOTATIONS_REQUEST, payload: { resourceId: string} }>({
      type: UPDATE_RESOURCE_VIDEO_ANNOTATIONS_REQUEST,
      payload: {resourceId: data.resourceId},
    });

    await DataService.updateData({
      id: [data.resourceId],
      videoAnnotations: data.videoAnnotations
    })

    dispatch<{ type: typeof UPDATE_RESOURCE_VIDEO_ANNOTATIONS, payload: UPDATE_RESOURCE_VIDEO_ANNOTATIONS_Action_Payload }>({
      type: UPDATE_RESOURCE_VIDEO_ANNOTATIONS,
      payload: {
        resourceId: data.resourceId,
        videoAnnotations: data.videoAnnotations
      },
    });

    resolve()
  })
}

export const clearDrawerDataInData = () => async (dispatch: Dispatch) => {
    dispatch({
        type: CLEAR_DATA_FOR_DRAWER,
    });
}

export const setSelectedData = (resourceId: string) => async (dispatch: Dispatch) => {
    dispatch({
        type: SET_SELECTED_DATA,
        // payload: { ...data },
        payload: resourceId,
    });
}

export const setResources = (resources: IData[]) => async (dispatch: Dispatch) => {
    dispatch({
        type: SET_RESOURCES,
        payload: resources,
    });
}
export const setResourcesTotalCount = (totalCount: number) => async (dispatch: Dispatch) => {
    dispatch({
        type: SET_RESOURCES_TOTAL_COUNT,
        payload: totalCount,
    });
}
export const setSelectedItems = (ids: string[]) => async (dispatch: Dispatch) => {
    dispatch({
        type: SET_SELECTED_ITEMS,
        payload: ids,
    });
}

export const updateDataViewType = (dataView: string) => async (dispatch: Dispatch) => {
    dispatch({
        type: UPDATE_VIEW_OF_DATA_MODEL,
        payload: dataView,
    });
}

export type ADD_RESOURCES_TO_DATA_SET_COLLECTION_REQUEST_Action_Payload = {
    resourceIds: string[], 
}
export type ADD_RESOURCES_TO_DATA_SET_COLLECTION_SUCCESS_Action_Payload = {
    resourceIds: string[],
    dataSetCollections: ResourceDataSetCollection[]
}
export const addResourcesToDataSetCollection = (payload: {resourceIds?: string[], dataSetCollections: ResourceDataSetCollection[], addToDataSetCollectionQueryParams?: DataSetCollectionQueryParams }) => async (dispatch: Dispatch) => {
    return new Promise<void>(async (resolve, reject)=>{
        try {
            dispatch({
                type: ADD_RESOURCES_TO_DATA_SET_COLLECTION_REQUEST,
                payload: {resourceIds: payload.resourceIds}
            })
            
              await DataService.addResourceToDataSetCollection(
                payload.resourceIds
                  ? {
                      resourceIds: payload.resourceIds,
                      dataSetCollectionIds: payload.dataSetCollections.map(
                        (dataSetCollection) => dataSetCollection._id
                      )
                    }
                  : {
                      dataSetCollectionIds: payload.dataSetCollections.map(
                        (dataSetCollection) => dataSetCollection._id
                      ),
                      addToDataSetCollectionQueryParams:
                        payload.addToDataSetCollectionQueryParams,
                    }
                );
            dispatch({
                type: ADD_RESOURCES_TO_DATA_SET_COLLECTION_SUCCESS,
                payload: {resourceIds: payload.resourceIds, dataSetCollections: payload.dataSetCollections}
            })

            triggerFetchLatestDataSetCollectionResourceLabelsIfCurrentlyShowingInScreenEvent()(dispatch);

            resolve();
        } catch (error: any) {
            reject(error.message);
        }
    })
}

export type SET_DATA_SET_COLLECTIONS_IN_RESOURCE_REQUEST_Action_Payload = {
    resourceIds: string[], 
}
export type SET_DATA_SET_COLLECTIONS_IN_RESOURCE_SUCCESS_Action_Payload = {
    resourceId: string,
    dataSetCollections: ResourceDataSetCollection[]
}
export const setDataSetCollectionsInResource = (payload: {resourceId: string, dataSetCollections: ResourceDataSetCollection[]}) => async (dispatch: Dispatch) => {
    return new Promise<void>(async (resolve, reject)=>{
        try {
            dispatch<{type: string, payload: SET_DATA_SET_COLLECTIONS_IN_RESOURCE_REQUEST_Action_Payload}>({
                type: SET_DATA_SET_COLLECTIONS_IN_RESOURCE_REQUEST,
                payload: {resourceIds: [payload.resourceId]}
            })
            await DataService.updateResourceDataSetCollections({
                dataSetCollectionIds: payload.dataSetCollections.map(collection=>collection._id),
                resourceId: payload.resourceId
            });
            dispatch<{type: string, payload: SET_DATA_SET_COLLECTIONS_IN_RESOURCE_SUCCESS_Action_Payload}>({
                type: SET_DATA_SET_COLLECTIONS_IN_RESOURCE_SUCCESS,
                payload: {resourceId: payload.resourceId, dataSetCollections: payload.dataSetCollections}
            })

            triggerFetchLatestDataSetCollectionResourceLabelsIfCurrentlyShowingInScreenEvent()(dispatch);

            resolve();
        } catch (error: any) {
            reject(error.message);
        }
    })
}

export const fetchDataSetCollectionsForDataTableFilter = (payload: {modelId: string, projectionQuery?: string}) => async (dispatch: Dispatch) => {
    return new Promise<void>(async (resolve, reject)=>{
        try {
            dispatch<{type: string, payload: FETCH_DATA_SET_COLLECTIONS_FOR_FILTER_REQUEST_ACTION_PAYLOAD}>({
                type: FETCH_DATA_SET_COLLECTIONS_FOR_FILTER_REQUEST,
                payload: null
            })
            const apiResponse = await DataService.getCollections({
                model: payload.modelId,
                limit: '-1',
                offset: '-1',
                projectionQuery: payload.projectionQuery
            });
            dispatch<{type: string, payload: FETCH_DATA_SET_COLLECTIONS_FOR_FILTER_SUCCESS_ACTION_PAYLOAD}>({
                type: FETCH_DATA_SET_COLLECTIONS_FOR_FILTER_SUCCESS,
                payload: {
                    dataSetCollections: apiResponse.data.collections
                }
            })
            resolve();
        } catch (error: any) {
            reject(error.message);
        }
    })
}

export const addDataSetCollectionInDataSetCollectionsFilter = (payload: ADD_DATA_SET_COLLECTION_IN_DATA_SET_COLLECTIONS_FILTER_ACTION_PAYLOAD) => (dispatch: Dispatch) => {
    dispatch<{type: string, payload: ADD_DATA_SET_COLLECTION_IN_DATA_SET_COLLECTIONS_FILTER_ACTION_PAYLOAD}>({
        type: ADD_DATA_SET_COLLECTION_IN_DATA_SET_COLLECTIONS_FILTER,
        payload: payload
    })
}

export const setShouldBlockDataTableKeyboardEvents = (value: SET_SHOULD_BLOCK_DATA_TABLE_KEYBOARD_EVENTS_ACTION_PAYLOAD) => (dispatch: Dispatch) => {
    dispatch<{type: string, payload: SET_SHOULD_BLOCK_DATA_TABLE_KEYBOARD_EVENTS_ACTION_PAYLOAD}>({
        type: SET_SHOULD_BLOCK_DATA_TABLE_KEYBOARD_EVENTS,
        payload: value
    })
}

export const updateResourcePromptOrAnswer = (data: {resourceId: string, resource?: string, ansOfPrompt?: string}) => async (dispatch: Dispatch) => {
  const ActionPayload: UPDATE_RESOURCE_PROMPT_Action_Payload = { resourceId: data.resourceId };

  if (data.resource !== undefined && data.resource !== null)
    ActionPayload.resource = data.resource;
  if (data.ansOfPrompt !== undefined && data.ansOfPrompt !== null) 
    ActionPayload.ansOfPrompt = data.ansOfPrompt;

  dispatch<{type: typeof UPDATE_RESOURCE_PROMPT, payload: UPDATE_RESOURCE_PROMPT_Action_Payload}>({
    type: "UPDATE_RESOURCE_PROMPT",
    payload: ActionPayload,
  });
}

export const updateResourceRemarks = (data: {resourceId: string, resourceRemarksText: ResourceRemarksText}) => async (dispatch: Dispatch) => {
    return new Promise<void>(async (resolve, reject)=>{

        dispatch<{type: typeof UPDATE_RESOURCE_REMARKS_REQUEST, payload: UPDATE_RESOURCE_REMARKS_REQUEST_Action_Payload}>({
            type: "UPDATE_RESOURCE_REMARKS_REQUEST",
            payload: {resourceId: data.resourceId},
        });

        await DataService.updateData({
            id: [data.resourceId],
            remarks: data.resourceRemarksText
        });

        const fetchApiResponse = await DataService.getResourceFromDBById({
            resourceId: data.resourceId,
            resourceSelectQuery: "-_id -model remarks"
        })

        const resourceRemarks: ResourceRemarks = fetchApiResponse.data.remarks as any;

        dispatch<{type: typeof UPDATE_RESOURCE_REMARKS, payload: UPDATE_RESOURCE_REMARKS_Action_Payload}>({
            type: "UPDATE_RESOURCE_REMARKS",
            payload: {
                resourceId: data.resourceId,
                resourceRemarks: resourceRemarks
            },
        });  
        
        resolve()
    })
}

export const updateItemsDiscardedCount = (params: UpdateItemsCountPayload) => async (dispatch: Dispatch) => {
    let payloadObj: any ={}
    if(params.resourceId === params.videoId){
        payloadObj={
            itemsDiscardedCount: params.count,
        }
    }
    else{
        payloadObj={
            additionalResourceIdstoFilter: [params.videoId],
            additionalResourcePropertiesToUpdate: { itemsDiscardedCount : params.count } 
        }
    }
    payloadObj.videoDurationInSeconds = params.videoDurationInSeconds
    await DataService.updateData({
        id: [params.resourceId],
        ...payloadObj
    })
    dispatch({
        type: SET_DISCARDED_COUNT,
        payload: params,
    });
}

export const updateItemsSoldCount = (params: UpdateItemsCountPayload) => async (dispatch: Dispatch) => {
    let payloadObj: any ={}
    if(params.resourceId === params.videoId){
        payloadObj={
            itemsSoldCount: params.count,
        }
    }
    else{
        payloadObj={
            additionalResourceIdstoFilter: [params.videoId],
            additionalResourcePropertiesToUpdate: { itemsSoldCount : params.count } 
        }
    }
    payloadObj.videoDurationInSeconds = params.videoDurationInSeconds
    await DataService.updateData({
        id: [params.resourceId],
        ...payloadObj
    })
    dispatch({
        type: SET_SOLD_COUNT,
        payload: params,
    });
}
export const updateResourceWhichAreBeingUpdatedInDB = (resourceId: string[]) => async (dispatch: Dispatch) => {
    dispatch({
        type: UPDATE_WHICH_RESOURCES_TO_BE_UPDATED_IN_DB,
        payload: resourceId
    })
}

export const updateItemsAddCount = (params: UpdateItemsCountPayload) => async (dispatch: Dispatch) => {
    let payloadObj: any ={}
    if(params.resourceId === params.videoId){
        payloadObj={
            itemsAddCount: params.count,
        }
    }
    else{
        payloadObj={
            additionalResourceIdstoFilter: [params.videoId],
            additionalResourcePropertiesToUpdate: { itemsAddCount : params.count } 
        }
    }
    payloadObj.videoDurationInSeconds = params.videoDurationInSeconds
    await DataService.updateData({
        id: [params.resourceId],
        ...payloadObj
    })
    dispatch({
        type: SET_ADD_COUNT,
        payload: params,
    });
}

export const setResourceDataBoost = (data: {resourceId: string, dataBoost: number}) => async (dispatch: Dispatch) => {
    dispatch({
        type: SET_RESOURCE_DATA_BOOST,
        payload: data
    });
}

/**
 * This will update any resource object property
 */
export const updateResourceInUI = (resourceData: any) => async (dispatch: Dispatch) => {
    dispatch({
        type: UPDATE_RESOURCE_IN_UI,
        payload: resourceData
    })
}

export type SET_IS_FETCHING_RESOURCE_TEXT_FILE_CONTENTSActionPayload = {
    resourceIdToUpdate:  string,
    isFetching: boolean
}

export type SET_RESOURCE_TEXT_FILE_CONTENTSActionPayload = {
    resourceIdToUpdate: string,
    textContentToSet: string
}

export const fetchResourceTextFileContents = (resourceId: string, resourceFileName: string, modelId: string, projectId: string) => async (dispatch: Dispatch) => {
    dispatch<{type: string, payload: SET_IS_FETCHING_RESOURCE_TEXT_FILE_CONTENTSActionPayload}>({
        type: SET_IS_FETCHING_RESOURCE_TEXT_FILE_CONTENTS,
        payload: {
            resourceIdToUpdate: resourceId, 
            isFetching: true
        }
    })

    const apiResponse = await DataService.fetchResourceTextFileContentsFromDB({
        modelId,
        projectId,
        resourceFileName,
        resourceId
    });

    if (apiResponse.data) {
        dispatch<{ type: string, payload: SET_RESOURCE_TEXT_FILE_CONTENTSActionPayload }>({
            type: SET_RESOURCE_TEXT_FILE_CONTENTS,
            payload: {
                resourceIdToUpdate: resourceId,
                textContentToSet: apiResponse.data.text || ""
            }
        });
    }

    dispatch<{type: string, payload: SET_IS_FETCHING_RESOURCE_TEXT_FILE_CONTENTSActionPayload}>({
        type: SET_IS_FETCHING_RESOURCE_TEXT_FILE_CONTENTS,
        payload: {
            resourceIdToUpdate: resourceId, 
            isFetching: false
        }
    })    

}

export type deleteResourceTrimmedTextInUIActionPayload = {
    resourceId: string, 
    idOfTrimTextToDelete: string
}
export const deleteResourceTrimmedTextInUI = (resourceData: deleteResourceTrimmedTextInUIActionPayload) => {
    return {
        type: DELETE_RESOURCE_TRIMMED_TEXT_IN_UI,
        payload: resourceData
    }
}

export const removeDataSetCollectionsFromResources = (resourceIds: string[], dataSetCollectionIds: string[]) => async (dispatch: Dispatch) => {
    dispatch({
        type: REMOVE_DATA_SET_COLLECTIONS_FROM_RESOURCES,
        payload: {
            resourceIds,
            dataSetCollectionIds
        }
    })
}

// export const getDataAndSetSelected = (data: { qParams: IDataQueryParams, selectedIndex: number }) => async (dispatch: Dispatch) => {
//     const { qParams, selectedIndex } = data;
//     await getData(qParams, true)(dispatch);
//     dispatch({
//         type: SELECT_DATA,
//         payload: { selectedIndex },
//     });
// }

// export const setSelectedData = (selectedIndex: number) => async (dispatch: Dispatch) => {
//     dispatch({
//         type: SELECT_DATA,
//         payload: { selectedIndex },
//     });
// }


// export const getTags = () => async (dispatch: Dispatch) => {
//     dispatch({
//         type: GET_TAGS_INIT,
//         payload: null,
//     });
//     try {
//         const res = await DataService.getTags();
//         dispatch({
//             type: GET_TAGS_SUCCESS,
//             payload: res.data,
//         });
//     } catch (err) {
//         console.log(err);
//     }
// }

// // TODO make it generic
// export const showSideDrawer = (type: string) => async (dispatch: Dispatch) => {
//     dispatch({
//         type: SHOW_ADD_COLLECTION,
//         payload: null,
//     });
//     getTags()(dispatch);
// }

// export const addCollection = (data: IaddCollection) => async (dispatch: Dispatch) => {
//     dispatch({
//         type: ADD_COLLECTION_INIT,
//         payload: null,
//     });
//     data.model = '6094fe053bddac17dca44792'; // TODO make model dynamic
//     try {
//         const res = await DataService.addCollection(data);
//         await getCollections({ limit: 10, offset: 1 }, true)(dispatch);
//         dispatch({
//             type: ADD_COLLECTION_SUCCESS,
//             payload: res.data,
//         });
//     } catch (err) {
//         console.log(err);
//     }
// }

// export const changeQParams = (data: IDataQParams) => async (dispatch: Dispatch) => {
//     dispatch({
//         type: SET_QUERY_PARAMS,
//         payload: data,
//     });
// }

// export const getCollections = (data: IDataQParams, refresh = false) => async (dispatch: Dispatch) => {
//     if (!refresh) {
//         dispatch({
//             type: GET_COLLECTIONS_INIT,
//             payload: null,
//         });
//     }
//     try {
//         const res = await DataService.getCollections(data);
//         dispatch({
//             type: GET_COLLECTIONS_SUCCESS,
//             payload: res.data,
//         });
//     } catch (err) {
//         console.log(err);
//     }
// }

// export const setSelectedCollection = (selectedIndex: number) => async (dispatch: Dispatch) => {
//     dispatch({
//         type: SELECT_COLLECTION,
//         payload: { selectedIndex },
//     });
// }
