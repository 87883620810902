export const HIDE_ANALYTICS_TAB = 'HIDE_ANALYTICS_TAB'
export const SHOW_ANALYTICS_TAB = 'SHOW_ANALYTICS_TAB'

export const COLLECTION_SELECTED_TAB = 'COLLECTION_SELECTED_TAB'


export const hideAnalyticsTab = () => {
    return {
        type: HIDE_ANALYTICS_TAB
    }
}

export const showAnalyticsTab = () => {
    return {
        type: SHOW_ANALYTICS_TAB
    }
}

export const collectionSelectedTab = (data: string) => {
    return {
        type: COLLECTION_SELECTED_TAB,
        payload: data
    }
}