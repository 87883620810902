import { AnyAction } from "redux";

import {
    IProjectState,
} from "../../common";

import { ADD_PROJECT_REQUEST, ADD_PROJECT_SUCCESS, CLEAR_ADD_PROJECT_SUCCESS, GET_PROJECTS_ERROR, GET_PROJECTS_REQUEST, GET_PROJECTS_SUCCESS, ON_PROJECT_ADD, SELECT_PROJECT } from '../types';

const initialState: IProjectState = {
    projects: [],
    total: 0,
    isFetching: false,
    isFetchError: false,
    isFetchSuccess: false,
    fetchErrorMessage: '',
    isUpdating: false,
}

const project = (state: IProjectState = initialState, action: AnyAction) => {
    const { type, payload } = action;
    switch (type) {
        case GET_PROJECTS_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case GET_PROJECTS_SUCCESS:
            return {
                ...state,
                projects: payload.projects,
                total: payload.total,
                isFetching: false,
            };
        case GET_PROJECTS_ERROR:
            return {
                ...state,
                fetchErrorMessage: payload,
                isFetchError: true,
                isFetching: false,
            };
        case ON_PROJECT_ADD:
            return {
                ...state,
                projects: [payload, ...state.projects]
            }
        case ADD_PROJECT_REQUEST:
            return {
                ...state,
                isAdding: true,
            }
        case ADD_PROJECT_SUCCESS:
            return {
                ...state,
                isAdding: false,
                isAddSuccess: true,
                selected: payload
            }
        case CLEAR_ADD_PROJECT_SUCCESS:
            return {
                ...state,
                isAddSuccess: false,
                selected: null,
            }
        case SELECT_PROJECT:
            return {
                ...state,
                selected: payload
            }
        default:
            return state
    }
}

export default project;
