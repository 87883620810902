import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";

import css from "./Alert.module.css";
import { CSSProperties } from "react";

export type AlertComponentProps = {
  message: string;
  onClose: () => any;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl";
  styleProperty?: CSSProperties 
};

/**
 * @description
 * This component is meant to be used as a better alternative than javascript alert
 */
export function Alert(props: AlertComponentProps) {
  return (
    <div>
      <Dialog
        open={true}
        onClose={() => {
          props.onClose();
        }}
        maxWidth={props.maxWidth||"lg"}
      >
        {/* <DialogTitle id="alert-dialog-title">{"Use Google's location service?"}</DialogTitle> */}
        <DialogContent>
          <DialogContentText 
            className={`
              ${css.alertMessage}
            `}
            style={props.styleProperty?{...props.styleProperty}:{}}
          >
            {props.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              props.onClose();
            }}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
