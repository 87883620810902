import { AnyAction } from "redux";
import { ICopilotState } from "../../common/constants/interfaces/copilot";
import { ICoPilotResourceNotification } from "../../common/constants/interfaces/coPilotResource";
import { SOCKET_EVENT_CO_PILOT_RESOURCES_UPDATED_DATA } from "../../common/constants/interfaces/socketEvents";
import { SELECT_COPILOT , GET_COPILOTS_REQUEST, GET_COPILOTS_SUCCESS, SET_ACTIVE_TAB_ACTIVE, SET_EXPIRED_TAB_ACTIVE, SET_REJECTED_TAB_ACTIVE, CLEAR_ADD_COPILOT_SUCCESS, GET_COPILOTS_ERROR, ADD_COPILOT_REQUEST, ADD_COPILOT_SUCCESS, GET_COPILOTS_NOTIFICATIONS_SUCCESS, UPDATE_NOTIFICATION_RESOURCE_SUCCESS, GET_COPILOTS_NOTIFICATIONS, UPDATE_COPILOT_NOTIFICATIONS, ADD_COPILOT_NOTIFICATIONS, REMOVE_COPILOT_NOTIFICATION_AND_RESOURCE, COPILOT_NOTIFICATION_AND_RESOURCE_DELETED, SET_COPILOT_NOTIFICATION_BADGE, RESET_NOTIFICATION_DATA, GET_COPILOTS_NOTIFICATIONS_FOR_BADGE } from '../types';

const initialState: ICopilotState = {
    copilots: [],
    total: 0,
    isFetching: false,
    isFetchError: false,
    isFetchSuccess: false,
    fetchErrorMessage: '',
    isUpdating: false,
    activeTab: '/active',
    notifications: [],
    notificationCount: 0,
    isFetchingNotifications: false,
    showNotificationBadge: false
}

const copilots = (state: ICopilotState = initialState, action: AnyAction): ICopilotState => {
    const { type, payload } = action;
    switch (type) {
        case GET_COPILOTS_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case GET_COPILOTS_SUCCESS:
            return {
                ...state,
                copilots: payload.coPilots,
                total: payload.totalCount,
                isFetching: false,
            };
        case GET_COPILOTS_ERROR:
            return {
                ...state,
                fetchErrorMessage: payload,
                isFetchError: true,
                isFetching: false,
            };
        case ADD_COPILOT_REQUEST:
            return {
                ...state,
                isAdding: true,
            }
        case ADD_COPILOT_SUCCESS:
            return {
                ...state,
                isAdding: false,
                isAddSuccess: true,
                selected: payload
            }
        case CLEAR_ADD_COPILOT_SUCCESS:
            return {
                ...state,
                isAddSuccess: false,
                selected: undefined,
            }
        case SELECT_COPILOT:
            return {
                ...state,
                selected: payload
            }
        case SET_ACTIVE_TAB_ACTIVE:
            return {
                ...state,
                activeTab: '/active'
            }
        case SET_EXPIRED_TAB_ACTIVE:
            return {
                ...state,
                activeTab: '/expired'
            }
        case SET_REJECTED_TAB_ACTIVE:
            return {
                ...state,
                activeTab: '/rejected'
            }
        case GET_COPILOTS_NOTIFICATIONS:
            return {
                ...state,
                isFetchingNotifications: true
            }
        case GET_COPILOTS_NOTIFICATIONS_SUCCESS:
            return {
              ...state,
              notifications: removeDuplicateNotifications(state.notifications, payload.resources),
              notificationCount: payload.totalCount,
              isFetchingNotifications: false,
            };
        case GET_COPILOTS_NOTIFICATIONS_FOR_BADGE:
            return {
                ...state,
                notifications: payload.resources,
                notificationCount: payload.totalCount,
                showNotificationBadge: payload.resources.length > 0
            }
        case RESET_NOTIFICATION_DATA: 
            return {
                ...state,
                notifications: [],
                notificationCount: 0,
                isFetchingNotifications: false,
            }
        case SET_COPILOT_NOTIFICATION_BADGE:
            return {
                ...state,
                showNotificationBadge: payload
            }
        case UPDATE_NOTIFICATION_RESOURCE_SUCCESS:
            return {
                ...state,
                ...removeNotificationFromList(state.notifications, payload),
            }
        case ADD_COPILOT_NOTIFICATIONS:
            return {
                ...state,
                notifications:[...state.notifications, payload],
                // showNotificationBadge: payload.created_at ? false : true
            }
        case REMOVE_COPILOT_NOTIFICATION_AND_RESOURCE:
        case COPILOT_NOTIFICATION_AND_RESOURCE_DELETED:
            return {
                ...state,
                ...removeNotificationFromList(state.notifications, payload)
            }
        case UPDATE_COPILOT_NOTIFICATIONS: 
            return {
                ...state,
                notifications: updateNotification(state.notifications, payload),
                showNotificationBadge: true
            }
        default:
            return state
    }
}

const updateNotification = (notifications: ICoPilotResourceNotification[], payload: SOCKET_EVENT_CO_PILOT_RESOURCES_UPDATED_DATA) => {
    let notificationList: ICoPilotResourceNotification[] = [...notifications];
    let index = notificationList.findIndex(notificationItem => notificationItem._id === payload.resourceIds[0])
    if(index !== -1){
        notificationList[index] = {
            ...notificationList[index],
            label: payload.label || notificationList[index].label,
            confidenceScore: payload.confidenceScore || notificationList[index].confidenceScore,
            remarks: payload.remarks || notificationList[index].remarks,
            userTimeLeftInSecondsToTakeAction: payload.userTimeLeftInSecondsToTakeAction || notificationList[index].userTimeLeftInSecondsToTakeAction,
        };
    }
    return notificationList
}
const removeNotificationFromList = (notifications: ICoPilotResourceNotification[], notificationId: string) => {
    let filteredNotifications = [...notifications].filter(notification => notification._id !== notificationId)
    return {
        notifications: filteredNotifications,
        showNotificationBadge: filteredNotifications.length > 0
    }
}

const removeDuplicateNotifications = (notifications: ICoPilotResourceNotification[], newNotifications: ICoPilotResourceNotification[]) => {
    const tempNotificationsArray = notifications.filter((notification) => {
        return !newNotifications.find((newNotification) => {
          return notification._id === newNotification._id;
        }); 
    });
    return [...tempNotificationsArray, ...newNotifications];
}

export default copilots;
