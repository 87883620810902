import { COLLECTION_SELECTED_TAB, HIDE_ANALYTICS_TAB, SHOW_ANALYTICS_TAB } from '../actions'

const initalState = {
    showAnalyticsTab: false,
    selectedTab: 'Data Set'
}

 const updateDashboardAnalyticTabState = (state = initalState, action: any) => {
    switch(action.type){
        case HIDE_ANALYTICS_TAB: return {
            ...state,
            showAnalyticsTab : false
        }
        case SHOW_ANALYTICS_TAB: return {
            ...state,
            showAnalyticsTab : true
        }
        case COLLECTION_SELECTED_TAB: return {
            ...state,
            selectedTab: action.payload
        }
        default: return state
    }
}




export default updateDashboardAnalyticTabState