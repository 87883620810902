import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { IData, IDataResponse, IModelCollection } from "../../../../../../../common";
import BrowseModelCollectionsPopupContext from "./browseModelCollectionsPopup.context"

export default function BrowseModelCollectionsPopupContextComponent(props: { children: ReactElement }) {

    const [selectedModelCollection, setSelectedModelCollection] = useState<IModelCollection | null>(null)

    const [breadCrumbs, setBreadCrumbs] = useState<string[]>([])

    const browseModelCollectionsPopupContextValue = useMemo(() => {
        return {
            selectedModelCollection,
            setSelectedModelCollection,
            breadCrumbs,
            setBreadCrumbs
        }
    }, [selectedModelCollection, breadCrumbs])


    return (
        <BrowseModelCollectionsPopupContext.Provider value={browseModelCollectionsPopupContextValue}>
            {props.children}
        </BrowseModelCollectionsPopupContext.Provider>
    )
}