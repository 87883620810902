/**
 * Core
 */
export const ADD_BREADCRUMBS = "ADD_BREADCRUMBS";
export const REMOVE_BREADCRUMBS = "REMOVE_BREADCRUMBS";
export const SET_TOP_ACTION_BUTTON = "SET_TOP_ACTION_BUTTON";
export const UPDATE_BREADCRUMBS_PATH = "UPDATE_BREADCRUMBS_PATH";
export const REMOVE_ALL_BREADCRUMBS = "REMOVE_ALL_BREADCRUMBS";

/**
 * Projects
 */
export const ON_PROJECT_CHANGE = "ON_PROJECT_CHANGE";
export const ON_PROJECT_ADD = "ON_PROJECT_ADD";
export const ON_PROJECT_DELETE = "ON_PROJECT_DELETE";
export const ON_PROJECT_UPDATE = "ON_PROJECT_UPDATE";

export const GET_PROJECTS_REQUEST = "GET_PROJECTS_REQUEST";
export const GET_PROJECTS_SUCCESS = "GET_PROJECTS_SUCCESS";
export const GET_PROJECTS_ERROR = "GET_PROJECTS_ERROR";
export const GET_PROJECTS_CLEAR_ERROR = "GET_PROJECTS_CLEAR_ERROR";

export const ADD_PROJECT_REQUEST = "ADD_PROJECT_REQUEST";
export const ADD_PROJECT_SUCCESS = "ADD_PROJECT_SUCCESS";
export const ADD_PROJECT_ERROR = "ADD_PROJECT_ERROR";
export const ADD_PROJECT_CLEAR_ERROR = "ADD_PROJECT_CLEAR_ERROR";
export const CLEAR_ADD_PROJECT_SUCCESS = "CLEAR_ADD_PROJECT_SUCCESS";

export const UPDATE_PROJECT_REQUEST = "UPDATE_PROJECT_REQUEST";
export const UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS";
export const UPDATE_PROJECT_ERROR = "UPDATE_PROJECT_ERROR";
export const UPDATE_PROJECT_CLEAR_ERROR = "UPDATE_PROJECT_CLEAR_ERROR";

export const SELECT_PROJECT = "SELECT_PROJECT";
/*------------------------------------------------------------------------*/

/**
 * Models
 */
export const GET_MODELS_REQUEST = "GET_MODELS_REQUEST";
export const GET_MODELS_SUCCESS = "GET_MODELS_SUCCESS";
export const GET_MODELS_ERROR = "GET_MODELS_ERROR";
export const GET_MODELS_CLEAR_ERROR = "GET_MODELS_CLEAR_ERROR";

export const ADD_MODEL_REQUEST = "ADD_MODEL_REQUEST";
export const ADD_MODEL_SUCCESS = "ADD_MODEL_SUCCESS";
export const ADD_MODEL_ERROR = "ADD_MODEL_ERROR";
export const ADD_MODEL_CLEAR_ERROR = "ADD_MODEL_CLEAR_ERROR";
export const CLEAR_ADD_MODEL_SUCCESS = "CLEAR_ADD_MODEL_SUCCESS";

export const UPDATE_MODEL_REQUEST = "UPDATE_MODEL_REQUEST";
export const UPDATE_MODEL_SUCCESS = "UPDATE_MODEL_SUCCESS";
export const UPDATE_MODEL_ERROR = "UPDATE_MODEL_ERROR";
export const UPDATE_MODEL_CLEAR_ERROR = "UPDATE_MODEL_CLEAR_ERROR";

export const SELECT_MODEL = "SELECT_MODEL";

export const SET_DATA_TAB_ACTIVE = "SET_DATA_TAB_ACTIVE";
export const SET_COLLECTIONS_TAB_ACTIVE = "SET_COLLECTIONS_TAB_ACTIVE";
export const SET_CONFIGURATION_TAB_ACTIVE = "SET_CONFIGURATION_TAB_ACTIVE";
export const SET_PLANOGRAMS_TAB_ACTIVE = "SET_PLANOGRAMS_TAB_ACTIVE";
export const IS_PLANOGRAM_ENABLED_CHANGED = "IS_PLANOGRAM_ENABLED_CHANGED";

export const DELETE_MODEL = "DELETE_MODEL";

/*------------------------------------------------------------------------*/

/**
 * Copilots
 */
 export const GET_COPILOTS_REQUEST = "GET_COPILOTS_REQUEST";
 export const GET_COPILOTS_SUCCESS = "GET_COPILOTS_SUCCESS";
 export const GET_COPILOTS_ERROR = "GET_COPILOTS_ERROR";
 export const GET_COPILOTS_CLEAR_ERROR = "GET_COPILOTS_CLEAR_ERROR";
 export const UPDATE_COPILOT_RESOURCE_ERROR = "UPDATE_COPILOT_RESOURCE_ERROR";
 export const UPDATE_NOTIFICATION_RESOURCE_SUCCESS = "UPDATE_NOTIFICATION_RESOURCE_SUCCESS";
 export const UPDATE_NOTIFICATION_RESOURCE_REQUEST = "UPDATE_NOTIFICATION_RESOURCE_REQUEST";
 
 export const ADD_COPILOT_REQUEST = "ADD_COPILOT_REQUEST";
 export const ADD_COPILOT_SUCCESS = "ADD_COPILOT_SUCCESS";
 export const ADD_COPILOT_ERROR = "ADD_COPILOT_ERROR";
 export const ADD_COPILOT_CLEAR_ERROR = "ADD_COPILOT_CLEAR_ERROR";
 export const CLEAR_ADD_COPILOT_SUCCESS = "CLEAR_ADD_COPILOT_SUCCESS";
 
 export const UPDATE_COPILOT_REQUEST = "UPDATE_COPILOT_REQUEST";
 export const UPDATE_COPILOT_SUCCESS = "UPDATE_COPILOT_SUCCESS";
 export const UPDATE_COPILOT_ERROR = "UPDATE_COPILOT_ERROR";
 export const UPDATE_COPILOT_CLEAR_ERROR = "UPDATE_COPILOT_CLEAR_ERROR";
 
 export const SELECT_COPILOT = "SELECT_COPILOT";
 
 export const SET_ACTIVE_TAB_ACTIVE = "SET_DATA_TAB_ACTIVE";
 export const SET_EXPIRED_TAB_ACTIVE = "SET_COLLECTIONS_TAB_ACTIVE";
 export const SET_REJECTED_TAB_ACTIVE = "SET_CONFIGURATION_TAB_ACTIVE";


 export const GET_COPILOTS_NOTIFICATIONS = "GET_COPILOTS_NOTIFICATIONS";
 export const GET_COPILOTS_NOTIFICATIONS_FOR_BADGE = "GET_COPILOTS_NOTIFICATIONS_FOR_BADGE";
 export const GET_COPILOTS_NOTIFICATIONS_SUCCESS = "GET_COPILOTS_NOTIFICATIONS_SUCCESS";
 export const UPDATE_COPILOT_NOTIFICATIONS = "UPDATE_COPILOT_NOTIFICATIONS";
 export const REMOVE_COPILOT_NOTIFICATION_AND_RESOURCE = "REMOVE_COPILOT_NOTIFICATION_AND_RESOURCE";
 export const COPILOT_NOTIFICATION_AND_RESOURCE_DELETED = "COPILOT_NOTIFICATION_AND_RESOURCE_DELETED";
 export const ADD_COPILOT_NOTIFICATIONS = "ADD_COPILOT_NOTIFICATIONS";
 export const SET_COPILOT_NOTIFICATION_BADGE = "SET_COPILOT_NOTIFICATION_BADGE";
 export const RESET_NOTIFICATION_DATA = "RESET_NOTIFICATION_DATA";
 
 /*------------------------------------------------------------------------*/
 

/*------------------------------------------------------------------------*/

/**
 * Tags
 */
export const GET_TAGS_REQUEST = "GET_TAGS_REQUEST";
export const GET_TAGS_SUCCESS = "GET_TAGS_SUCCESS";
export const GET_TAGS_ERROR = "GET_TAGS_ERROR";
export const GET_TAGS_CLEAR_ERROR = "GET_TAGS_CLEAR_ERROR";
/*------------------------------------------------------------------------*/

/**
 * Collection
 */
export const GET_COLLECTION_REQUEST = "GET_COLLECTION_REQUEST";
export const GET_COLLECTION_SUCCESS = "GET_COLLECTION_SUCCESS";
export const GET_COLLECTION_ERROR = "GET_COLLECTION_ERROR";
export const GET_COLLECTION_CLEAR_ERROR = "GET_COLLECTION_CLEAR_ERROR";
export const SET_COLLECTIONS_RESOURCE_LABEL_COUNT = "SET_COLLECTIONS_RESOURCE_LABEL_COUNT";

export const SET_SELECTED_DATASET_COLLECTION = "SET_SELECTED_DATASET_COLLECTION";
export const SET_SELECTED_DATASET_COLLECTIONS = "SET_SELECTED_DATASET_COLLECTIONS";
export const ADD_SELECTED_DATASET_COLLECTIONS = "ADD_SELECTED_DATASET_COLLECTIONS";
export const SET_SELECTED_MODEL_COLLECTION = "SET_SELECTED_MODEL_COLLECTION";
export const SET_SELECTED_MODEL_COLLECTIONS = "SET_SELECTED_MODEL_COLLECTIONS";
export const SET_SELECTED_TEST_COLLECTIONS = "SET_SELECTED_TEST_COLLECTIONS";
export const ADD_SELECTED_MODEL_COLLECTIONS = "ADD_SELECTED_MODEL_COLLECTIONS";
export const SET_SELECTED_MODEL_COLLECTIONS_TO_COMPARE = "SET_SELECTED_MODEL_COLLECTIONS_TO_COMPARE";
export const ADD_SELECTED_MODEL_COLLECTIONS_TO_COMPARE = "ADD_SELECTED_MODEL_COLLECTIONS_TO_COMPARE";
export const ADD_SELECTED_TEST_COLLECTIONS = "ADD_SELECTED_TEST_COLLECTIONS";
export const SET_SELECTED_TEST_COLLECTION = "SET_SELECTED_TEST_COLLECTION";
export const SET_MODEL_COLLECTION = "SET_MODEL_COLLECTION";
export const SET_MODEL_COLLECTION_TOTAL_COUNT = "SET_MODEL_COLLECTION_TOTAL_COUNT";
export const SET_DATASET_COLLECTION_DATA_BOOST = "SET_DATASET_COLLECTION_DATA_BOOST";
export const SET_LIVE_MODEL_COLLECTION = "SET_LIVE_MODEL_COLLECTION";
export const SET_MODEL_COLLECTION_DEFAULT_CHECKPOINT = "SET_MODEL_COLLECTION_DEFAULT_CHECKPOINT"
export const SET_SHOULD_FETCH_MODEL_COLLECTIONS = "SET_SHOULD_FETCH_MODEL_COLLECTIONS";
export const SET_SHOULD_FETCH_TEST_COLLECTIONS = "SET_SHOULD_FETCH_TEST_COLLECTIONS";
export const SET_TRAINING_DATA_DIRECTORIES_STATE_FOR_ADD_MODEL_COLLECTION = "SET_TRAINING_DATA_DIRECTORIES_STATE_FOR_ADD_MODEL_COLLECTION";
export const SET_MODEL_VERSION_STATE_FOR_TEST = "SET_MODEL_VERSION_STATE_FOR_TEST";
export const SET_DEFAULT_DATASET_COLLECTION = "SET_DEFAULT_DATASET_COLLECTION";
export const SET_TEST_COLLECTIONS_STATE = "SET_TEST_COLLECTIONS_STATE";
export const SET_TEST_COLLECTION_ID_SELECTED_STATE = "SET_TEST_COLLECTION_ID_SELECTED_STATE";

export const EMPTY_MODEL_COLLECTION = "EMPTY_MODEL_COLLECTION";
export const ADD_RETEST_DETAILS_TO_MODEL_COLLECTION = "ADD_RETEST_DETAILS_TO_MODEL_COLLECTION";

export const ADD_COLLECTION_REQUEST = "ADD_COLLECTION_REQUEST";
export const ADD_COLLECTION_SUCCESS = "ADD_COLLECTION_SUCCESS";
export const ADD_COLLECTION_ERROR = "ADD_COLLECTION_ERROR";
export const ADD_COLLECTION_CLEAR_ERROR = "ADD_COLLECTION_CLEAR_ERROR";
export const CLEAR_ADD_COLLECTION_SUCCESS = "CLEAR_ADD_COLLECTION_SUCCESS";

export const UPDATE_COLLECTION_REQUEST = "UPDATE_COLLECTION_REQUEST";
export const UPDATE_COLLECTION_SUCCESS = "UPDATE_COLLECTION_SUCCESS";
export const UPDATE_COLLECTION_ERROR = "UPDATE_COLLECTION_ERROR";
export const UPDATE_COLLECTION_CLEAR_ERROR = "UPDATE_COLLECTION_CLEAR_ERROR";

export const DELETE_COLLECTION_REQUEST = "DELETE_COLLECTION_REQUEST";
export const DELETE_COLLECTION_SUCCESS = "DELETE_COLLECTION_SUCCESS";
export const UPDATE_SELECTED_DATASET_COLLECTIONS = "UPDATE_SELECTED_DATASET_COLLECTIONS";
export const DELETE_COLLECTION_ERROR = "DELETE_COLLECTION_ERROR";
export const RESET_DELETE_COLLECTION_ERROR_STATE = "RESET_DELETE_COLLECTION_ERROR_STATE";
export const DELETE_MODEL_COLLECTION_SUCCESS = "DELETE_MODEL_COLLECTION_SUCCESS";
export const DELETE_MODEL_COLLECTION_REQUEST = "DELETE_MODEL_COLLECTION_REQUEST";
export const DELETE_MODEL_COLLECTION_ERROR = "DELETE_MODEL_COLLECTION_ERROR";
export const RESET_DELETE_MODEL_COLLECTION_ERROR_STATE = "RESET_DELETE_MODEL_COLLECTION_ERROR_STATE";

export const NAVIGATE_COLLECTION = "NAVIGATE_COLLECTION";

export const CLEAR_COLLECTION = "CLEAR_COLLECTION";

export const ADD_COLLECTION_PAGE_INIT = "ADD_COLLECTION_PAGE_INIT";

export const SET_IS_DATA_SET_COLLECTION_RESOURCES_FILE_DOWNLOAD_LINK_BEING_FETCHED = "SET_IS_DATA_SET_COLLECTION_RESOURCES_FILE_DOWNLOAD_LINK_BEING_FETCHED";
export const UPDATE_MODEL_COLLECTION_DATA = "UPDATE_MODEL_COLLECTION_DATA";
export const UPDATE_TEST_COLLECTION_DATA = "UPDATE_TEST_COLLECTION_DATA";
export const SET_COLLECTION_CONFIRM_DELETE_MODAL = "SET_COLLECTION_CONFIRM_DELETE_MODAL";
export const CLOSE_COLLECTION_CONFIRM_DELETE_MODAL = "CLOSE_COLLECTION_CONFIRM_DELETE_MODAL";
export const SET_SHOULD_FETCH_LATEST_DATA_SET_COLLECTION_RESOURCE_LABELS_IF_CUTTENTLY_SHOWING_IN_SCREEN = "SET_SHOULD_FETCH_LATEST_DATA_SET_COLLECTION_RESOURCE_LABELS_IF_CUTTENTLY_SHOWING_IN_SCREEN";  

/*------------------------------------------------------------------------*/

/**
 * Data
 */
export const APPROVE_DATA_REQUEST = "APPROVE_DATA_REQUEST";
export const APPROVE_DATA_SUCCESS = "APPROVE_DATA_SUCCESS";
export const APPROVE_DATA_ERROR = "APPROVE_DATA_ERROR";

export const GET_DATA_REQUEST = "GET_DATA_REQUEST";
export const GET_DATA_SUCCESS = "GET_DATA_SUCCESS";
export const GET_DATA_SUCCESS_FOR_SELECTING_ALL_RESOURCES_MATCHING_FILTER = "GET_DATA_SUCCESS_FOR_SELECTING_ALL_RESOURCES_MATCHING_FILTER";
export const GET_DATA_ERROR = "GET_DATA_ERROR";
export const GET_DATA_CLEAR_ERROR = "GET_DATA_CLEAR_ERROR";
export const GET_API_REQUEST_CANCELLED = "GET_API_REQUEST_CANCELLED"

export const SELECT_DATA = "SELECT_DATA";
export const SET_SELECTED_DATA = "SET_SELECTED_DATA";
export const SET_RESOURCES = "SET_RESOURCES";
export const SET_RESOURCES_TOTAL_COUNT = "SET_RESOURCES_TOTAL_COUNT";
export const SET_SELECTED_ITEMS = "SET_SELECTED_ITEMS";
export const SET_DISCARDED_COUNT = "SET_DISCARDED_COUNT";
export const SET_SOLD_COUNT = "SET_SOLD_COUNT";
export const SET_ADD_COUNT = "SET_ADD_COUNT";
export const SET_RESOURCE_DATA_BOOST = "SET_RESOURCE_DATA_BOOST";

export const SET_DATA_FOR_DRAWER = "SET_DATA_FOR_DRAWER";
export const CLEAR_DATA_FOR_DRAWER = "CLEAR_DATA_FOR_DRAWER";

export const CREATE_DATA_REQUEST = "CREATE_DATA_REQUEST";
export const CREATE_DATA_SUCCESS = "CREATE_DATA_SUCCESS";
export const CREATE_DATA_ERROR = "CREATE_DATA_ERROR";
export const CREATE_DATA_CLEAR_ERROR = "CREATE_DATA_CLEAR_ERROR";

export const UPDATE_DATA_REQUEST = "UPDATE_DATA_REQUEST";
export const UPDATE_DATA_SUCCESS = "UPDATE_DATA_SUCCESS";
export const UPDATE_DATA_ERROR = "UPDATE_DATA_ERROR";
export const UPDATE_DATA_CLEAR_ERROR = "UPDATE_DATA_CLEAR_ERROR";
export const UPDATE_WHICH_RESOURCES_TO_BE_UPDATED_IN_DB = "UPDATE_WHICH_RESOURCES_TO_BE_UPDATED_IN_DB";

export const DELETE_DATA_REQUEST = "DELETE_DATA_REQUEST";
export const DELETE_DATA_SUCCESS = "DELETE_DATA_SUCCESS";
export const DELETE_DATA_ERROR = "DELETE_DATA_ERROR";

export const CLEAR_DATA = "CLEAR_DATA";

export const ADD_RESOURCES_TO_DATA_SET_COLLECTION_REQUEST = "ADD_RESOURCES_TO_DATA_SET_COLLECTION_REQUEST" 
export const ADD_RESOURCES_TO_DATA_SET_COLLECTION_SUCCESS = "ADD_RESOURCES_TO_DATA_SET_COLLECTION_SUCCESS" 

export const SET_DATA_SET_COLLECTIONS_IN_RESOURCE_REQUEST = "SET_DATA_SET_COLLECTIONS_IN_RESOURCE_REQUEST" 
export const SET_DATA_SET_COLLECTIONS_IN_RESOURCE_SUCCESS = "SET_DATA_SET_COLLECTIONS_IN_RESOURCE_SUCCESS" 

export const FETCH_DATA_SET_COLLECTIONS_FOR_FILTER_REQUEST = "FETCH_DATA_SET_COLLECTIONS_FOR_FILTER_REQUEST" 
export const FETCH_DATA_SET_COLLECTIONS_FOR_FILTER_SUCCESS = "FETCH_DATA_SET_COLLECTIONS_FOR_FILTER_SUCCESS" 
export const ADD_DATA_SET_COLLECTION_IN_DATA_SET_COLLECTIONS_FILTER = "ADD_DATA_SET_COLLECTION_IN_DATA_SET_COLLECTIONS_FILTER" 

export const SET_IS_EDIT_TEXT_SELECTED = "SET_IS_EDIT_TEXT_SELECTED"; 

export const UPDATE_RESOURCE_IMAGE_GROUP_ANNOTATIONS_REQUEST = "UPDATE_RESOURCE_IMAGE_GROUP_ANNOTATIONS_REQUEST"; 
export const UPDATE_RESOURCE_IMAGE_GROUP_ANNOTATIONS = "UPDATE_RESOURCE_IMAGE_GROUP_ANNOTATIONS"; 

export const UPDATE_RESOURCE_IMAGE_ANNOTATIONS_REQUEST = "UPDATE_RESOURCE_IMAGE_ANNOTATIONS_REQUEST"; 
export const UPDATE_RESOURCE_IMAGE_ANNOTATIONS = "UPDATE_RESOURCE_IMAGE_ANNOTATIONS"; 
export const UPDATE_RESOURCE_MULTIPLE_IMAGE_ANNOTATIONS = "UPDATE_RESOURCE_MULTIPLE_IMAGE_ANNOTATIONS"; 

export const UPDATE_RESOURCE_VIDEO_ANNOTATIONS_REQUEST = "UPDATE_RESOURCE_VIDEO_ANNOTATIONS_REQUEST"; 
export const UPDATE_RESOURCE_VIDEO_ANNOTATIONS = "UPDATE_RESOURCE_VIDEO_ANNOTATIONS"; 

export const UPDATE_RESOURCE_REMARKS_REQUEST = "UPDATE_RESOURCE_REMARKS_REQUEST"; 
export const UPDATE_RESOURCE_REMARKS = "UPDATE_RESOURCE_REMARKS"; 
export const UPDATE_RESOURCE_PROMPT = "UPDATE_RESOURCE_PROMPT"; 

export const UPDATE_VIEW_OF_DATA_MODEL = "UPDATE_VIEW_OF_DATA_MODEL"

export const ADD_RESOURCE_ERROR = "ADD_RESOURCE_ERROR"
export const CLEAR_ADD_RESOURCE_SUCCESS = "CLEAR_ADD_RESOURCE_SUCCESS"
export const ADD_RESOURCE_SUCCESS = "ADD_RESOURCE_SUCCESS"
export const ADD_RESOURCE_REQUEST = "ADD_RESOURCE_REQUEST"

export const REMOVE_DATA_SET_COLLECTIONS_FROM_RESOURCES = "REMOVE_DATA_SET_COLLECTIONS_FROM_RESOURCES";

/**
 * applicable for textFile model type resources
 */
export const SET_IS_FETCHING_RESOURCE_TEXT_FILE_CONTENTS = "SET_IS_FETCHING_RESOURCE_TEXT_FILE_CONTENTS"
export const SET_RESOURCE_TEXT_FILE_CONTENTS = "SET_RESOURCE_TEXT_FILE_CONTENTS"

/**
 * This is used to update any resource object property
 */
export const UPDATE_RESOURCE_IN_UI = "UPDATE_RESOURCE_IN_UI";
/*------------------------------------------------------------------------*/

export const SET_QUERY_PARAMS = "SET_QUERY_PARAMS";
export const DELETE_RESOURCE_TRIMMED_TEXT_IN_UI = "DELETE_RESOURCE_TRIMMED_TEXT_IN_UI";

/**
 * Drawer
 */
export const CHANGE_OPTIONS_DRAWER = "CHANGE_OPTIONS_DRAWER";
export const CHANGE_SIDE_DRAWER = "CHANGE_SIDE_DRAWER";
export const SET_DRAWER_OPEN = "SET_DRAWER_OPEN";
/*------------------------------------------------------------------------*/


/**
 * CoPilotDetailPage
 */
export const SET_CO_PILOT_DETAIL_PAGE_STATE = "SET_CO_PILOT_DETAIL_PAGE_STATE"
export const GET_RESOURCES_ERROR = "GET_RESOURCES_ERROR";
export const GET_RESOURCES_SUCCESS = "GET_RESOURCES_SUCCESS";
export const SET_CO_PILOT_RESOURCES = "SET_CO_PILOT_RESOURCES";
export const SET_CO_PILOT_RESOURCE_DATA = "SET_CO_PILOT_RESOURCE_DATA";
export const SET_SELECTED_CO_PILOT_RESOURCE = "SET_SELECTED_CO_PILOT_RESOURCE";
export const REMOVE_COPILOT_RESOURCE_FROM_UI = "REMOVE_COPILOT_RESOURCE_FROM_UI";
export const SET_DATA_FETCHING_LOADER_VALUE = "SET_DATA_FETCHING_LOADER_VALUE";
export const REMOVE_ALL_COPILOT_RESOURCES = "REMOVE_ALL_COPILOT_RESOURCES";
export const UPDATE_SELECTED_TAB = "UPDATE_SELECTED_TAB";
export const SET_CO_PILOT_RESOURCES_ONLY = "SET_CO_PILOT_RESOURCES_ONLY";
export const SET_CO_PILOT_RESOURCES_TOTAL_COUNT_ONLY = "SET_CO_PILOT_RESOURCES_TOTAL_COUNT_ONLY";

export const UPDATE_COPILOT_RESOURCE_IMAGE_ANNOTATIONS_REQUEST = "UPDATE_COPILOT_RESOURCE_IMAGE_ANNOTATIONS_REQUEST"; 
export const UPDATE_COPILOT_RESOURCE_IMAGE_ANNOTATIONS = "UPDATE_COPILOT_RESOURCE_IMAGE_ANNOTATIONS"; 
export const UPDATE_COPILOT_RESOURCE = "UPDATE_COPILOT_RESOURCE";

export const UPDATE_COPILOT_RESOURCE_TRIMMED_TEXTS_IN_UI = "UPDATE_COPILOT_RESOURCE_TRIMMED_TEXTS_IN_UI";
export const DELETE_COPILOT_RESOURCE_TRIMMED_TEXT_IN_UI = "DELETE_COPILOT_RESOURCE_TRIMMED_TEXT_IN_UI";
export const UPDATE_COPILOT_RESOURCE_LABEL_IN_UI = "UPDATE_COPILOT_RESOURCE_LABEL_IN_UI";

/*------------------------------------------------------------------------*/


/**
 *  web socket
 */
 export const WS_CONNECT = "WS_CONNECT";
 export const WS_CONNECTED = "WS_CONNECTED";
 export const WS_DISCONNECT = "WS_DISCONNECT";
 export const WS_DISCONNECTED = "WS_DISCONNECTED";
 
 /*------------------------------------------------------------------------*/
 
