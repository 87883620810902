import { Dispatch } from "redux";

import { ADD_BREADCRUMBS, REMOVE_ALL_BREADCRUMBS, REMOVE_BREADCRUMBS, SET_TOP_ACTION_BUTTON, UPDATE_BREADCRUMBS_PATH } from "../types";

export type AddBreadcrumbsActionPayload = { [key: string]: string }[]

export const addBreadcrumbsDispatch = (data: AddBreadcrumbsActionPayload) => {
    return {
        type: ADD_BREADCRUMBS,
        payload: data,
    }
}

export const addBreadcrumbs = (data: AddBreadcrumbsActionPayload) => async (dispatch: Dispatch) => {
    // dispatch({
    //     type: ADD_BREADCRUMBS,
    //     payload: data,
    // });
    dispatch(addBreadcrumbsDispatch(data));
}

export const removeBreadcrumbs = (index: number) => async (dispatch: Dispatch) => {
    dispatch({
        type: REMOVE_BREADCRUMBS,
        payload: index,
    });
}

export const updateBreadPathcrumbs = (data : { index: number; path: string } ) => async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_BREADCRUMBS_PATH,
      payload: data,
    });
}

export const setTopActionButton = (data: { type: string, onClickComponentToRender?: React.FunctionComponent }) => async (dispatch: Dispatch) => {
    dispatch({
        type: SET_TOP_ACTION_BUTTON,
        payload: data,
    });
}

export const setBreadcrumbsTillProjectSelected = () => async (dispatch: Dispatch) => {
    dispatch({
        type: REMOVE_BREADCRUMBS,
        payload: 2,
    });
}

export const setBreadcrumbsTillHome = () => async (dispatch: Dispatch) => {
    dispatch({
        type: REMOVE_BREADCRUMBS,
        payload: 1,
    });
}

export const setBreadcrumbsForYourApiTokensPage = () => async (dispatch: Dispatch) => {
    dispatch({
        type: REMOVE_ALL_BREADCRUMBS
    })

    /**
     * adding breadcrumbs from beginning
     */
    dispatch({
        type: ADD_BREADCRUMBS,
        payload: [
            {
                label:"Home",
                path:"/admin/projects"
            },
            {
                label:"Configurations",
                path:"/admin/configurations"
            },
            {
                label:"API Tokens",
                path:"/admin/configurations/apiTokens/yourApiTokens"
            },
        ]
    })
}
