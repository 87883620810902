import { makeStyles } from "@material-ui/core";
import { Skeleton, SkeletonProps } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.grey[100]
    }
}));

export const CustomSkeleton = (props: SkeletonProps) => {
    const classes = useStyles();
    return (
        <Skeleton classes={{ root: classes.root }} {...props}>
            {props.children}
        </Skeleton>
    )
}