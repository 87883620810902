import { AnyAction } from "redux";

import {
    ICoreState,
} from "../../common";

import { ADD_BREADCRUMBS, REMOVE_ALL_BREADCRUMBS, REMOVE_BREADCRUMBS, SET_TOP_ACTION_BUTTON, UPDATE_BREADCRUMBS_PATH } from '../types';

const initialState: ICoreState = {
    breadcrumbs: [{ label: 'Home', path: '/admin/projects' }],
}

const project = (state: ICoreState = initialState, action: AnyAction) => {
    const { type, payload } = action;
    switch (type) {
        case ADD_BREADCRUMBS:
            return {
                ...state,
                breadcrumbs: [
                    ...state.breadcrumbs,
                    ...payload
                ],
            };
        case REMOVE_BREADCRUMBS:
            return {
                ...state,
                breadcrumbs: state.breadcrumbs.slice(0, payload)
            };
        case REMOVE_ALL_BREADCRUMBS:
            return {
                ...state,
                breadcrumbs: []
            };
        case SET_TOP_ACTION_BUTTON:
            return {
                ...state,
                topActionButton: payload
            };
        case UPDATE_BREADCRUMBS_PATH:
          const breadcrumbsNewState = [...state.breadcrumbs];
          breadcrumbsNewState[payload.index] = { ...breadcrumbsNewState[payload.index], path: payload.path };
          return {
            ...state,
            breadcrumbs: breadcrumbsNewState
          };
        default:
            return state
    }
}

export default project;
