import { Dispatch } from "redux";

import { IProjectAddPayload, IProject, IProjectQueryParams, actionsErrorHandling } from "../../common";

import {
    ADD_PROJECT_ERROR,
    ADD_PROJECT_REQUEST,
    ADD_PROJECT_SUCCESS,
    CLEAR_ADD_PROJECT_SUCCESS,
    GET_PROJECTS_ERROR,
    GET_PROJECTS_REQUEST,
    GET_PROJECTS_SUCCESS,
} from "../types";
import { ProjectService } from "../../services";
import { socket } from "../../services/config";

export const getProjects = (data?: IProjectQueryParams, refresh = false) => async (dispatch: Dispatch) => {
    if (!refresh) {
        dispatch({
            type: GET_PROJECTS_REQUEST,
            payload: null,
        });
    }
    try {
        const res = await ProjectService.getProjects(data);
        dispatch({
            type: GET_PROJECTS_SUCCESS,
            payload: res.data,
        });
        // socket.connection.emit('getProjects', { limit: parseInt(data?.limit || '1', 10), offset: parseInt(data?.offset || '10', 10) });
        // await listenToGetProjects()(dispatch);
    } catch (err) {
        console.log(err);
        actionsErrorHandling(err, GET_PROJECTS_ERROR, dispatch);
    }
}

// export const listenToGetProjects = () => async (dispatch: Dispatch) => {
//     socket.connection.on('getProjectsResponse', (res: IProject[]) => {
//         dispatch({
//             type: GET_PROJECTS_SUCCESS,
//             payload: { total: res.length, projects: res },
//         });
//     });
// };

// export const listenToProjectChanges = () => async (dispatch: Dispatch) => {
//     console.log('listening');
//     socket.connection.emit('subscribeToProjects');
//     socket.connection.on('projectChange', (res: { type: string, old_val: { [key: string]: string }, new_val: { [key: string]: string } }) => {
//         if (res.type === 'add') {
//             dispatch({
//                 type: ON_PROJECT_ADD,
//                 payload: res.new_val,
//             });
//         }
//     });
// };

export const unsubscribeToProjectChanges = () => async (dispatch: Dispatch) => {
    socket.connection.off('projectChange');
    socket.connection.off('getProjectsResponse');
};

export const addProject = (data: IProjectAddPayload) => async (dispatch: Dispatch) => {
    dispatch({
        type: ADD_PROJECT_REQUEST
    });
    try {
        const res = await ProjectService.addProject(data);
        await getProjects({ limit: '1000', offset: '1' }, true)(dispatch);
        dispatch({
            type: ADD_PROJECT_SUCCESS,
            payload: res.data,
        });
        dispatch({
            type: CLEAR_ADD_PROJECT_SUCCESS
        })
    } catch (err) {
        console.log(err);
        actionsErrorHandling(err, ADD_PROJECT_ERROR, dispatch);
    }
}

export const setSelectedProject = (data: IProject) => async (dispatch: Dispatch) => {
    dispatch({
        type: GET_PROJECTS_REQUEST,
        payload: data,
    });
}

// export const addCollection = (data: IAddCollectionPayload) => async (dispatch: Dispatch) => {
//     dispatch({
//         type: ADD_COLLECTION_REQUEST
//     });
//     data.model = '6094fe053bddac17dca44792'; // TODO make model dynamic
//     data.project_id = 'Faceopen';
//     data.remove_columns = ["sno", "sV", "5mmsV"];
//     try {
//         const res = await ProjectService.addCollection(data);
//         dispatch({
//             type: ADD_COLLECTION_SUCCESS,
//             payload: res.data,
//         });
//     } catch (err) {
//         console.log(err);
//         actionsErrorHandling(err, ADD_COLLECTION_ERROR, dispatch);
//     }
// }

// export const clearSelectedCollection = () => async (dispatch: Dispatch) => {
//     dispatch({
//         type: CLEAR_ADD_COLLECTION_SUCCESS,
//     })
// }

