import { useLocation } from 'react-router-dom';

export function useQuery() {
    return new URLSearchParams(useLocation().search);
    // const string = useLocation().search;
    // const object = Object.fromEntries(new URLSearchParams(string));
    // return {
    //     string,
    //     object
    // }
}

export * from './api';
export * from './date';
export * from './errorHandling';
