import { AnyAction } from "redux";

import { IOptionsDrawerState } from "../../common";

import { CHANGE_SIDE_DRAWER, SET_DRAWER_OPEN } from '../types';

const initialState: IOptionsDrawerState = {
    type: 'data',
    isOpen: false
}

const optionsDrawer = (state: IOptionsDrawerState = initialState, action: AnyAction): IOptionsDrawerState => {
    const { type, payload } = action;
    switch (type) {
        case CHANGE_SIDE_DRAWER:
            return {
                ...state,
                ...payload,
            }
        case SET_DRAWER_OPEN:
            return {
                ...state,
                isOpen: payload
            }
        default:
            return state
    }
}

export default optionsDrawer;
