// import { moveDataFromExpiredAfterCertainTime } from './../common/constants/interfaces/models';
// import { CoPilotResourceStatus, ICoPilotResource } from './../common/constants/interfaces/coPilotResource';
import axios, { AxiosResponse, Canceler} from "axios";

import { IReferenceGroupState, queryObjectToString, TrimmedText, UpdateModelApiRequestPayload } from "../common";
import {
  CoPilotConfigurationResponse,
  CreateCoPilotResourceApiRequestPayload,
  GetCopilotByIdApiReqPayload,
  GetCopilotByIdApiResponse,
  GetCopilotReferenceDataAPiReqPayload,
  ICopilotAddPayload,
  ICoPilotAPIQueryParams,
  ICopilotQueryParams,
  ICopilotResponse,
  UpdateCopilotApiResponse
} from "../common/constants/interfaces/copilot";

import { API } from "./API";
import { copyByValue } from './variableHelperService';
import { CoPilotResourceTimerEndAt } from '../common/constants/interfaces/coPilotResource';

const URL_STRINGS = {
  COOPILOTS: "coPilot",
  RESOURCES: "coPilotResource",
};
const CancelToken = axios.CancelToken;
let cancelgetResourcesApiCall: Canceler;
class CopilotService extends API {
  getCopilots(
    data: ICopilotQueryParams
  ): Promise<AxiosResponse<ICopilotResponse>> {
    return this.api.get(
      `${URL_STRINGS.COOPILOTS}?${queryObjectToString(data)}`
    );
  }

  deleteCopilot(body: {coPilot: string}): Promise<AxiosResponse<unknown>> {
    return this.api.delete(URL_STRINGS.COOPILOTS, { data: body });
  }

  getCoPilotById(
    data: GetCopilotByIdApiReqPayload
  ): Promise<AxiosResponse<GetCopilotByIdApiResponse>> {
    const dataWithoutCoPilotId = copyByValue(data);

    // @ts-ignore
    delete dataWithoutCoPilotId.coPilotId;

    return this.api.get(
      `${URL_STRINGS.COOPILOTS}/${data.coPilotId}?${queryObjectToString(
        dataWithoutCoPilotId
      )}`
    );
  }

  getResources(data: ICopilotQueryParams): Promise<AxiosResponse<any>> {
    // if (cancelgetResourcesApiCall) {
    //   // cancelgetResourcesApiCall();
    // }

    return this.api.get(
      `${URL_STRINGS.RESOURCES}?${queryObjectToString(data)}`,
      {
        cancelToken: new CancelToken(function executor(c) {
          cancelgetResourcesApiCall = c;
        }),
      }
    );
  }

  getResourceById(coPilotId: string): Promise<AxiosResponse<any>> {
    return this.api.get(`${URL_STRINGS.RESOURCES}/${coPilotId}`);
  }

  rejectResources(data: any): Promise<AxiosResponse<any>> {
    console.log("running reject resource", data);
    return this.api.put(`${URL_STRINGS.RESOURCES}/`, data);
  }

  updateResource(data: any): Promise<AxiosResponse<any>> {
    return this.api.put(`${URL_STRINGS.RESOURCES}`, data);
  }

  getNotifications(
    project: string,
    params?: ICoPilotAPIQueryParams,
    limit?: string
  ): Promise<AxiosResponse<any>> {
    const queryParams = {
      project,
      status: "active",
      sortOrder: "ascending",
      offset: params ? params.offset : "-1",
      limit: params ? params.limit : limit || "-1",
      sortField: CoPilotResourceTimerEndAt,
    };
    return this.api.get(
      `${URL_STRINGS.RESOURCES}?${queryObjectToString(queryParams)}`
    );
  }

  createCoPilotResource(
    resource: CreateCoPilotResourceApiRequestPayload
  ): Promise<AxiosResponse<any>> {
    const bodyFormData = new FormData();

    for (
      let jsonKeyIndex = 0;
      jsonKeyIndex < Object.keys(resource).length;
      jsonKeyIndex++
    ) {
      const jsonKey: string = Object.keys(resource)[jsonKeyIndex];
      // @ts-ignore
      const jsonKeyValue: any = resource[jsonKey] as any;

      if (jsonKey === "imageAnnotations" || jsonKey === "trimmedTexts" || jsonKey === "audioTranscriptionWordsMetadatas") {
        bodyFormData.append(jsonKey, JSON.stringify(jsonKeyValue));
      } else if (Array.isArray(jsonKeyValue)) {
        for (let index = 0; index < jsonKeyValue.length; index++) {
          let arrayElement = jsonKeyValue[index];
          if (typeof arrayElement === "object") {
            arrayElement = JSON.stringify(arrayElement);
          }
          bodyFormData.append(jsonKey, arrayElement);
        }
      } else {
        bodyFormData.append(jsonKey, jsonKeyValue);
      }
    }

    return this.api.post(`${URL_STRINGS.RESOURCES}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  setActiveResourceExtendTime(resourceId: string, resourceTimerEndAt: string, copilotId: string): Promise<AxiosResponse<any>> {
    return this.api.put(
      `${URL_STRINGS.RESOURCES}/onActiveResourceExtendTimePopupOpen/${resourceId}`,
      {
        resourceTimerEndAt,
        copilotId
      }
    );
  }

  extendResourceTime(resourceId: string, resourceTimerEndAt: string, copilotId: string): Promise<AxiosResponse<any>> {
    return this.api.put(
      `${URL_STRINGS.RESOURCES}/extendActiveResourceTimer/${resourceId}`,
      {
        resourceTimerEndAt,
        copilotId        
      }
    );
  }

  copilotConfiguration(copilotId: string, data: UpdateModelApiRequestPayload): Promise<AxiosResponse<CoPilotConfigurationResponse>>{
        return this.api.put(`${URL_STRINGS.COOPILOTS}`, {
      id: [copilotId],
      name: data.name,
      allowUserToSetAnyLabel: data.allowUserToSetAnyLabel,
      apiURLToCallOnApprovingResource: data.apiURLToCallOnApprovingResource,
      annotationLabelsAvailable: data.annotationLabelsAvailable,
      shouldSendResourceToModel: data.shouldSendResourceToModel,
      modelIdToAddResourceInto: data.modelIdToAddResourceInto,
      extendActiveResourceTimeInSeconds: data.extendActiveResourceTimeInSeconds,
      shouldExtendTimeForActiveResource: data.shouldExtendTimeForActiveResource,
      labelsAvailable: data.copilotLabelsAvailable,
      labelsForRejectedReasons: data.copilotRejectedLabelsAvailable,
      is500WCompressionEnabled: data.is500WCompressionEnabled,
      sortOrder: data.sortOrder,
      showContentMetadataInfo: data.showContentMetadataInfo,
      showLabelDropdown: data.showLabelDropdown,
      showConfidenceScore: data.showConfidenceScore,
      resourcesAutoDelete: data.resourcesAutoDelete,
      numberOfDaysAfterResourcesShouldGetDeleted : data.numberOfDaysAfterResourcesShouldGetDeleted,
      actionsInCopilotActivePage: data.actionsInCopilotActivePage,
      activePageActions: data.activePageActions,
      referenceDataFiles: data.files,
          activeTimerDuration: data.activeTimerDuration,
      moveDataFromExpiredAfterCertainTime: data.moveDataFromExpiredAfterCertainTime
    });
  }

  createAndSaveTrimmedTextInDB(requestPayload: {
    trimmedTextId: string,
    resourceId: string,
    startPosition: TrimmedText["startPosition"],
    endPosition: TrimmedText["endPosition"],
    label: TrimmedText["label"], 
    isPiiData?: TrimmedText["isPiiData"] 
  }): Promise<AxiosResponse<TrimmedText>> {
      return this.api.post(`${URL_STRINGS.RESOURCES}/trimmedText`, requestPayload)
  } 
  
  updateTrimmedTextInDB(requestPayload: {
    trimmedTextId: string,
    startPosition?: TrimmedText["startPosition"],
    endPosition?: TrimmedText["endPosition"],
    label?: TrimmedText["label"], 
    isPiiData?: TrimmedText["isPiiData"],
    tag ?: TrimmedText["tag"],        
  }): Promise<AxiosResponse<void>> {
      return this.api.put(`${URL_STRINGS.RESOURCES}/trimmedText`, requestPayload) 
  }  

  deleteTrimmedTextInDB(requestPayload: {
    trimmedTextId: string,
  }): Promise<AxiosResponse<void>> {
      return this.api.delete(`${URL_STRINGS.RESOURCES}/trimmedText?trimmedTextID=${requestPayload.trimmedTextId}`) 
  } 

  addCopilot(body: ICopilotAddPayload): Promise<AxiosResponse<unknown>> {
    return this.api.post(URL_STRINGS.COOPILOTS, body);
  }

  updateCopilotReferenceData(
    data: FormData
  ): Promise<AxiosResponse<UpdateCopilotApiResponse>> {
    return this.api.put(`${URL_STRINGS.COOPILOTS}/referenceData`, data, 
      {
        headers: {'Content-Type': 'multipart/form-data'}
    });
  }

  getCopilotReferenceData(data: GetCopilotReferenceDataAPiReqPayload) : Promise<AxiosResponse<IReferenceGroupState[]>>{
    return this.api.get(
      `${URL_STRINGS.COOPILOTS}/${data.copilotId}/referenceData`,
    );
  }
}


// const test = new CopilotService();
export default new CopilotService();
