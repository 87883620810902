import { combineReducers } from 'redux'

import data from './data';
import collection from './collection';
import tag from './tag';
import optionsDrawer from './optionsDrawer';
import project from './project';
import model from './model';
import core from './core';

import copilots from './copilot';

import coPilotDetailPage from './coPilotDetailPage';
import updateDashboardAnalyticTabState  from './dashboardAnalyticsReducer'
export default combineReducers({
    project,
    model,
    data,
    collection,
    tag,
    optionsDrawer,
    core,
    copilots,
    coPilotDetailPage,
    updateDashboardAnalyticTabState,
});
