import { Dispatch } from "redux";


import { IModelQueryParams, actionsErrorHandling, IModelAddPayload } from "../../common";

import {
    ADD_MODEL_ERROR,
    ADD_MODEL_REQUEST,
    ADD_MODEL_SUCCESS,
    CLEAR_ADD_MODEL_SUCCESS,
    GET_MODELS_ERROR,
    GET_MODELS_REQUEST,
    GET_MODELS_SUCCESS,
} from "../types";
import { ModelService } from "../../services";
import { SET_DATA_TAB_ACTIVE, SET_COLLECTIONS_TAB_ACTIVE, SET_CONFIGURATION_TAB_ACTIVE, DELETE_MODEL, SET_PLANOGRAMS_TAB_ACTIVE, IS_PLANOGRAM_ENABLED_CHANGED } from '../types/actions';

export const getProjectModels = (data: IModelQueryParams, refresh = false) => async (dispatch: Dispatch) => {
    if (!refresh) {
        dispatch({
            type: GET_MODELS_REQUEST,
            payload: null,
        });
    }
    try {
        const res = await ModelService.getProjectModels(data);
        dispatch({
            type: GET_MODELS_SUCCESS,
            payload: res.data,
        });
    } catch (err) {
        console.log(err);
        actionsErrorHandling(err, GET_MODELS_ERROR, dispatch);
    }
}

export const addModel = (data: IModelAddPayload) => async (dispatch: Dispatch) => {
    dispatch({
        type: ADD_MODEL_REQUEST
    });
    try {
        const res = await ModelService.addModel(data);
        dispatch({
            type: ADD_MODEL_SUCCESS,
            payload: res.data,
        });
        dispatch({
            type: CLEAR_ADD_MODEL_SUCCESS
        });
    } catch (err) {
        console.log(err);
        actionsErrorHandling(err, ADD_MODEL_ERROR, dispatch);
    }
}

export const setDataTabActive = () => async (dispatch: Dispatch) => {
    dispatch({
        type: SET_DATA_TAB_ACTIVE
    });
}

export const setCollectionsTabActive = () => async (dispatch: Dispatch) => {
    dispatch({
        type: SET_COLLECTIONS_TAB_ACTIVE
    });
}
export const setConfigurationTabActive = () => async (dispatch: Dispatch) => {
    dispatch({
        type: SET_CONFIGURATION_TAB_ACTIVE
    });
}

export const setPlanogramsTabActiveWhenDispatchParam = (dispatch: Dispatch) => {
    return dispatch({
        type: SET_PLANOGRAMS_TAB_ACTIVE
    });
}

export const setIsPlanogramEnabledChangedToSetPlanogramsTab = (dispatch: Dispatch) => {
    return dispatch({
        type: IS_PLANOGRAM_ENABLED_CHANGED
    });
}

export const deleteModel = (data: {modelId: string}) => async (dispatch: Dispatch) => {
    dispatch({
        type: DELETE_MODEL,
        payload: data
    });
}
