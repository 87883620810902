import React from "react";

/**
 * @description
 * This error boundary component refreshes the webpage if chunk loading error received due to code splitting after deploying a new version of react app.
 * For reference, see this article: https://mitchgavan.com/code-splitting-react-safely/
 */

type State = {
  hasError: boolean;
};

function setWithExpiry(key: string, value: string, ttl: number) {
  const item = {
    value: value,
    expiry: new Date().getTime() + ttl,
  };
  sessionStorage.setItem(key, JSON.stringify(item));
}

export function getWithExpiry(key: string) {
  const itemString = window.sessionStorage.getItem(key);
  if (!itemString) return null;

  const item = JSON.parse(itemString);
  const isExpired = new Date().getTime() > item.expiry;

  if (isExpired) {
    sessionStorage.removeItem(key);
    return null;
  }

  return item.value;
}

const handleError = (errorMessage: string) => {
  console.log("ErrorBoundary handleError ~ errorMessage", errorMessage);
  const chunkFailedMessage = /Loading chunk [\d]+ failed/;
  if (errorMessage && chunkFailedMessage.test(errorMessage)) {
    if (!getWithExpiry("chunk_failed")) {
      setWithExpiry("chunk_failed", "true", 10000);
      console.log("ErrorBoundary: reloading page");
      window.location.reload();
    }
  }
  if (errorMessage) {
    return {hasError: true}
  }
};

export default class ErrorBoundary extends React.Component<{}, State> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    console.log("ErrorBoundary ~ getDerivedStateFromError ~ error", error);

    return handleError(error.message);

    // Update state so the next render will show the fallback UI.
    // return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.log("ErrorBoundary ~ componentDidCatch ~ errorInfo", errorInfo);
    console.log("ErrorBoundary ~ componentDidCatch ~ error", error);
  }

  render() {
    // if (this.state.hasError) {
    //   // You can render any custom fallback UI
    //   return (
    //     <div
    //       style={{
    //         height: "100vh",
    //         width: "100vw",
    //         display: "flex",
    //         justifyContent: "center",
    //         alignItems: "center"
    //       }}
    //     >
    //       <h4>Something went wrong. Please try refreshing the web page</h4>
    //     </div>
    //   );
    // }

    return this.props.children;
  }
}
