import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { IData, IDataResponse } from "../../../../../../../common";
import BrowseCollectionsPopupContext from "./browseCollectionsPopup.context";

export default function BrowseCollectionsPopupContextComponent(props: { children: ReactElement }) {

    const initialResources: IData[] = []
    //@ts-ignore
    const [itemsAddedToCart, setItemsAddedToCart] = useState<IDataResponse>({ resources: [...initialResources] })
    const [breadCrumbs, setBreadCrumbs] = useState<string[]>([])
    // @ts-ignore
    const [itemsSavedForLater, setItemsSavedForLater] = useState<IDataResponse>({ resources: [...initialResources] })


    function toggleItemInCart(dir: number, item: IData) {
        //direction = 1 means add to cart and -1 means remove from cart
        if (dir !== 1 && dir !== -1)
            return;

        if (dir === 1) {
            //@ts-ignore
            setItemsAddedToCart((prevState) => {
                return {
                    resources: [
                        ...(prevState.resources || []),
                        item
                    ]
                }
            })
            // remove item form savedForLater if it exists
            //@ts-ignore
            setItemsSavedForLater((prevState) => {
                return {
                    resources: prevState.resources?.filter((obj: IData) => obj._id !== item._id)
                }
            })
        }
        else {
            //@ts-ignore
            setItemsAddedToCart((prevState) => {
                return {
                    resources: prevState.resources?.filter((obj: IData) => obj._id !== item._id)
                }
            })
        }
    }

    function toggleItemInSavedForLater(dir: number, item: IData, options?: {
        scenario?: "AI_Assisted_Labelling_Browse_Data" | undefined
    }) {

        const scenario = options?.scenario;

        //direction = 1 means add to savedForLater and -1 means remove from savedForLater
        if (dir !== 1 && dir !== -1)
            return;

        if (dir === 1) {
            //@ts-ignore
            setItemsSavedForLater((prevState) => {
                return {
                    resources: [
                        ...(prevState.resources || []),
                        item
                    ]
                }
            })
            if (scenario !== "AI_Assisted_Labelling_Browse_Data") {
                toggleItemInCart(-1, item)
            }
        }
        else {
            //@ts-ignore
            setItemsSavedForLater((prevState) => {
                return {
                    resources: prevState.resources.filter((obj: IData) => obj._id !== item._id)
                }
            })
            if (scenario !== "AI_Assisted_Labelling_Browse_Data") { 
                toggleItemInCart(1, item)
            }
        }
    }

    const browseCollectionsPopupContextValue = useMemo(() => {
        return {
            itemsAddedToCart,
            setItemsAddedToCart,
            toggleItemInCart,
            breadCrumbs,
            setBreadCrumbs,
            itemsSavedForLater,
            setItemsSavedForLater,
            toggleItemInSavedForLater
        }
    }, [itemsAddedToCart, breadCrumbs, itemsSavedForLater])


    return (
        <BrowseCollectionsPopupContext.Provider value={browseCollectionsPopupContextValue}>
            {props.children}
        </BrowseCollectionsPopupContext.Provider>
    )
}