import { SET_DATASET_COLLECTION_DATA_BOOST, ADD_RETEST_DETAILS_TO_MODEL_COLLECTION, UPDATE_MODEL_COLLECTION_DATA, UPDATE_SELECTED_DATASET_COLLECTIONS, SET_COLLECTIONS_RESOURCE_LABEL_COUNT, UPDATE_TEST_COLLECTION_DATA, SET_COLLECTION_CONFIRM_DELETE_MODAL, CLOSE_COLLECTION_CONFIRM_DELETE_MODAL, ADD_SELECTED_TEST_COLLECTIONS, SET_SELECTED_TEST_COLLECTIONS } from './../types/actions';

import { Dispatch } from "redux";

import { ICollectionQueryParams, actionsErrorHandling, IAddCollectionPayload, ICollection, IDeleteCollectionPayload, IModelCollection, ITrainingDataDirectoriesSelectedStateForAddModelCollection, setIsDataSetCollectionResourcesFileDownloadLinkBeingFetchedActionPayload, IDefaultDataSetCollection, IMergeCollectionPayload, ITestVersionSelectedStateForTestModelCollection, ITestCollection, ITestCollectionsResponse, IDeleteModelCollectionPayload, IReTestingDetailsUpdate, IDeleteCollectionConfirmPayload, Navigation, IModelCollectionDeleteResponse } from "../../common";

import {
    GET_COLLECTION_ERROR,
    GET_COLLECTION_REQUEST,
    GET_COLLECTION_SUCCESS,
    ADD_COLLECTION_REQUEST,
    ADD_COLLECTION_SUCCESS,
    ADD_COLLECTION_ERROR,
    CLEAR_ADD_COLLECTION_SUCCESS,
    SET_SELECTED_DATASET_COLLECTION,
    SET_SELECTED_MODEL_COLLECTION,
    DELETE_COLLECTION_REQUEST,
    ADD_COLLECTION_PAGE_INIT,
    SET_SHOULD_FETCH_MODEL_COLLECTIONS
} from "../types";
import { DataService, ModelService } from "../../services";
import { DELETE_COLLECTION_SUCCESS, DELETE_COLLECTION_ERROR, SET_SELECTED_DATASET_COLLECTIONS, ADD_SELECTED_DATASET_COLLECTIONS, SET_TRAINING_DATA_DIRECTORIES_STATE_FOR_ADD_MODEL_COLLECTION, SET_IS_DATA_SET_COLLECTION_RESOURCES_FILE_DOWNLOAD_LINK_BEING_FETCHED, SET_DEFAULT_DATASET_COLLECTION, SET_MODEL_COLLECTION, SET_MODEL_COLLECTION_TOTAL_COUNT, SET_MODEL_COLLECTION_DEFAULT_CHECKPOINT, RESET_DELETE_COLLECTION_ERROR_STATE, SET_SELECTED_TEST_COLLECTION, SET_SHOULD_FETCH_TEST_COLLECTIONS, SET_MODEL_VERSION_STATE_FOR_TEST, SET_TEST_COLLECTIONS_STATE, SET_TEST_COLLECTION_ID_SELECTED_STATE, RESET_DELETE_MODEL_COLLECTION_ERROR_STATE, DELETE_MODEL_COLLECTION_SUCCESS, DELETE_MODEL_COLLECTION_REQUEST, DELETE_MODEL_COLLECTION_ERROR, EMPTY_MODEL_COLLECTION, SET_DRAWER_OPEN, ADD_SELECTED_MODEL_COLLECTIONS, SET_SELECTED_MODEL_COLLECTIONS, SET_SELECTED_MODEL_COLLECTIONS_TO_COMPARE, ADD_SELECTED_MODEL_COLLECTIONS_TO_COMPARE, SET_SHOULD_FETCH_LATEST_DATA_SET_COLLECTION_RESOURCE_LABELS_IF_CUTTENTLY_SHOWING_IN_SCREEN } from '../types/actions';
// import resource from '../../features/admin/features/projects/features/coPilot/features/components/resource/resource';


export const getCollections = (data: ICollectionQueryParams, refresh = false, selectedCollection?: ICollection | null , navigation?: Navigation | null) => async (dispatch: Dispatch) => {
    if (!refresh) {
        dispatch({
            type: GET_COLLECTION_REQUEST,
            payload: null,
        });
    }
    try {
        const res = await DataService.getCollections(data);
        if(res.data.collections.length > 0) {
            if(selectedCollection!== null && selectedCollection !== undefined && navigation === "moveToLeft"){
                dispatch({
                    type: SET_SELECTED_DATASET_COLLECTION,
                    payload: res.data.collections[res.data.collections.length-1]
                });
            } else if (selectedCollection!== null && selectedCollection !== undefined && navigation === "moveToRight"){
                dispatch({
                    type: SET_SELECTED_DATASET_COLLECTION,
                    payload: res.data.collections[0]
                });
            } 
            // else if (selectedCollection!== null && selectedCollection !== undefined){
            //     dispatch({
            //         type: SET_SELECTED_DATASET_COLLECTION,
            //         payload: null,
            //     });
            // }
        }
        dispatch({
            type: GET_COLLECTION_SUCCESS,
            payload: res.data,
        });
    } catch (err) {
        console.log(err);
        actionsErrorHandling(err, GET_COLLECTION_ERROR, dispatch);
    }
}

export const addCollection = (data: IAddCollectionPayload) => async (dispatch: Dispatch) => {
    dispatch({
        type: ADD_COLLECTION_REQUEST
    });
    // data.remove_columns = ["sno", "sV", "5mmsV"]; // TODO make model dynamic
    try {
        const res = await DataService.addCollection(data);
        dispatch({
            type: ADD_COLLECTION_SUCCESS,
            payload: res.data,
        });
    } catch (err) {
        console.log(err);
        actionsErrorHandling(err, ADD_COLLECTION_ERROR, dispatch);
    }
}

export const mergeCollection = (data: IMergeCollectionPayload) => async (dispatch: Dispatch) => {
    console.log("here")
    dispatch({
        type: ADD_COLLECTION_REQUEST
    });
    // data.remove_columns = ["sno", "sV", "5mmsV"]; // TODO make model dynamic
    try {
        const res = await DataService.addCollection(data);
        console.log(res);
        dispatch({
            type: ADD_COLLECTION_SUCCESS,
            payload: res.data,
        });
    } catch (err) {
        console.log(err);
        actionsErrorHandling(err, ADD_COLLECTION_ERROR, dispatch);
    }
}

export const deleteCollection = (data: IDeleteCollectionPayload) => async (dispatch: Dispatch) => {
    dispatch({
        type: DELETE_COLLECTION_REQUEST,
        payload: data
    });
    try {
        const res = await DataService.deleteCollection(data);
        // console.log(" :::: :::: ",res)
        if (res.data.deletedDataSetCount > 0) {
          dispatch({
            type: SET_DRAWER_OPEN,
            payload: false,
          });
        }
        await dispatch({
            type: DELETE_COLLECTION_SUCCESS,
            payload: res.data,
        });
        dispatch({
            type: CLOSE_COLLECTION_CONFIRM_DELETE_MODAL,
            payload: true,
        });
        dispatch({
            type: SET_SELECTED_DATASET_COLLECTIONS,
            payload: []
        });
        if(res.status === 200 && (!res.data.errorMessagesDueToDataSetLinkedSomewhereElse || res.data.errorMessagesDueToDataSetLinkedSomewhereElse.length === 0)){
            if(data.iterateResource) 
            data.iterateResource()
        }
    } catch (err) {
        console.log(err);
        actionsErrorHandling(err, DELETE_COLLECTION_ERROR, dispatch);
    }
}

export const confirmDeleteCollection = (data: IDeleteCollectionConfirmPayload) => {
    return {
        type: SET_COLLECTION_CONFIRM_DELETE_MODAL,
        payload: data,
    };
}

export const closeConfirmDeleteCollection = () => {
    return {
        type: CLOSE_COLLECTION_CONFIRM_DELETE_MODAL,
        payload: true,
    };
}


export const resetDelectCollectionErrorState = () => async (dispatch: Dispatch) => {
    dispatch({
        type: RESET_DELETE_COLLECTION_ERROR_STATE
    })
}

export const deleteModelCollection = (data: IDeleteModelCollectionPayload) => async (dispatch: Dispatch) : Promise<IModelCollectionDeleteResponse | undefined> => {
    dispatch({
        type: DELETE_MODEL_COLLECTION_REQUEST
    });
    try {
        const res = await DataService.deleteModelCollections(data);
        dispatch({
            type: CLOSE_COLLECTION_CONFIRM_DELETE_MODAL,
            payload: true,
        });
        dispatch({
            type: DELETE_MODEL_COLLECTION_SUCCESS,
            payload: res.data,
        });
        return res.data;
    } catch (err) {
        console.log(err);
        actionsErrorHandling(err, DELETE_MODEL_COLLECTION_ERROR, dispatch);
    }
}

export const resetDeleteModelCollectionErrorState = () => async (dispatch: Dispatch) => {
    dispatch({
        type: RESET_DELETE_MODEL_COLLECTION_ERROR_STATE
    })
}

export const clearAddCollectionSuccess = () => async (dispatch: Dispatch) => {
    dispatch({
        type: CLEAR_ADD_COLLECTION_SUCCESS,
    });
}

export const setSelectedDataSetCollection = (data: ICollection) => async (dispatch: Dispatch) => {
    dispatch({
        type: SET_SELECTED_DATASET_COLLECTION,
        payload: data
    });
}

export type SetSelectedDataSetCollectionsActionPayload = ICollection[];
export const setSelectedDataSetCollections = (data: SetSelectedDataSetCollectionsActionPayload) => async (dispatch: Dispatch) => {
    dispatch({
        type: SET_SELECTED_DATASET_COLLECTIONS,
        payload: data
    });
}

export type AddSelectedDataSetCollectionsActionPayload = ICollection;
export const addSelectedDataSetCollections = (data: AddSelectedDataSetCollectionsActionPayload) => async (dispatch: Dispatch) => {
    dispatch({
        type: ADD_SELECTED_DATASET_COLLECTIONS,
        payload: data
    });
}

export type SetSelectedModelCollectionsActionPayload = IModelCollection[];
export const setSelectedModelCollections = (data: SetSelectedModelCollectionsActionPayload) => async (dispatch: Dispatch) => {
    // debugger
    dispatch({
        type: SET_SELECTED_MODEL_COLLECTIONS,
        payload: data
    });
}

export type SetSelectedTestCollectionsActionPayload = ITestCollection[];
export const setSelectedTestCollections = (data: SetSelectedTestCollectionsActionPayload) => async (dispatch: Dispatch) => {
    // debugger
    dispatch({
        type: SET_SELECTED_TEST_COLLECTIONS,
        payload: data
    });
}


export type AddSelectedModelCollectionsActionPayload = IModelCollection;
export const addSelectedModelCollections = (data: AddSelectedModelCollectionsActionPayload) => async (dispatch: Dispatch) => {
    // debugger
    dispatch({
        type: ADD_SELECTED_MODEL_COLLECTIONS,
        payload: data
    });
}

export type AddSelectedTestCollectionsActionPayload = ITestCollection;
export const addSelectedTestCollections = (data: AddSelectedTestCollectionsActionPayload) => async (dispatch: Dispatch) => {
    // debugger
    dispatch({
        type: ADD_SELECTED_TEST_COLLECTIONS,
        payload: data
    });
}

export type SetSelectedModelCollectionsToCompareActionPayload = string[];
export const setSelectedModelCollectionsToCompare = (data: SetSelectedModelCollectionsToCompareActionPayload) => async (dispatch: Dispatch) => {
    // debugger
    dispatch({
        type: SET_SELECTED_MODEL_COLLECTIONS_TO_COMPARE,
        payload: data
    });
}

export type AddSelectedModelCollectionsToCompareActionPayload = string;
export const addSelectedModelCollectionsToCompare = (data: AddSelectedModelCollectionsToCompareActionPayload) => async (dispatch: Dispatch) => {
    // debugger
    dispatch({
        type: ADD_SELECTED_MODEL_COLLECTIONS_TO_COMPARE,
        payload: data
    });
}

export const setSelectedModelCollection = (data: string | null) => async (dispatch: Dispatch) => {
    dispatch({
        type: SET_SELECTED_MODEL_COLLECTION,
        payload: data
    });
}
export const setSelectedTestCollection =
  (data: ITestCollection | null) => async (dispatch: Dispatch) => {
    dispatch({
      type: SET_SELECTED_TEST_COLLECTION,
      payload: data,
    });
    };
  
export const setModelCollection = (data: IModelCollection[] | null) => async (dispatch: Dispatch) => {
    dispatch({
        type: SET_MODEL_COLLECTION,
        payload: data
    });
}

export const setLiveModelCollection = (data: IModelCollection[] | null) => async (dispatch: Dispatch) => {
    dispatch({
        type: SET_MODEL_COLLECTION,
        payload: data
    });
}

export const setEmptyModelCollection = (data: Boolean) => async (dispatch: Dispatch) => {
    dispatch({
        type: EMPTY_MODEL_COLLECTION,
        payload: data
    });
}

export const setModelCollectionTotalCount = (data: number | null) => async (dispatch: Dispatch) => {
    dispatch({
        type: SET_MODEL_COLLECTION_TOTAL_COUNT,
        payload: data
    });
}

export const setModelCollectionDefaultCheckPoint = (data:  IModelCollection| null) => async (dispatch: Dispatch) => {
    dispatch({
        type: SET_MODEL_COLLECTION_DEFAULT_CHECKPOINT,
        payload: data
    });
}

export const setDefaultDatasetCollection = (collectionDtls: IDefaultDataSetCollection | null) => {
   return {
        type: SET_DEFAULT_DATASET_COLLECTION,
        payload: collectionDtls
    };
}

export const updateDataSetCollection = (collection: ICollection) => {
    return {
      type: UPDATE_SELECTED_DATASET_COLLECTIONS,
      payload: collection,
    };
}

export const setIsDataSetCollectionResourcesFileDownloadLinkBeingFetched = (data: setIsDataSetCollectionResourcesFileDownloadLinkBeingFetchedActionPayload) => async (dispatch: Dispatch) => {
    dispatch<{type: string, payload: setIsDataSetCollectionResourcesFileDownloadLinkBeingFetchedActionPayload}>({
        type: SET_IS_DATA_SET_COLLECTION_RESOURCES_FILE_DOWNLOAD_LINK_BEING_FETCHED,
        payload: data
    });
}

export const addCollectionPageInit = () => async (dispatch: Dispatch) => {
    dispatch({
        type: ADD_COLLECTION_PAGE_INIT
    });
}


export interface ISetShouldFetchModelCollectionsActionPayload {
    shouldFetchModelCollections: boolean
}
export const setShouldFetchModelCollections = (data: ISetShouldFetchModelCollectionsActionPayload) => async (dispatch: Dispatch) => {
    dispatch({
        type: SET_SHOULD_FETCH_MODEL_COLLECTIONS,
        payload: data
    });
}

export const setShouldFetchTestCollections = (data: { shouldFetchTestCollections: boolean }) => async (dispatch: Dispatch) => {
    dispatch({
        type: SET_SHOULD_FETCH_TEST_COLLECTIONS,
        payload: data
    });
}

export const setTrainingDataDirectoriesSelectedStateForAddModelCollection = (data: ITrainingDataDirectoriesSelectedStateForAddModelCollection) => async (dispatch: Dispatch) => {
    dispatch({
        type: SET_TRAINING_DATA_DIRECTORIES_STATE_FOR_ADD_MODEL_COLLECTION,
        payload: data
    });
}

export const setTestModelVersionState =
  (data: ITestVersionSelectedStateForTestModelCollection) =>
  async (dispatch: Dispatch) => {
    dispatch({
      type: SET_MODEL_VERSION_STATE_FOR_TEST,
      payload: data,
    });
  };

export const setTestCollectionState = (data: ITestCollectionsResponse) => async (dispatch: Dispatch) => {
    dispatch({
        type: SET_TEST_COLLECTIONS_STATE,
        payload: data
    });
}

export const setTestCollectionIdSelectedByUserState = (data: string) => async (dispatch: Dispatch) => {
    dispatch({
        type: SET_TEST_COLLECTION_ID_SELECTED_STATE,
        payload: data
    })
}

export const updateModelCollectionDefaultCheckPoint = (data:any) => async (dispatch: Dispatch) => {
    try {
        const res = await ModelService.updateModelCollectionDefaultCheckPoint(data);
        console.log(res);
        // dispatch({})
    } catch (err) {
        console.log(err);
        actionsErrorHandling(err, ADD_COLLECTION_ERROR, dispatch);
    }
}

export const updateModelCollectionData = (modelCollectionToUpdate: IModelCollection) => async(dispatch: Dispatch) => {
    try {
        dispatch({
            type: UPDATE_MODEL_COLLECTION_DATA,
            payload: modelCollectionToUpdate
        })        
    } catch (error) {
        console.error(error);
    }
}

export const updateTestCollectionData = (testCollectionToUpdate: ITestCollection) => async(dispatch: Dispatch) => {
    try {
        dispatch({
            type: UPDATE_TEST_COLLECTION_DATA,
            payload: testCollectionToUpdate
        })        
    } catch (error) {
        console.error(error);
    }
}

/**
 * To update the resource labels & counts in the collection
 * @param datasetCollection ICollection object
 * @returns dispatch the action to update the collection
 */
export const setCollectionResourceLabelCount = (datasetCollection: ICollection) => async (dispatch: Dispatch) => {
    try {
        dispatch({
            type: SET_COLLECTIONS_RESOURCE_LABEL_COUNT,
            payload: datasetCollection
        })
    } catch (error) {
        console.error(error);
    }
}

export const updateReTestingModelCollectionDetails = (data : IReTestingDetailsUpdate) => async(dispatch: Dispatch) => {
    try{
        dispatch({
            type: ADD_RETEST_DETAILS_TO_MODEL_COLLECTION,
            payload: data
        })
    } catch (error) {
        console.error(error);
    }
    }
export const setDataSetCollectionDataBoost = (data: {modelCollectionId: string, dataBoost: number}) => async (dispatch: Dispatch) => {
    dispatch({
        type: SET_DATASET_COLLECTION_DATA_BOOST,
        payload: data
    });
}

export const triggerFetchLatestDataSetCollectionResourceLabelsIfCurrentlyShowingInScreenEvent = () => async (dispatch: Dispatch) => {
            dispatch({
                type: SET_SHOULD_FETCH_LATEST_DATA_SET_COLLECTION_RESOURCE_LABELS_IF_CUTTENTLY_SHOWING_IN_SCREEN,
                payload: true
            })
            
            setTimeout(() => {
                dispatch({
                    type: SET_SHOULD_FETCH_LATEST_DATA_SET_COLLECTION_RESOURCE_LABELS_IF_CUTTENTLY_SHOWING_IN_SCREEN,
                    payload: false
                })
            }, 500);
}