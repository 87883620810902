import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { APP_API } from './constants';

const BASE_URL = APP_API;

export class API {
    api: AxiosInstance;
    constructor() {
        let headers = {
            'Content-type': 'application/json; charset=utf-8'
        };
        this.api = axios.create({
            baseURL: BASE_URL,
            headers: headers,
            withCredentials: true
        });

        this.api.interceptors.response.use(async (response: AxiosResponse) => {
            if (response?.config?.url !== "productKeys/isProductKeyValidAndRegistered" && response?.config?.url !== "/productKeys/register"  && response?.data?.productKeyInvalidMessageCode) {
                window.location.href = `/?scenario=axiosInterceptor&errorMsg=${response.data.productKeyInvalidMessageCode}`;
            }
            return response;
        });
    }
}
