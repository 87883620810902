import { AxiosError } from "axios";
import { Dispatch } from "redux";

const actionsErrorHandling = (error: AxiosError, type: string, dispatch: Dispatch) => {
    let message = '';
    if (error.response) {
        // Request made and server responded
        console.log(error.response.data);
        message = error.response.data?.message || (typeof error.response.data==='string' && error.response.data ) || 'Something went wrong';
    } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
        message = error.request.message || 'Something went wrong';
    } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
        message = error.message || 'Something went wrong';
    }
    dispatch({
        type,
        payload: message
    });
}

const apiRequestCancelErrorHandling = (error: AxiosError, type: string, dispatch: Dispatch) => {
    let message = '';
    if (error.response) {
        // Request made is canceled
        console.log(error.constructor.name);
        message = error.constructor.name;
    }
    dispatch({
        type,
        payload: message
    });
}

export { actionsErrorHandling, apiRequestCancelErrorHandling };
