import {
  ResourceDataSetCollection,
} from "../common/constants/interfaces/data";

export function resourceContainsDataSetCollection(
  resource: {
    dataSetCollections?: {
      _id: string
    }[]
  },
  dataSetCollection: ResourceDataSetCollection
): boolean {
  if (resource.dataSetCollections) {
    for (const resourceDataSetCollection of resource?.dataSetCollections) {
      if (
        resourceDataSetCollection._id.toString() ===
        dataSetCollection._id.toString()
      ) {
        return true;
      }
    }
  }
  return false;
}
