import { makeStyles, Theme } from "@material-ui/core";

interface ISeparator {
    width?: string;
}

const useStyles = makeStyles<Theme, ISeparator>((theme) => ({
    root: {
        borderTop: `1px solid ${theme.palette.grey[100]}`,
        margin: theme.spacing(3, 0),
        width: ({ width }) => width || '100%',
    }
}));

export const Separator = (props: ISeparator) => {
    const classes = useStyles(props);
    return (
        <div className={classes.root}>
        </div>
    )
}