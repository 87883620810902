export enum Status{
    ACTIVE = 'active',
    EXPIRED = 'expired',
    REJECTED = 'rejected', 
    APPROVED = 'approved', 
    DELETED = 'deleted', 
    CONFIG = "configuration"
}

export enum ResourceActions {
    REJECT = "Reject",
    APPROVE = "Approve",
    ACTIVE = "Active"
}

export enum CounterAction{
    SOLD = 'Sold',
    DISCARDED = 'Discarded',
    REMOVED = 'Removed', 
    ADDED = "Added"
}
