
/**
 * use this function to read environment variable value
 */
export const getEnv = (key: string): string => {

    // @ts-ignore
    const environmentVariableValue = window?._env_?.[key]

    if (environmentVariableValue === "default") {
        return "";
    }

    return environmentVariableValue
}