import { UPDATE_COPILOT_RESOURCE_IMAGE_ANNOTATIONS_Action_Payload, UPDATE_COPILOT_RESOURCE_IMAGE_ANNOTATIONS_REQUEST_Action_Payload, UPDATE_COPILOT_RESOURCE_LABEL_Action_Payload } from './../../common/constants/interfaces/copilot';
import { AnyAction } from "redux";
import { ICoPilotDetailPageState, REMOVE_COPILOT_RESOURCE_FROM_UI_Action_Payload, SET_CO_PILOT_RESOURCE_DATA_Action_Payload } from "../../common/constants/interfaces/coPilotDetailPage";
import { ICoPilotResource } from "../../common/constants/interfaces/coPilotResource";
import { copyByValue } from '../../services/variableHelperService';
import { REMOVE_ALL_COPILOT_RESOURCES, COPILOT_NOTIFICATION_AND_RESOURCE_DELETED, SET_CO_PILOT_RESOURCES_ONLY, SET_CO_PILOT_RESOURCES_TOTAL_COUNT_ONLY, UPDATE_COPILOT_RESOURCE, UPDATE_SELECTED_TAB, UPDATE_COPILOT_RESOURCE_TRIMMED_TEXTS_IN_UI, UPDATE_COPILOT_RESOURCE_LABEL_IN_UI, DELETE_COPILOT_RESOURCE_TRIMMED_TEXT_IN_UI } from '../types/actions';
import { SET_CO_PILOT_DETAIL_PAGE_STATE, SET_CO_PILOT_RESOURCES, SET_SELECTED_CO_PILOT_RESOURCE, REMOVE_COPILOT_RESOURCE_FROM_UI, SET_DATA_FETCHING_LOADER_VALUE, SET_CO_PILOT_RESOURCE_DATA, UPDATE_COPILOT_RESOURCE_IMAGE_ANNOTATIONS_REQUEST, UPDATE_COPILOT_RESOURCE_IMAGE_ANNOTATIONS } from '../types/actions';
import { SOCKET_EVENT_CO_PILOT_RESOURCES_UPDATED_DATA } from '../../common/constants/interfaces/socketEvents';
import { Status, TrimmedText } from '../../common';
import { UPDATE_COPILOT_RESOURCE_TRIMMED_TEXTS_Action_Payload } from '../../common/constants/interfaces/copilot';
import { deleteCopilotResourceTrimmedTextsInUIActionPayload } from '../actions';

const initialState: ICoPilotDetailPageState = {
    tabSelected: "",
    coPilotResourcesState: {
      totalCount: undefined,
      coPilotResources: []
    },
    coPilotResourceIdSelectedForSeeingDetails: "",
    coPilotResourceSelectedForSeeingDetails: undefined,
    isFetchingData: false,
}

const coPilotDetailPage = (state: ICoPilotDetailPageState = initialState, action: AnyAction) => {
    const { type, payload } = action;
    switch (type) {
        case SET_CO_PILOT_RESOURCES_ONLY:
            return {
                ...state,
                coPilotResourcesState: {
                    ...state.coPilotResourcesState,
                    coPilotResources: [...payload]
                }
            }
        case SET_CO_PILOT_RESOURCES_TOTAL_COUNT_ONLY:
            return {
                ...state,
                coPilotResourcesState: {
                    ...state.coPilotResourcesState,
                    totalCount: payload
                }
            }
        case SET_CO_PILOT_DETAIL_PAGE_STATE:
            return {
                ...payload,
            };
        case SET_CO_PILOT_RESOURCES: 
            return{
                ...state,
                coPilotResourcesState:{
                    // coPilotResources: payload.offset > 1 ? [...state.coPilotResourcesState.coPilotResources, ...payload.resources] : payload.resources,
                    coPilotResources: [...payload.resources],
                    totalCount:payload.totalCount
                },
                isFetchingData: false,
                // coPilotResourceIdSelectedForSeeingDetails: (payload.resources && payload.resources.length && payload.resources[0]._id) || "",
                // coPilotResourceSelectedForSeeingDetails: getCoPilotResource(payload.resources, payload.resources && payload.resources.length && payload.resources[0]._id || "")
            }
        case SET_CO_PILOT_RESOURCE_DATA: 
            return handleSET_CO_PILOT_RESOURCE_DATA(state, payload)
        case UPDATE_COPILOT_RESOURCE_TRIMMED_TEXTS_IN_UI:
            return handleUpdateCopilotResourceTrimmedTextsInUI(state, payload)
        case DELETE_COPILOT_RESOURCE_TRIMMED_TEXT_IN_UI:
            return handleDeleteCopilotResourceTrimmedTextInUI(state, payload)
        case UPDATE_COPILOT_RESOURCE_LABEL_IN_UI:
            return handleUpdateCopilotResourceLabelInUI(state, payload)
        case SET_SELECTED_CO_PILOT_RESOURCE:
            return{
                ...state,
                coPilotResourceIdSelectedForSeeingDetails: payload,
                coPilotResourceSelectedForSeeingDetails: getCoPilotResource(state.coPilotResourcesState.coPilotResources, payload)
            }
        case REMOVE_COPILOT_RESOURCE_FROM_UI:
            return handleREMOVE_COPILOT_RESOURCE_FROM_UI(state, payload)
        case SET_DATA_FETCHING_LOADER_VALUE:
            return {
              ...state,
              isFetchingData: payload
            };
        case REMOVE_ALL_COPILOT_RESOURCES:
            return {
                ...state,
                coPilotResourcesState: {
                    totalCount: undefined,
                    coPilotResources: []
                  },
                  coPilotResourceIdSelectedForSeeingDetails: "",
                  coPilotResourceSelectedForSeeingDetails: undefined,
              };
        case UPDATE_COPILOT_RESOURCE_IMAGE_ANNOTATIONS_REQUEST:
            return handleUPDATE_COPILOT_RESOURCE_IMAGE_ANNOTATIONS_REQUEST(state, payload)
        case UPDATE_COPILOT_RESOURCE_IMAGE_ANNOTATIONS:
            return handleUPDATE_COPILOT_RESOURCE_IMAGE_ANNOTATIONS(state, payload)   
        case UPDATE_SELECTED_TAB:
            return {
                ...state,
                tabSelected: payload
                };
        case UPDATE_COPILOT_RESOURCE:
            return handleUPDATE_COPILOT_RESOURCE(state, payload)   
        case COPILOT_NOTIFICATION_AND_RESOURCE_DELETED:
            return handleREMOVE_COPILOT_RESOURCE(state, payload)   
        default:
            return state
    }
}

const getCoPilotResource = (resources: ICoPilotResource[], id: string) => {
    return resources.find(resource => resource._id === id)
}

export default coPilotDetailPage;

function handleREMOVE_COPILOT_RESOURCE_FROM_UI(state: ICoPilotDetailPageState, payload: REMOVE_COPILOT_RESOURCE_FROM_UI_Action_Payload): ICoPilotDetailPageState {
    const resources = copyByValue(state.coPilotResourcesState.coPilotResources);
    let totalCount = copyByValue(state.coPilotResourcesState.totalCount) || 0;
    for (let resourceIndex = 0; resourceIndex < resources.length; resourceIndex++) {
        const resource = resources[resourceIndex];
        if (resource._id.toString() === payload.resourceId) {
            resources.splice(resourceIndex, 1);
            totalCount = totalCount - 1;
            if (totalCount < 0) {
                totalCount = 0;
            }
            break;
        }
    }
    return {
        ...state,
        coPilotResourcesState: {
            coPilotResources: [...resources],
            totalCount: totalCount
        }
    }
}

function handleSET_CO_PILOT_RESOURCE_DATA(state: ICoPilotDetailPageState, payload: SET_CO_PILOT_RESOURCE_DATA_Action_Payload): ICoPilotDetailPageState {
    const resources = state.coPilotResourcesState.coPilotResources;
    for (let index = 0; index < resources.length; index++) {
      let resource = resources[index];
      if (resource._id.toString() === payload.coPilotResource._id.toString()) {
        resource = payload.coPilotResource;
        resources[index] = resource;
        break;
      }
    }

    if (payload.shouldNotFormNewRootState) {
        state.coPilotResourcesState.coPilotResources = [...resources];
        return state;
    }

    return {
        ...state,
        coPilotResourcesState: {
            ...state.coPilotResourcesState,
            coPilotResources: [...resources],
        }
    }
}

function handleUPDATE_COPILOT_RESOURCE_IMAGE_ANNOTATIONS_REQUEST(state: ICoPilotDetailPageState, payload: UPDATE_COPILOT_RESOURCE_IMAGE_ANNOTATIONS_REQUEST_Action_Payload): ICoPilotDetailPageState {

    const updatedResources = state.coPilotResourcesState.coPilotResources;

    for (const resource of updatedResources) {
        if (resource._id.toString() === payload.resourceId.toString()) {
            resource.areImageKeyPointsBeingUpdatedInUI = true;
            break;
        }
    }

    if (payload.shouldNotFormNewRootState) {
        state.coPilotResourcesState.coPilotResources = [...updatedResources];
        return state;
    }

    return {
        ...state,
        coPilotResourcesState: {
            ...state.coPilotResourcesState,
            coPilotResources: [...updatedResources],
        }
    }

}

function handleUPDATE_COPILOT_RESOURCE_IMAGE_ANNOTATIONS(state: ICoPilotDetailPageState, payload: UPDATE_COPILOT_RESOURCE_IMAGE_ANNOTATIONS_Action_Payload): ICoPilotDetailPageState {

    const updatedResources = state.coPilotResourcesState.coPilotResources;

    for (const resource of updatedResources) {
        if (resource._id.toString() === payload.resourceId.toString()) {
            resource.imageAnnotations = payload.imageAnnotations;
            if (!payload.shouldNotFormNewRootState) {
                resource.imageAnnotations = copyByValue(payload.imageAnnotations);
            }
            resource.areImageKeyPointsBeingUpdatedInUI = false;
            break;
        }
    }

    if (payload.shouldNotFormNewRootState) {
        state.coPilotResourcesState.coPilotResources = [...updatedResources];
        return state;
    }    

    return {
        ...state,
        coPilotResourcesState: {
            ...state.coPilotResourcesState,
            coPilotResources: [...updatedResources],
        }
    }

}

function handleUpdateCopilotResourceTrimmedTextsInUI(state: ICoPilotDetailPageState, payload: UPDATE_COPILOT_RESOURCE_TRIMMED_TEXTS_Action_Payload): ICoPilotDetailPageState {

    const updatedResources = state.coPilotResourcesState.coPilotResources;

    for (const resource of updatedResources) {
        if (resource._id.toString() === payload._id.toString()) {
                resource.trimmedTexts = [...payload.trimmedTexts];
            break;
        }
    }

    const isCopilotResourceSelectedInSideDrawer: boolean = (()=>{
        if (
            state.coPilotResourceIdSelectedForSeeingDetails === payload._id
        ) {
            return true;
        }

        if (
            state.coPilotResourceSelectedForSeeingDetails?._id === payload._id
        ) {
            return true;
        }

        return false;
    })()    

    const updatedRootState: ICoPilotDetailPageState = {
        ...state,
        coPilotResourcesState: {
            ...state.coPilotResourcesState,
            coPilotResources: [...updatedResources],
        }
    };

    if (isCopilotResourceSelectedInSideDrawer) {
        if (updatedRootState.coPilotResourceSelectedForSeeingDetails) {
            updatedRootState.coPilotResourceSelectedForSeeingDetails={
                ...updatedRootState.coPilotResourceSelectedForSeeingDetails,
                trimmedTexts: [...payload.trimmedTexts]
            }
        }
    }

    return {
        // ...JSON.parse(JSON.stringify(updatedRootState))
        ...updatedRootState
    }

}
function handleDeleteCopilotResourceTrimmedTextInUI(state: ICoPilotDetailPageState, payload: deleteCopilotResourceTrimmedTextsInUIActionPayload): ICoPilotDetailPageState {

    const updatedResources = state.coPilotResourcesState.coPilotResources;

    let updatedResourceTrimmedTexts: TrimmedText[] = [];

    for (let resourceIndex = 0; resourceIndex < updatedResources.length; resourceIndex++) {
        const resource = updatedResources[resourceIndex];
        if (resource._id.toString() === payload.resourceId.toString()) {
            if (resource.trimmedTexts) {
                for (let trimTextIndex = 0; trimTextIndex < resource.trimmedTexts.length; trimTextIndex++) {
                    const trimTextObject = resource.trimmedTexts[trimTextIndex];
                    if (trimTextObject._id === payload.idOfTrimTextToDelete) {
                        resource.trimmedTexts.splice(trimTextIndex, 1);
                        resource.trimmedTexts = [...resource.trimmedTexts];
                        updatedResourceTrimmedTexts = resource.trimmedTexts;
                        break;
                    }
                }
            }
            break;
        }
    }

    const isCopilotResourceSelectedInSideDrawer: boolean = (()=>{
        if (
            state.coPilotResourceIdSelectedForSeeingDetails === payload.resourceId
        ) {
            return true;
        }

        if (
            state.coPilotResourceSelectedForSeeingDetails?._id === payload.resourceId
        ) {
            return true;
        }

        return false;
    })()    

    const updatedRootState: ICoPilotDetailPageState = {
        ...state,
        coPilotResourcesState: {
            ...state.coPilotResourcesState,
            coPilotResources: [...updatedResources],
        }
    };

    if (isCopilotResourceSelectedInSideDrawer) {
        if (updatedRootState.coPilotResourceSelectedForSeeingDetails) {
            updatedRootState.coPilotResourceSelectedForSeeingDetails={
                ...updatedRootState.coPilotResourceSelectedForSeeingDetails,
                trimmedTexts: [...updatedResourceTrimmedTexts]
            }
        }
    }

    return {
        // ...JSON.parse(JSON.stringify(updatedRootState))
        ...updatedRootState
    }

}

function handleUpdateCopilotResourceLabelInUI(state: ICoPilotDetailPageState, payload: UPDATE_COPILOT_RESOURCE_LABEL_Action_Payload): ICoPilotDetailPageState {

    const updatedResources = state.coPilotResourcesState.coPilotResources;

    for (const resource of updatedResources) {
        if (resource._id.toString() === payload._id.toString()) {
                resource.label = payload.label;
            break;
        }
    }

    const isCopilotResourceSelectedInSideDrawer: boolean = (()=>{
        if (
            state.coPilotResourceIdSelectedForSeeingDetails === payload._id
        ) {
            return true;
        }

        if (
            state.coPilotResourceSelectedForSeeingDetails?._id === payload._id
        ) {
            return true;
        }

        return false;
    })()

    const updatedRootState: ICoPilotDetailPageState = {
        ...state,
        coPilotResourcesState: {
            ...state.coPilotResourcesState,
            coPilotResources: [...updatedResources],
        }
    };

    if (isCopilotResourceSelectedInSideDrawer) {
        if (updatedRootState.coPilotResourceSelectedForSeeingDetails) {
            updatedRootState.coPilotResourceSelectedForSeeingDetails={
                ...updatedRootState.coPilotResourceSelectedForSeeingDetails,
                label: payload.label
            }
        }
    }

    return {
        ...updatedRootState
    }

}

function handleUPDATE_COPILOT_RESOURCE(state: ICoPilotDetailPageState, payload: SOCKET_EVENT_CO_PILOT_RESOURCES_UPDATED_DATA): ICoPilotDetailPageState {
    let selectedTab = state.tabSelected;
    // const currentUrlParams = new URLSearchParams(window.location.search) ;
    // const coPilotId = currentUrlParams.get("coPilot")|| "";
    // const project = currentUrlParams.get("project") || "";
    const resourceList = [...state.coPilotResourcesState.coPilotResources]
    const index = resourceList.findIndex(resource => resource._id === payload.resourceIds[0]);
    let totalCount = state.coPilotResourcesState.totalCount;

    // if(payload.coPilot._id !== coPilotId || payload.coPilot.project !== project || index === -1 ){
    if(index === -1 || (selectedTab === Status.ACTIVE && !payload.status )){ /** On Updating Active Resource, updating the store is not required. */
        return state  /** return old state itself*/
    }

    if(index !== -1){
        if(selectedTab !== payload.status && payload.status){
            resourceList.splice(index,1);
            totalCount = (totalCount || 1) -1;
        }
        else{
            resourceList[index] = {
                ...resourceList[index],
                label: payload.label || resourceList[index].label,
                confidenceScore: payload.confidenceScore || resourceList[index].confidenceScore,
                remarks: payload.remarks || resourceList[index].remarks,
                resourceTimerEndAt: payload.resourceTimerEndAt || resourceList[index].resourceTimerEndAt,
                userTimeLeftInSecondsToTakeAction: payload.userTimeLeftInSecondsToTakeAction || resourceList[index].userTimeLeftInSecondsToTakeAction,
            };
        }
    }
    const isResourceSelectedForSeeingDetailsUpdated = (totalCount === state.coPilotResourcesState.totalCount) && (state.coPilotResourceIdSelectedForSeeingDetails === payload.resourceIds[0])
    const isResourceSelectedForSeeingDetailsRemoved = (totalCount !== state.coPilotResourcesState.totalCount) && (state.coPilotResourceIdSelectedForSeeingDetails === payload.resourceIds[0])
    return {
        ...state,
        coPilotResourcesState: {
            ...state.coPilotResourcesState,
            coPilotResources: [...resourceList],
            totalCount
        },
        coPilotResourceIdSelectedForSeeingDetails: isResourceSelectedForSeeingDetailsRemoved ? "" : isResourceSelectedForSeeingDetailsUpdated ? resourceList[index]._id : state.coPilotResourceIdSelectedForSeeingDetails,
        coPilotResourceSelectedForSeeingDetails: isResourceSelectedForSeeingDetailsRemoved ? undefined : isResourceSelectedForSeeingDetailsUpdated ? resourceList[index] :state.coPilotResourceSelectedForSeeingDetails
    }
}


function handleREMOVE_COPILOT_RESOURCE(state: ICoPilotDetailPageState, resourceId: string): ICoPilotDetailPageState {
    const resourceList = [...state.coPilotResourcesState.coPilotResources]
    const index = resourceList.findIndex(resource => resource._id === resourceId)
    let totalCount = state.coPilotResourcesState.totalCount;

    if(index !== -1){
        resourceList.splice(index,1);
        totalCount = (totalCount || 1) -1;
    }
    const isResourceSelectedForSeeingDetailsRemoved = (totalCount !== state.coPilotResourcesState.totalCount) && (state.coPilotResourceIdSelectedForSeeingDetails === resourceId)
    return {
        ...state,
        coPilotResourcesState: {
            ...state.coPilotResourcesState,
            coPilotResources: [...resourceList],
            totalCount: (state.coPilotResourcesState.totalCount || 0) - 1
        },
        coPilotResourceIdSelectedForSeeingDetails: isResourceSelectedForSeeingDetailsRemoved ? "" : state.coPilotResourceIdSelectedForSeeingDetails,
        coPilotResourceSelectedForSeeingDetails: isResourceSelectedForSeeingDetailsRemoved ? undefined : state.coPilotResourceSelectedForSeeingDetails
    }
}