import { AnyAction } from "redux";

import {
    ITagState,
} from "../../common";

import {
    GET_TAGS_CLEAR_ERROR,
    GET_TAGS_ERROR,
    GET_TAGS_REQUEST,
    GET_TAGS_SUCCESS,
} from '../types';

const initialState: ITagState = {
    tags: [],
    isFetching: false,
    isFetchSuccess: false,
    isFetchError: false,
    fetchErrorMessage: '',
}

const tag = (state: ITagState = initialState, action: AnyAction): ITagState => {
    const { type, payload } = action;
    switch (type) {
        case GET_TAGS_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case GET_TAGS_SUCCESS:
            return {
                ...state,
                tags: payload.tags,
                isFetching: false,
            };
        case GET_TAGS_ERROR:
            return {
                ...state,
                fetchErrorMessage: payload,
                isFetchError: true,
                isFetching: false,
            };
        case GET_TAGS_CLEAR_ERROR:
            return {
                ...state,
                fetchErrorMessage: '',
                isFetchError: false,
            };
        default:
            return state
    }
}

export default tag;
