import { Dispatch } from "redux";

import {
    GET_TAGS_ERROR,
    GET_TAGS_REQUEST,
    GET_TAGS_SUCCESS,
} from "../types";

import { DataService } from "../../services";
import { actionsErrorHandling } from "../../common";

export const getTags = (modelId: string) => async (dispatch: Dispatch) => {
    dispatch({
        type: GET_TAGS_REQUEST,
        payload: null,
    });
    try {
        const res = await DataService.getTags(modelId);
        dispatch({
            type: GET_TAGS_SUCCESS,
            payload: res.data,
        });
    } catch (err) {
        console.log(err);
        actionsErrorHandling(err, GET_TAGS_ERROR, dispatch);
    }
}
