import { AnyAction } from "redux";

import { ICollection, ICollectionState, IModelCollection, ITestCollection, ITestVersionSelectedStateForTestModelCollection, ITrainingDataDirectoriesSelectedStateForAddModelCollection, setIsDataSetCollectionResourcesFileDownloadLinkBeingFetchedActionPayload } from '../../common/constants/interfaces/collections';
import { AddSelectedDataSetCollectionsActionPayload, AddSelectedModelCollectionsActionPayload, AddSelectedModelCollectionsToCompareActionPayload, AddSelectedTestCollectionsActionPayload, SetSelectedDataSetCollectionsActionPayload, SetSelectedModelCollectionsActionPayload, SetSelectedModelCollectionsToCompareActionPayload, SetSelectedTestCollectionsActionPayload } from "../actions";

import {
    CLEAR_ADD_COLLECTION_SUCCESS,
    ADD_COLLECTION_REQUEST,
    ADD_COLLECTION_SUCCESS,
    GET_COLLECTION_CLEAR_ERROR,
    GET_COLLECTION_ERROR,
    GET_COLLECTION_REQUEST,
    GET_COLLECTION_SUCCESS,
    SET_SELECTED_DATASET_COLLECTION,
    DELETE_COLLECTION_SUCCESS,
    DELETE_COLLECTION_REQUEST,
    ADD_COLLECTION_ERROR,
    DELETE_MODEL_COLLECTION_SUCCESS,
    DELETE_MODEL_COLLECTION_REQUEST,
    ADD_COLLECTION_PAGE_INIT,
    SET_SELECTED_MODEL_COLLECTION,
    SET_SHOULD_FETCH_MODEL_COLLECTIONS,
    SET_SELECTED_DATASET_COLLECTIONS,
    ADD_SELECTED_DATASET_COLLECTIONS,
    SET_TRAINING_DATA_DIRECTORIES_STATE_FOR_ADD_MODEL_COLLECTION,
    SET_IS_DATA_SET_COLLECTION_RESOURCES_FILE_DOWNLOAD_LINK_BEING_FETCHED,
    SET_MODEL_VERSION_STATE_FOR_TEST,
    SET_DEFAULT_DATASET_COLLECTION,
    SET_MODEL_COLLECTION,
    SET_MODEL_COLLECTION_TOTAL_COUNT,
    SET_MODEL_COLLECTION_DEFAULT_CHECKPOINT,
    SET_SELECTED_TEST_COLLECTION,
    SET_SHOULD_FETCH_TEST_COLLECTIONS,
    SET_TEST_COLLECTIONS_STATE,
    SET_TEST_COLLECTION_ID_SELECTED_STATE,
    RESET_DELETE_COLLECTION_ERROR_STATE,
    RESET_DELETE_MODEL_COLLECTION_ERROR_STATE,
    EMPTY_MODEL_COLLECTION,
    SET_SELECTED_MODEL_COLLECTIONS,
    ADD_SELECTED_MODEL_COLLECTIONS,
    SET_SELECTED_MODEL_COLLECTIONS_TO_COMPARE,
    ADD_SELECTED_MODEL_COLLECTIONS_TO_COMPARE,
    UPDATE_MODEL_COLLECTION_DATA,
    UPDATE_TEST_COLLECTION_DATA,
    ADD_RETEST_DETAILS_TO_MODEL_COLLECTION,
    SET_DATASET_COLLECTION_DATA_BOOST,
    UPDATE_SELECTED_DATASET_COLLECTIONS,
    SET_COLLECTIONS_RESOURCE_LABEL_COUNT,
    SET_COLLECTION_CONFIRM_DELETE_MODAL,
    CLOSE_COLLECTION_CONFIRM_DELETE_MODAL,
    ADD_SELECTED_TEST_COLLECTIONS,
    SET_SELECTED_TEST_COLLECTIONS,
    SET_SHOULD_FETCH_LATEST_DATA_SET_COLLECTION_RESOURCE_LABELS_IF_CUTTENTLY_SHOWING_IN_SCREEN,
} from '../types';

export const trainingDataDirectoriesSelectedStateForAddModelCollectionInitialValue = {
    isDirty: false,
    isTouched: false,
    value: []
};
export const testModelVersionInitialValue =
  {
    isDirty: false,
    isTouched: false,
    value: [],
  };
const initialState: ICollectionState = {
    collections: {
        collections: [],
        totalCount: 0,
    },
    shouldFetchLatestDataSetCollectionResourceLabelsIfCurrentlyShowingInScreen: false,
    isFetching: false,
    isFetchSuccess: false,
    isFetchError: false,
    fetchErrorMessage: '',
    isAdding: false,
    isAddError: false,
    isAddSuccess: false,
    addErrorMessage: '',
    createErrorMessage: '',
    isUpdating: false,
    isUpdateError: false,
    isUpdateSuccess: false,
    updateErrorMessage: '',
    ConfirmDeleteModal: {
        showConfirmDeleteModal: false,
        collectionToDelete: null,
        multiDelete: false,
        collectionType: undefined,
        selectedCollectionIds: [],
        deletedCollectionFromPopup: false
    },
    errorMessagesDueToDataSetLinkedSomewhereElse: [],
    modelCollection: {
        shouldFetchModelCollections: false,
        modelCollectionSelected: "",
        modelCollectionsSelected : [],
        modelCollectionsSelectedToCompare: [],
        trainingDataDirectoriesSelectedStateForAddModelCollection: trainingDataDirectoriesSelectedStateForAddModelCollectionInitialValue,
        modelCollections : null,
        totalModelCollectionsTableCount: 0,
        biggestTestModelVersion: testModelVersionInitialValue,
        errorMessagesDueToModelCollectionLinkedSomewhereElse: [],
        deletedModelCollectionCount : 0
        // modelCollections: {
        //     totalCount: 0,
        //     collections: []
        // },
        // modelCollectionIdSelectedByUser: ""
    },
    testCollection: {
        shouldFetchTestCollections: false,
        testCollectionsSelected: [],
        testCollectionSelected: null,
        testCollectionIdSelectedByUser: null,
        testCollections: null
    },
    dataSetCollectionsSelected: [],
    defaultDataSetCollection: null
}

const collection = (state: ICollectionState = initialState, action: AnyAction): ICollectionState => {
    const { type, payload } = action;
    switch (type) {
        case GET_COLLECTION_REQUEST:
            return {
                ...state,
                isFetching: true,
                isAddSuccess: false,
                isAdding: false
            };
        case GET_COLLECTION_SUCCESS:
            return {
                ...state,
                collections: payload,
                isFetching: false,
            };
        case SET_COLLECTIONS_RESOURCE_LABEL_COUNT:

            const isCollectionToUpdateDataInIsTheOneSelectedRightNow: boolean = (()=>{
                if (payload?._id.toString() === state.selected?._id.toString()) {
                    return true;
                }
                return false;
            })();

            return {
                ...state,
                collections: {
                    ...state.collections,
                    collections: [...state.collections.collections.map(collection => {
                        if (collection._id === payload._id) {
                            return payload
                        }
                        return collection
                    })]
                },
                ...(
                    isCollectionToUpdateDataInIsTheOneSelectedRightNow 
                    ? {selected: {...payload}}
                    : {}
                )
            }
        case GET_COLLECTION_ERROR:
            return {
                ...state,
                fetchErrorMessage: payload,
                isFetchError: true,
                isFetching: false,
            };
        case GET_COLLECTION_CLEAR_ERROR:
            return {
                ...state,
                fetchErrorMessage: '',
                isFetchError: false,
            };
        case ADD_COLLECTION_REQUEST:
            return {
                ...state,
                isAdding: true,
                addErrorMessage: ''
            }
        case ADD_COLLECTION_SUCCESS:
            return {
                ...state,
                isAdding: false,
                isAddSuccess: true,
                selected: payload
            }
        case ADD_COLLECTION_ERROR:
            return {
                ...state,
                addErrorMessage: payload,
                isAdding: false,
                isAddError:true,
            };            
        case DELETE_COLLECTION_REQUEST:
            return {
                ...state,
                isDeleting: true,
                isDeleteSuccess: false,
                errorMessagesDueToDataSetLinkedSomewhereElse: [],
                collections: {
                    totalCount: state.collections.totalCount,
                    collections: state.collections.collections.map(collectionData => {
                        if (payload.collections.some((collectionId: string) => collectionId === collectionData._id)) {
                            return {...collectionData, isDeleteHappening: true}
                        } else {
                            // return {...collectionData, isDeleteHappening: false}
                            return {...collectionData}
                        }
                    })
                },
                selected: {...state.selected, isDeleteHappening: payload.collections.some((collectionId: string) => collectionId === state.selected?._id)} as unknown as ICollection
            }
        case DELETE_COLLECTION_SUCCESS:
            return {
              ...state,
              isDeleting: false,
              isDeleteSuccess: payload.deletedDataSetCount > 0,
              selected: 
              payload.deletedDataSetCount > 0 ? null : 
              {...state.selected, isDeleteHappening: false} as unknown as ICollection,
              errorMessagesDueToDataSetLinkedSomewhereElse:
                payload.errorMessagesDueToDataSetLinkedSomewhereElse || [],
              collections: {
                totalCount: state.collections.totalCount,
                collections: state.collections.collections.map(
                  (collectionData) => {
                    return { ...collectionData, isDeleteHappening: false };
                  }
                ),
              },
            };
        case UPDATE_SELECTED_DATASET_COLLECTIONS:
            const updatedCollection: ICollection = payload;
            return {
                ...state,
                collections: {
                    ...state.collections,
                    collections: [...state.collections.collections.map(collectionData => {
                        if (collectionData._id === updatedCollection._id) {
                            return updatedCollection
                        } 
                        return collectionData
                    })]
                },
                selected: {...updatedCollection}
            }
        case RESET_DELETE_COLLECTION_ERROR_STATE:
            return {
                ...state,
                errorMessagesDueToDataSetLinkedSomewhereElse: []
            }
            case DELETE_MODEL_COLLECTION_REQUEST:
                return {
                    ...state,
                    isDeleting: true,
                    isDeleteSuccess: false,
                    modelCollection: {
                        ...state.modelCollection,
                        errorMessagesDueToModelCollectionLinkedSomewhereElse: [],
                        deletedModelCollectionCount : 0
                    }
                }
            case DELETE_MODEL_COLLECTION_SUCCESS:
                return {
                    ...state,
                    isDeleting: false,
                    isDeleteSuccess: true,
                    selected: null,
                    modelCollection: {
                        ...state.modelCollection,
                        errorMessagesDueToModelCollectionLinkedSomewhereElse: payload.errorMessagesDueToModelCollectionLinkedSomewhereElse || [],
                        deletedModelCollectionCount : payload.deletedModelCollectionCount || 0
                    }
                    
                }
            case RESET_DELETE_MODEL_COLLECTION_ERROR_STATE:
                return {
                    ...state,
                    modelCollection: {
                        ...state.modelCollection,
                        errorMessagesDueToModelCollectionLinkedSomewhereElse: [],
                        deletedModelCollectionCount : 0
                    }
                }
        case CLEAR_ADD_COLLECTION_SUCCESS:
            return {
                ...state,
                isAddSuccess: false,
                selected: null,
            }
        case SET_SELECTED_DATASET_COLLECTION:
            return {
                ...state,
                selected: payload
            }
        case SET_SELECTED_MODEL_COLLECTION:
            return {
                ...state,
                modelCollection: {
                    ...state.modelCollection,
                    modelCollectionSelected: payload
                }
                    // modelCollections:{
                    //     ...state.modelCollection.modelCollections,
                    //     collections: payload
                    // }
                    // modelCollectionSelected: payload
            }
        case SET_MODEL_COLLECTION:
            // console.log("pyaload :::: ", payload);
            return {
                ...state,
                modelCollection: {
                    ...state.modelCollection,
                    modelCollections: [...(state.modelCollection.modelCollections || []), ...payload]
                }
            }
        case EMPTY_MODEL_COLLECTION:
            return {
                ...state,
                modelCollection: {
                    ...state.modelCollection,
                    modelCollections: []
                }
            }
        case UPDATE_MODEL_COLLECTION_DATA:
            return handleUpdateModelCollectionData(state, payload)
        case UPDATE_TEST_COLLECTION_DATA:
            return handleUpdateTestCollectionData(state, payload)
        case SET_MODEL_COLLECTION_TOTAL_COUNT:
            return {
                ...state,
                modelCollection: {
                    ...state.modelCollection,
                    totalModelCollectionsTableCount: payload
                }
            }
        case SET_MODEL_COLLECTION_DEFAULT_CHECKPOINT:
            return {
                ...state,
                modelCollection: {
                    ...state.modelCollection,
                    modelCollections : setModelCollectionDefaultCheckPoint(state.modelCollection.modelCollections || [], payload)
                }
            }
        case SET_SELECTED_TEST_COLLECTION:
            return {
                ...state,
                testCollection: {
                    ...state.testCollection,
                    testCollectionSelected: payload
                }
            }
        case SET_SHOULD_FETCH_MODEL_COLLECTIONS:
            return {
                ...state,
                modelCollection: {
                    ...state.modelCollection,
                    shouldFetchModelCollections: payload.shouldFetchModelCollections
                }
            }
        case SET_SHOULD_FETCH_TEST_COLLECTIONS:
            return {
                ...state,
                testCollection: {
                    ...state.testCollection,
                    shouldFetchTestCollections: payload.shouldFetchTestCollections
                }
            }
        case ADD_COLLECTION_PAGE_INIT:
            return {
                ...state,
                isAdding: false,
                addErrorMessage: ''
            }
        case SET_SELECTED_DATASET_COLLECTIONS:
            const actionPayload: SetSelectedDataSetCollectionsActionPayload = payload;
            return {
                ...state,
                dataSetCollectionsSelected: actionPayload
            }
        case ADD_SELECTED_DATASET_COLLECTIONS:
             const actionPayload2: AddSelectedDataSetCollectionsActionPayload = payload;
             for (let index = 0; index < state.dataSetCollectionsSelected.length; index++) {
                 const collection = state.dataSetCollectionsSelected[index];
                 if (collection._id === actionPayload2._id) {
                     /** if collection already added, then not adding it again */
                     return {
                         ...state
                     };
                 }
             }
            return {
                ...state,
                dataSetCollectionsSelected: [...state.dataSetCollectionsSelected, actionPayload2]
            }
        case SET_SELECTED_MODEL_COLLECTIONS:
            const modelActionPayload: SetSelectedModelCollectionsActionPayload = payload;
            // debugger
            return {
                ...state,
                modelCollection: {
                    ...state.modelCollection,
                    modelCollectionsSelected: modelActionPayload
            }
        }
        case SET_SELECTED_TEST_COLLECTIONS:
            const testActionPayload1: SetSelectedTestCollectionsActionPayload = payload;
            // debugger
            return {
                ...state,
                testCollection: {
                    ...state.testCollection,
                    testCollectionsSelected: testActionPayload1
            }
        }
        case ADD_SELECTED_MODEL_COLLECTIONS:
            // debugger
            const modelActionPayload2: AddSelectedModelCollectionsActionPayload = payload;
            for (let index = 0; index < state.modelCollection.modelCollectionsSelected.length; index++) {
                const collection = state.modelCollection.modelCollectionsSelected[index];
                if (collection._id === modelActionPayload2._id) {
                    /** if collection already added, then not adding it again */
                    return {
                        ...state
                    };
                }
            }
        return {
            ...state,
            modelCollection: {
                ...state.modelCollection,
                modelCollectionsSelected: [...state.modelCollection.modelCollectionsSelected, modelActionPayload2]
            }
        }
        case ADD_SELECTED_TEST_COLLECTIONS:
            // debugger
            const testActionPayload: AddSelectedTestCollectionsActionPayload = payload;
            for (let index = 0; index < state.testCollection.testCollectionsSelected?.length; index++) {
                const collection = state.testCollection.testCollectionsSelected[index];
                if (collection._id === testActionPayload._id) {
                    /** if collection already added, then not adding it again */
                    return {
                        ...state
                    };
                }
            }
        return {
            ...state,
            testCollection: {
                ...state.testCollection,
                testCollectionsSelected: [...state.testCollection.testCollectionsSelected, testActionPayload]
            }
        }
            case SET_SELECTED_MODEL_COLLECTIONS_TO_COMPARE:
                const modelsToCompareActionPayload: SetSelectedModelCollectionsToCompareActionPayload = payload;
                // debugger
                return {
                    ...state,
                    modelCollection: {
                        ...state.modelCollection,
                        modelCollectionsSelectedToCompare: modelsToCompareActionPayload
                }
            }
            case ADD_SELECTED_MODEL_COLLECTIONS_TO_COMPARE:
                // debugger
                const modelsToCompareActionPayload2: AddSelectedModelCollectionsToCompareActionPayload = payload;
                for (let index = 0; index < state.modelCollection.modelCollectionsSelectedToCompare.length; index++) {
                    const collection = state.modelCollection.modelCollectionsSelectedToCompare[index];
                    if (collection === modelsToCompareActionPayload2) {
                        /** if collection already added, then not adding it again */
                        return {
                            ...state
                        };
                    }
                }
            return {
                ...state,
                modelCollection: {
                    ...state.modelCollection,
                    modelCollectionsSelectedToCompare: [...state.modelCollection.modelCollectionsSelectedToCompare, modelsToCompareActionPayload2]
                }
            }
            case SET_TRAINING_DATA_DIRECTORIES_STATE_FOR_ADD_MODEL_COLLECTION:
                const actionPayload3: ITrainingDataDirectoriesSelectedStateForAddModelCollection = payload;
                return {
                    ...state,
                    modelCollection: {
                        ...state.modelCollection,
                        trainingDataDirectoriesSelectedStateForAddModelCollection: actionPayload3
                    }
                } 
            case SET_MODEL_VERSION_STATE_FOR_TEST:
                const modelVersionPayload: ITestVersionSelectedStateForTestModelCollection = payload;
                return {
                  ...state,
                  modelCollection: {
                    ...state.modelCollection,
                    biggestTestModelVersion: modelVersionPayload,
                  },
                };
            case SET_TEST_COLLECTIONS_STATE:
                return {
                    ...state,
                    testCollection: {
                        ...state.testCollection,
                        testCollections: payload
                    }
                }
            case SET_TEST_COLLECTION_ID_SELECTED_STATE: 
                return {
                    ...state,
                    testCollection: {
                        ...state.testCollection,
                        testCollectionIdSelectedByUser: payload
                    }
                }
            case SET_IS_DATA_SET_COLLECTION_RESOURCES_FILE_DOWNLOAD_LINK_BEING_FETCHED:
                return handleSET_IS_DATA_SET_COLLECTION_RESOURCES_FILE_DOWNLOAD_LINK_BEING_FETCHED(state, payload)
            case SET_DEFAULT_DATASET_COLLECTION:
                return {
                    ...state,
                    defaultDataSetCollection: payload
                }
            case ADD_RETEST_DETAILS_TO_MODEL_COLLECTION:
                return {
                    ...state, 
                    modelCollection: {
                        ...state.modelCollection,
                        modelCollections: state.modelCollection.modelCollections?.map((collection) => {
                            if(collection._id === payload.id){
                                return { ...collection, 
                                reTestingDetail : payload.reTestingDetail}
                            }
                            else {
                                return { ...collection}
                            }
                        }) || null
                    }
                }

            case SET_DATASET_COLLECTION_DATA_BOOST:
                return{
                    ...state,
                    collections:{
                        ...state.collections,
                        collections: handleUpdateDataBoost(state.collections.collections, payload)
                    }
                }
            case SET_COLLECTION_CONFIRM_DELETE_MODAL:
                return{
                    ...state,
                    ConfirmDeleteModal: {
                        ...state.ConfirmDeleteModal,
                        multiDelete: payload.multiDelete,
                        showConfirmDeleteModal: payload.showConfirmDeleteModal,
                        collectionToDelete: payload.collectionToDelete ?  payload.collectionToDelete : null,
                        selectedCollectionIds: payload.selectedCollectionIds,
                        collectionType: payload.collectionType,
                        deletedCollectionFromPopup: payload.deletedCollectionFromPopup
                    }
                }
            case CLOSE_COLLECTION_CONFIRM_DELETE_MODAL:
                return{
                    ...state,
                    ConfirmDeleteModal: {
                        ...state.ConfirmDeleteModal,
                        showConfirmDeleteModal: false,
                        deletedCollectionFromPopup: false
                    }
                }
            case SET_SHOULD_FETCH_LATEST_DATA_SET_COLLECTION_RESOURCE_LABELS_IF_CUTTENTLY_SHOWING_IN_SCREEN:
                return {
                    ...state,
                    shouldFetchLatestDataSetCollectionResourceLabelsIfCurrentlyShowingInScreen: payload
                }
        default:
            return state
    }
}

export default collection;

function handleSET_IS_DATA_SET_COLLECTION_RESOURCES_FILE_DOWNLOAD_LINK_BEING_FETCHED(state: ICollectionState, payload: setIsDataSetCollectionResourcesFileDownloadLinkBeingFetchedActionPayload): ICollectionState {
    const dataSetCollections = state.collections.collections;
    for (const dataSetCollection of dataSetCollections) {
        if (dataSetCollection._id.toString() === payload.dataSetCollectionId.toString()) {
            dataSetCollection.isResourcesFileDownloadLinkBeingFetched = payload.isDownloadLinkBeingFetched
            break;
        }
    }
    return {
        ...state,
        collections: {
            ...state.collections,
            collections: [...dataSetCollections]
        },
        selected: {...state.selected, isResourcesFileDownloadLinkBeingFetched: state.selected?._id === payload.dataSetCollectionId ? payload.isDownloadLinkBeingFetched : false} as unknown as ICollection
    }
}

function setModelCollectionDefaultCheckPoint(modelCollections : IModelCollection[], payload: IModelCollection){
    console.log(" ::: in reducer :::: ");
    let newModelCollections = modelCollections;
    newModelCollections = modelCollections.map(collection => {
    if(collection && payload && (collection._id === payload._id))
    {
        return {...collection, 
            checkpointFiles: [...payload.checkpointFiles]
            // {
            //     // ...collection.files, 
            //     files: payload
            // }
        }
    }  
    else{
        return collection
    }  
    })
    return newModelCollections
}

function handleUpdateTestCollectionData(state: ICollectionState, payload: ITestCollection): ICollectionState {
    try {

        const updatedTestCollections = state.testCollection?.testCollections?.collections || [];

        for (let testCollectionIndex = 0; testCollectionIndex < updatedTestCollections.length; testCollectionIndex++) {
            let testCollection = updatedTestCollections[testCollectionIndex];
            if (testCollection._id.toString() === payload._id.toString()) {
                testCollection = {...payload}
                updatedTestCollections[testCollectionIndex] = testCollection;

                break;
            }
        }

        return {
            ...state,
            testCollection: {
                ...state.testCollection,
                testCollections: {
                    ...state.testCollection.testCollections!,
                    collections:[...updatedTestCollections]
                }
            }
        }

    } catch (error) {
        
    }
    return state;
}

function handleUpdateModelCollectionData(state: ICollectionState, payload: IModelCollection): ICollectionState {
    try {

        const updatedModelCollections = state.modelCollection?.modelCollections || [];

        for (let modelCollectionIndex = 0; modelCollectionIndex < updatedModelCollections.length; modelCollectionIndex++) {
            let modelCollection = updatedModelCollections[modelCollectionIndex];
            if (modelCollection._id.toString() === payload._id.toString()) {
                modelCollection = {...payload}
                updatedModelCollections[modelCollectionIndex] = modelCollection;

                break;
            }
        }

        return {
            ...state,
            modelCollection: {
                ...state.modelCollection,
                modelCollections: [...updatedModelCollections]
            }
        }
        
    } catch (error) {
        
    }
    return state;
}

function handleUpdateDataBoost(collections: ICollection[], payload: {modelCollectionId: string, dataBoost: number}): ICollection[] {

    const resultCollections = [...collections];

    const collectionToUpdate =  resultCollections.find(collection => collection._id === payload.modelCollectionId)

    if(collectionToUpdate){
        collectionToUpdate.dataBoost = payload.dataBoost;
    }

    return resultCollections;
}