import { createContext } from "react";
import { IData, IDataResponse } from "../../../../../../../common";

//@ts-ignore
const BrowseCollectionsPopupContext = createContext<{
    itemsAddedToCart: IDataResponse;
    setItemsAddedToCart: (newState: IDataResponse) => void;
    toggleItemInCart: (dir: number, item: IData) => void;
    breadCrumbs: string[];
    setBreadCrumbs: Function;
    itemsSavedForLater: IDataResponse;
    setItemsSavedForLater: Function;
    toggleItemInSavedForLater: Function;
}>();

export default BrowseCollectionsPopupContext;
