import { UPDATE_COPILOT_RESOURCE_IMAGE_ANNOTATIONS, UPDATE_COPILOT_RESOURCE_IMAGE_ANNOTATIONS_REQUEST, REMOVE_ALL_COPILOT_RESOURCES, SET_CO_PILOT_RESOURCES_ONLY, SET_CO_PILOT_RESOURCES_TOTAL_COUNT_ONLY, UPDATE_COPILOT_RESOURCE_TRIMMED_TEXTS_IN_UI , UPDATE_COPILOT_RESOURCE_LABEL_IN_UI, DELETE_COPILOT_RESOURCE_TRIMMED_TEXT_IN_UI } from './../types/actions';
import { UPDATE_COPILOT_RESOURCE_IMAGE_ANNOTATIONS_Action_Payload, UPDATE_COPILOT_RESOURCE_IMAGE_ANNOTATIONS_REQUEST_Action_Payload, UPDATE_COPILOT_RESOURCE_LABEL_Action_Payload } from './../../common/constants/interfaces/copilot';
import { Dispatch } from "redux";
import { actionsErrorHandling } from "../../common";
import { ICoPilotDetailPageState, REMOVE_COPILOT_RESOURCE_FROM_UI_Action_Payload, SET_CO_PILOT_RESOURCE_DATA_Action_Payload } from "../../common/constants/interfaces/coPilotDetailPage";
import copilotService from "../../services/copilotService";
import { GET_RESOURCES_ERROR, REMOVE_COPILOT_RESOURCE_FROM_UI, SET_CO_PILOT_DETAIL_PAGE_STATE, SET_CO_PILOT_RESOURCES, SET_CO_PILOT_RESOURCE_DATA, SET_DATA_FETCHING_LOADER_VALUE, SET_SELECTED_CO_PILOT_RESOURCE } from "../types";
import { ICoPilotResource } from '../../common/constants/interfaces/coPilotResource';
import { UPDATE_SELECTED_TAB } from '../types/actions';

export const setCoPilotDetailPageState = (data: ICoPilotDetailPageState) => async (dispatch: Dispatch) => {
    dispatch({
        type: SET_CO_PILOT_DETAIL_PAGE_STATE,
        payload: data
    });
}
export const setCoPilotResourceData = (data: SET_CO_PILOT_RESOURCE_DATA_Action_Payload) => async (dispatch: Dispatch) => {
    dispatch({
        type: SET_CO_PILOT_RESOURCE_DATA,
        payload: data
    });
}
export const removeCoPilotResourceFromUI = (data: REMOVE_COPILOT_RESOURCE_FROM_UI_Action_Payload) => async (dispatch: Dispatch) => {
    dispatch({
        type: REMOVE_COPILOT_RESOURCE_FROM_UI,
        payload: data
    });
}
export const getResources = (data: any, prevData?: Array<ICoPilotResource>) => async (dispatch: Dispatch) => {
    try {
        const res = await copilotService.getResources(data);
        let updatedData = [];
        if (prevData && Number(data.offset) > 1) {
            updatedData = [...prevData, ...res.data.resources]
        } else {
            updatedData = [...res.data.resources]
        }
        dispatch({
            type:  SET_CO_PILOT_RESOURCES,
            payload: { resources: [...updatedData], totalCount: res.data.totalCount },
        });
    } catch (err) {
        console.log(err);
        actionsErrorHandling(err, GET_RESOURCES_ERROR, dispatch);
    }
}

export const getResourceById = (coPilotId: string) => async (dispatch: Dispatch) => {
    try {
        const res = await copilotService.getResourceById(coPilotId);
        dispatch({
            type:  SET_CO_PILOT_RESOURCES,
            payload: { resources: [res.data], totalCount: 1},
        });
    } catch (err) {
        console.log(err);
        actionsErrorHandling(err, GET_RESOURCES_ERROR, dispatch);
    }
}

export const updateSelectedCoPilotResource = (payload: string) => {
    return {
      type: SET_SELECTED_CO_PILOT_RESOURCE,
      payload
    }
}

export const updateIsDataFetching = (payload: boolean) => {
  return {
    type: SET_DATA_FETCHING_LOADER_VALUE,
    payload,
  };
};

export const removeAllCopilotResources = () => {
    return {
      type: REMOVE_ALL_COPILOT_RESOURCES
    };
  };

  export const updateSelectedTab = (tab: string) => {
    return {
      type: UPDATE_SELECTED_TAB,
      payload: tab
    };
  };

  export const setCoPilotResourcesOnly = (resources: ICoPilotResource[]) => {
    return {
      type: SET_CO_PILOT_RESOURCES_ONLY,
      payload: resources
    };
  };

  export const setCoPilotResourcesTotalCountOnly = (totalCount: number) => {
    return {
      type: SET_CO_PILOT_RESOURCES_TOTAL_COUNT_ONLY,
      payload: totalCount
    };
  };

export const updateCoPilotResource = (data: any) => async (dispatch: Dispatch) => {
    try {
        await copilotService.updateResource(data);
        // const resource = await copilotService.getResourceById(data.id[0]);
        /**
         * commenting following code to fetch data after update, as socket will update the state
         */
        // const resources = await copilotService.getResources(data.getQueryParams);
        // dispatch({
        //     type:  SET_CO_PILOT_RESOURCES,
        //     payload: resources.data,
        // });
        // dispatch({
        //     type: UPDATE_COPILOT_RESOURCE,
        //     payload: resource.data
        // })
    } catch (err) {
        console.log(err);
        actionsErrorHandling(err, GET_RESOURCES_ERROR, dispatch);
        throw err;
    }
}

export const updateCopilotResourceTrimmedTextsInUI = (resourceData: any) => {
    return {
        type: UPDATE_COPILOT_RESOURCE_TRIMMED_TEXTS_IN_UI,
        payload: resourceData
    }
}

export type deleteCopilotResourceTrimmedTextsInUIActionPayload = {
    resourceId: string, 
    idOfTrimTextToDelete: string
}
export const deleteCopilotResourceTrimmedTextInUI = (resourceData: deleteCopilotResourceTrimmedTextsInUIActionPayload) => {
    return {
        type: DELETE_COPILOT_RESOURCE_TRIMMED_TEXT_IN_UI,
        payload: resourceData
    }
}

export const updateCopilotResourceLabelInUI = (resourceData: UPDATE_COPILOT_RESOURCE_LABEL_Action_Payload) => {
    return {
        type: UPDATE_COPILOT_RESOURCE_LABEL_IN_UI,
        payload: resourceData
    }
}

export const updateCopilotResourceImageAnnotationsInDB = (data: UPDATE_COPILOT_RESOURCE_IMAGE_ANNOTATIONS_Action_Payload) => async (dispatch: Dispatch) => {
    return new Promise<void>(async (resolve, reject)=>{

        dispatch<{type: typeof UPDATE_COPILOT_RESOURCE_IMAGE_ANNOTATIONS_REQUEST, payload: UPDATE_COPILOT_RESOURCE_IMAGE_ANNOTATIONS_REQUEST_Action_Payload}>({
            type: "UPDATE_COPILOT_RESOURCE_IMAGE_ANNOTATIONS_REQUEST",
            payload: {resourceId: data.resourceId, shouldNotFormNewRootState: data.shouldNotFormNewRootState},
        });

        await copilotService.updateResource({
            id: [data.resourceId],
            imageAnnotations: data.imageAnnotations
        })

        dispatch<{type: typeof UPDATE_COPILOT_RESOURCE_IMAGE_ANNOTATIONS, payload: UPDATE_COPILOT_RESOURCE_IMAGE_ANNOTATIONS_Action_Payload}>({
            type: "UPDATE_COPILOT_RESOURCE_IMAGE_ANNOTATIONS",
            payload: {
                resourceId: data.resourceId,
                imageAnnotations: data.imageAnnotations,
                shouldNotFormNewRootState: data.shouldNotFormNewRootState
            },
        });  
        
        resolve()
    })
}