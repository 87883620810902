import { BooleanLiteral } from "@babel/types";
import { ICommonQueryParams, ICommonResponse, ICommonState } from "./common";
import { ActivePageActions, CopilotActivePageSortOrder } from "./copilot";
import { IProject } from "./projects";
import { PredictionStatusLogic } from "./data";

export type IModelArchitectureAvailable = {
    label: string, ipAddress: string, description: string, 
    apiUrlToCallForDeployment: string, isDeploymentFeatureEnabled: boolean,
    listId?: string,
    hyperParameter?: string,
    vm_name:string
};

export type IModelUniqueVersions = {
    uniqueModelCollectionVersions  : string[];
}

export type IModelUniqueStatuses = {
    uniqueModelCollectionStatuses  : string[];
}

export type IModelUniqueTrainingDataSet = {
    name : string;
}

export type IModelUniqueTrainingDataSets = {
    uniqueModelCollectionTrainingDataSets  : IModelUniqueTrainingDataSet[];
}

export type IModelUniqueArchitectures = {
    uniqueModelCollectionArchitectures  : string[];
}

export type IModelUniqueAccuracyStatuses = {
    uniqueModelCollectionAccuracyStatuses: string[];
}

export type IModeLabelMap = {
    parentLabel: string;
    childLabels: string[];
    parentLabelError?: string;
    childLabelError?: string;
};
export type IModeCameraMap = {
    cameraId:string, 
};

/**
 * Models
 */
// export type IModelArchitecturesAvailable = string[];
export type IModelArchitecturesAvailable = IModelArchitectureAvailable[];
export type IModelLabelsMapping = IModeLabelMap[]
export type IModelCameraMapping = IModeCameraMap[]
export interface IReferenceDataFile extends File {
    referenceGroupId?: string
}
export type IReferenceGroupState = {
    _id: string,
    groupName?: string,
    referenceDatas: IReferenceDataState[],
    showLabelDropDown?: boolean,
    showContentsMetaInformation?: boolean,
    showConfidenceScoreToUser?: boolean,
    activePageActions?: {
        affirmationButton?: {
            onClickBehaviour: "approve" | "reject",
            buttonText?: string
        },
        negativeButton: {
            onClickBehaviour: "approve" | "reject",
            buttonText?: string
        }
    },
    exampleType?: "good" | "defective" | "none"
}
export type IReferenceDataState = {
    _id?: string,
    fileName: string,
    fileUrl: string,
    caption?: string,
    showingScenario?: ('viewMode' | 'editingAnnotations')[];
    linkedAnnotationLabels?: string[];
}
export type IModelLabelsAvailable = string[];
export type IModelType =  'videoAnnotation'| 'video' | 'audio' | 'image' | 'imageAnnotation' | 'multipleImageAnnotation' | 'imageAnnotationGroup' | 'forecasting' | 'text' | 'textAnnotation' | 'DLP' | 'textFile' | 'prompt' | 'bookTranslation' | 'textComparison' | 'audioTextTranscription' 
// copilot types
| "bestAnswer" | "evaluateAnswer" | "spectrogramAudio" | 'evaluateAnswerScore'

;

export type ModelAnnotationLabelAvailable = {
    id: string,
    value: string
}
export interface Camera{
    cameraId:string;
}
export interface IAvailableLabels {
    value: string;
    textBackgroundColour: string;
    textColour: string;
    valueError?: string;
    bgColorError?: string;
    textColorError?: string;
}

export enum ModelFields {
    AiAssistantEnabledField = "aiAssistantEnabled",
    ApiToCallOnAiAssistantInvocationField = "apiToCallOnAiAssistantInvocation",
    AiAssistantDescriptionField = "aiAssistantDescription"
}

export enum AiTestFields {
    AiTestEnabledField = "aiAssistantEnabled",
    ApiToCallOnAiTestInvocationField = "apiToCallOnAiAssistantInvocation",
    AiTestDescriptionField = "aiAssistantDescription"
}


export interface IModel {
  wordErrorRateThreshold?: number;
  allowUserToSetAnyLabelForImageAnnotation: boolean;
  defaultDataSetCollection: string;
  _id: string;
  name: string;
  headers: string;
  project: IProject;
  created_at: string;
  type: IModelType;
  modelGroupId: string;
  architecturesAvailable: IModelArchitecturesAvailable;
  labelsAvailable: IModelLabelsAvailable;
  annotationLabelsAvailable: ModelAnnotationLabelAvailable[];
  forecastingLabelsAvailable: ImageList[];
  aiAssistantLabellingEnabled: boolean;
  apiToCallIfAiAssistantLabellingEnabled: string;
  labelsMapping: IModeLabelMap[];
  cameras: IModeCameraMap[];
  
  planogramEnabled:boolean;

  /** used only for type book translation models */
  translateApiUrl?: string;

  /** used only for type audio models */
  maxAudioTrimAllowedInMilliSeconds: number;
  
  allowUserToSetAnyLabel: boolean;

  is500WCompressionEnabled?: boolean;
  lastUpdatedAt ?: Date;

  modelGroup?: {
    name?: string
  }

  maximumMisPredictionDeviationAllowedInNumber ?: number;
  predictionStatusLogic : PredictionStatusLogic;
  modelCollectionExist?: boolean;
  datasetCollectionExist ?: boolean;
  testCollectionExist ?: boolean;
  liveModelCollectionExist ?: boolean;

  /** Manual Ai Assistant preferences */
  aiAssistantEnabled?: boolean;
  apiToCallOnAiAssistantInvocation?: string;
  aiAssistantDescription?: string;
  /** Manual Ai Assistant preferences end */

  /** Manual Ai Test preferences */
  aiTestEnabled?: boolean;
  apiToCallOnAiTestInvocation?: string;
  aiTestDescription?: string;
  /** Manual Ai Assistant preferences end */

  modelIntegrityPassingPercentage: number;
  confidenceScoreThresholdForIntegrity: number;

  /** Segmentation model analytics configuration */
  isSegmentationModelAnalytics?: boolean;
  isAnalyticsBasedOnWordErrorRateEnabled?: boolean;
  segmentationModelAnalyticsAccuracyMetric?: "F1" | "Precision" | "Recall" | "Mean Average Precision";
  /** Segmentation model analytics configuration ends */
  referenceData: IReferenceDataState[];

  analyticsAiAssistantEnabled?: boolean;
  analyticsAiAssistantDescription?: string;
  apiToCallOnAnalyticsAssistantEnabled?: string;

  isSpectrogramEnabled?: boolean;
  iouThresholdValue?: number;
  /** text Comparison Model */
  textOneHeadingForUI?: string;
  textTwoHeadingForUI?: string;
  aiAssistedLabellingId?: string  | null;
  tags?:Tag[]
}
export interface Tag {
    value:string
}
export interface IModelUpdatePayload {
    name: string;
    headers: string;
    project: string;
}
export interface IModelAddPayload {
    name: string;
    project: string;
    type: IModelType;
    labelsAvailable: string[];
    architecturesAvailable: string[];
    modelGroupId: string;
    annotationLabelsAvailable: ModelAnnotationLabelAvailable[];
}
export interface IModelArchitecturesAvailablePayload {
    modelId: string;
}

export interface IModelCollectionUniqueDataPayload {
    modelId : string;
}

export interface IAdditionalHandTrackingDataQueryParams {
    modelId: string;
    modelCollectionIds: string[];
  }

  interface AdditionalHandTrackingDataItem {
    modelCollectionId: string;
    resourcesCount: number;
    noOfTimesUserFoundSameHandHavingMultipleId: number;
    noOfTimesUserFoundSameHandNotHavingMultipleId: number;
    userYetToMentionWhetherHeFoundSameHandHavingMultipleIdsOrNot: number;
  }
  
  export interface AdditionalHandTrackingDataApiResponse extends Array<AdditionalHandTrackingDataItem> {}
/*----------------------------------------------*/

/**
 * Params
 */

export interface IModelQueryParams extends ICommonQueryParams {
    project: string;
    getModelGroupName?: "true";
    collectionsExist ?: boolean;
}

export interface IModelNamesQueryParams {
    modelIds ?: string[];
    modelSelectQuery ?: string;
}
/*----------------------------------------------*/

/**
 * Backend API's
 */

export interface IModelsResponse extends ICommonResponse {
    models: IModel[];
}
export interface IModelNamesResponse{
    _id: string;
    name: string;
}
export type IModelArchitecturesAvailableApiResponse = IModelArchitecturesAvailable;
export type IModelLabelsAvailableApiResponse = IModelLabelsAvailable;

export interface DefaultDataSetCollectionAPIResponse {
  _id: string;
  defaultDataSetCollection?: string;
}
export type UpdateModelApiRequestPayload = {
  allowUserToSetAnyLabelForImageAnnotation?: boolean;
  id: string[];
  labelsAvailable?: string[];
  copilotLabelsAvailable?: IAvailableLabels[];
  copilotRejectedLabelsAvailable?: IAvailableLabels[];
  architecturesAvailable?: IModelArchitecturesAvailable;
  annotationLabelsAvailable?: ModelAnnotationLabelAvailable[];
  apiURLToCallOnApprovingResource?: string;
  defaultDataSetCollection?: string | null;
  aiAssistantLabellingEnabled?: boolean;
  apiToCallIfAiAssistantLabellingEnabled?: string;
  shouldSendResourceToModel?: boolean;
  modelIdToAddResourceInto?: string;
  allowUsersToWriteOwnReasons?: boolean;
  maxAudioTrimAllowedInMilliSeconds?: number;
  translateApiUrl?: string;
  resourcesAutoDelete?: boolean;
  numberOfDaysAfterResourcesShouldGetDeleted?: number;
  /** model collection deployment feature configuration */
  isDeploymentFeatureEnabled?: boolean;
  apiUrlToCallForDeployment?: string;
  /** model collection deployment feature configuration ends */

  maximumMisPredictionDeviationAllowedInNumber ?: number;
  predictionStatusLogic?: PredictionStatusLogic;
  shouldExtendTimeForActiveResource?: boolean;
  extendActiveResourceTimeInSeconds?: number;
  labelsMapping?: {
    parentLabel: string;
    childLabels: string[];
  }[];
  cameras?:Camera[];
  planogramEnabled?: boolean,
  allowUserToSetAnyLabel?: boolean;

  isSegmentationModelAnalytics?: boolean
  isAnalyticsBasedOnWordErrorRateEnabled?: boolean
  wordErrorRateThreshold?: number
  segmentationModelAnalyticsAccuracyMetric?: string

  is500WCompressionEnabled?: boolean;

  modelGroupId?: IModel["modelGroupId"];
  name ?: string;

  /** Manual Ai Assistant preferences */
  aiAssistantEnabled?: boolean;
  apiToCallOnAiAssistantInvocation?: string;
  aiAssistantDescription?: string;
  aiTestEnabled?: boolean;
  apiToCallOnAiTestInvocation?: string;
  aiTestDescription?: string;
  referenceData?: IReferenceDataState[];
  files?: IReferenceDataFile []
  /** Manual Ai Assistant preferences end */  

  modelIntegrityPassingPercentage?: number;
  // setting confidence score threshold value for integrity from configuration
  confidenceScoreThresholdForIntegrity?: number;

//   for copilot configuration
// copilot active page sort order
  sortOrder?: CopilotActivePageSortOrder;

  showContentMetadataInfo?: boolean;
  showLabelDropdown?: boolean;
  showConfidenceScore?: boolean;
  actionsInCopilotActivePage?: boolean;
  activePageActions?: ActivePageActions;
  activeTimerDuration?: number

  analyticsAiAssistantEnabled?: boolean;
  analyticsAiAssistantDescription?: string;
  apiToCallOnAnalyticsAssistantEnabled?: string;  
    isSpectrogramEnabled?: boolean;
  moveDataFromExpiredAfterCertainTime?: moveDataFromExpiredAfterCertainTimeType;
  iouThresholdValue?: number;
  aiAssistedLabellingId?: string | null;
  tags?:string[]
};
export type moveDataFromExpiredAfterCertainTimeType = {
	isEnabled: boolean,
	modelIdToMoveDataIn: string,
	dataStatusToSetWhileMoving: string,
	moveFromExpiredTabAfterHowMuchSeconds: number
}
export type UpdateModelApiResponse = {
    n: number,
    nModified: number,
    ok: number
};
export type GetModelDetailsAPiReqPayload = {
    modelId: string,
    modelSelectQuery?: string
}

export type GetModelReferenceDataAPiReqPayload = {
    modelId: string,
}

export type GetModelDetailsAPiResponse = IModel

export type UniqueModelCollectionVersionsApiResponse = IModelUniqueVersions
export type UniqueModelCollectionArchitecturesApiResponse = IModelUniqueArchitectures
export type UniqueModelCollectionAccuracyStatusesApiResponse = IModelUniqueAccuracyStatuses
export type UniqueModelCollectionTrainingDataSetsApiResponse = IModelUniqueTrainingDataSets
export type UniqueModelCollectionStatusesApiResponse = IModelUniqueStatuses
/*----------------------------------------------*/

/**
 * Redux
 */
export interface IModelState extends ICommonState {
    models: IModel[];
    total: number;
    selected?: IModel;
    activeTab: '/data' | '/collections' | '/configuration' | '/planograms';
    isPlanogramEnabledChanged?: boolean;
}

/*----------------------------------------------*/
export interface ImageList {
    name: string,
    resourceUrl: string,
}



export interface Labelling {
    _id: string;
    projectId: string;
    name: string;
    status: string;
    description: string;
    
  }

export type accuracyValueInLocalStorage = {
    accuracyValue: string,
    iouThreshold: number
}

