export function removeDuplicateElementsFromArray<ArrayElementType>(
  arr: ArrayElementType[]
): ArrayElementType[] {
  return arr.filter((item, index) => arr.indexOf(item) === index);
}

export function removeDuplicateElementsFromArrayByElementId(array: {id: string}[]): any[] {
	const uniqueElements: any = [];
	for (let index = 0; index < array.length; index++) {
		const element = array[index];
		if (!elementIdExistsInArray(uniqueElements, element.id)) {
      uniqueElements.push(element);
    }
	}
  return uniqueElements;
}

export function elementIdExistsInArray(array: {id: string}[], idToCheck: string): boolean {
  for (const element of array) {
    if (element.id === idToCheck) {
      return true;
    }
  }
  return false;
}

export function getRandomElementFromArray<E>(array: E[]): E {
  return array[Math.floor(Math.random()*array.length)];
}

export function getRandomElementsFromArray<E>(array: E[], noOfElementsToReturn: number): E[] {
    const result = new Array(noOfElementsToReturn);
    let len = array.length;
    const taken = new Array(len);
  if (noOfElementsToReturn > len)
    throw new RangeError("getRandom: more elements taken than available");
  while (noOfElementsToReturn--) {
    var x = Math.floor(Math.random() * len);
    result[noOfElementsToReturn] = array[x in taken ? taken[x] : x];
    taken[x] = --len in taken ? taken[len] : len;
  }
  return result;
}

export function areBothArraysEqual<ArrayElementType>(array1: ArrayElementType[], array2: ArrayElementType[]): boolean{
  const array2Sorted = array2.slice().sort();
   return array1.length === array2.length && array1.slice().sort().every(function(value, index) {
      return value === array2Sorted[index];
  });
}
