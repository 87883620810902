import { createContext } from "react";
import { IData, IDataResponse, IModelCollection } from "../../../../../../../common";

//@ts-ignore
const BrowseModelCollectionsPopupContext = createContext<{
    selectedModelCollection: IModelCollection | null
    setSelectedModelCollection: React.Dispatch<React.SetStateAction<IModelCollection | null>>
    breadCrumbs: string[];
    setBreadCrumbs: React.Dispatch<React.SetStateAction<string[]>>;
}>();

export default BrowseModelCollectionsPopupContext;
