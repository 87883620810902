import { CSSProperties, ReactElement, createContext, useCallback, useMemo, useState } from "react";
import { AlertContext } from "./alertContext";
import { Alert } from "../../components/Alert/Alert";

type AlertContextWrapperProps = {
  children: ReactElement;
}

export function AlertContextWrapper(props: AlertContextWrapperProps) {
  const [alertPopup, setAlertPopup] = useState({
    isOpen: false,
    message: "",
    styleProperty: {}
  });

  const setAlert = useCallback(
    (message: string, styleProperty?: CSSProperties) => {
      setAlertPopup({
        isOpen: true,
        message: message,
        styleProperty: styleProperty || {}
      })
    },
    [],
  );
  

  const AlertContextValue = useMemo(() => {
    return {
      setAlert
    }
  }, [setAlert])

  return (
    <AlertContext.Provider
      value={AlertContextValue}
    >
      <>
        {
          alertPopup.isOpen &&
          <Alert 
            message={alertPopup.message}
            onClose={()=>{
              setAlertPopup(oldState=>({
                ...oldState,
                isOpen: false,
                message: ""
              }))
            }}
            styleProperty={{...alertPopup.styleProperty}}
          />
        }
        {props.children}
      </>
    </AlertContext.Provider>
  )
}
