import { Dispatch } from "redux";

import { IDrawerIsOpen, IOptionsDrawerState } from "../../common";

import { CHANGE_SIDE_DRAWER, SET_DRAWER_OPEN } from "../types";

export const setSideDrawer = (data: IOptionsDrawerState) => async (dispatch: Dispatch) => {
    dispatch({
        type: CHANGE_SIDE_DRAWER,
        payload: data,
    });
}

export const setDrawerOpen = (data: IDrawerIsOpen) => async (dispatch: Dispatch) => {
    dispatch({
        type: SET_DRAWER_OPEN,
        payload: data,
    });
}
