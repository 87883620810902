import React from 'react';
import { Button, ButtonProps, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    button: {
        borderRadius: 3,
        boxShadow: '0 10px 20px -5px rgba(2, 141, 154, 0.25)',
        minWidth: '150px',
        textTransform: 'capitalize',
    }
}));

const CustomButton = (props: ButtonProps) => {
    const classes = useStyles();
    const defaults: ButtonProps = {
        color: 'primary',
        variant: 'contained'
    }
    return (
        <Button {...defaults} {...props} classes={{ root: classes.button }}>
            {props.children}
        </Button >
    );
};

export { CustomButton };
