import io, { Socket } from "socket.io-client";
import { APP_API } from "./constants";

const serverURL = APP_API;

let conn: Socket;

export const socket = { 
    get connection() {
        if (!conn) {
            // conn = io(`http://localhost:${SOCKER_PORT}`);
            conn = io(`${serverURL?.substring(0, serverURL.length - 1)}`,
                {
                    transports: ["websocket"]
                }
            );
        }
        return conn;
    }
}
