import { GET_DATA_SUCCESS_FOR_SELECTING_ALL_RESOURCES_MATCHING_FILTER, UPDATE_RESOURCE_REMARKS, UPDATE_RESOURCE_REMARKS_REQUEST, UPDATE_RESOURCE_IMAGE_ANNOTATIONS,UPDATE_RESOURCE_MULTIPLE_IMAGE_ANNOTATIONS, UPDATE_RESOURCE_IMAGE_ANNOTATIONS_REQUEST, SET_DISCARDED_COUNT, SET_SOLD_COUNT, SET_ADD_COUNT, UPDATE_WHICH_RESOURCES_TO_BE_UPDATED_IN_DB, SET_RESOURCE_DATA_BOOST, UPDATE_RESOURCE_IN_UI, DELETE_RESOURCE_TRIMMED_TEXT_IN_UI, GET_API_REQUEST_CANCELLED, ADD_RESOURCE_SUCCESS, CLEAR_ADD_RESOURCE_SUCCESS, SET_IS_FETCHING_RESOURCE_TEXT_FILE_CONTENTS, SET_RESOURCE_TEXT_FILE_CONTENTS, UPDATE_RESOURCE_PROMPT, UPDATE_RESOURCE_VIDEO_ANNOTATIONS, UPDATE_RESOURCE_VIDEO_ANNOTATIONS_REQUEST, REMOVE_DATA_SET_COLLECTIONS_FROM_RESOURCES } from './../types/actions';
import { UPDATE_RESOURCE_IMAGE_GROUP_ANNOTATIONS_Action_Payload, UPDATE_RESOURCE_IMAGE_ANNOTATIONS_REQUEST_Action_Payload, UPDATE_RESOURCE_IMAGE_ANNOTATIONS_Action_Payload,UPDATE_RESOURCE_MULTIPLE_IMAGE_ANNOTATIONS_Action_Payload, UPDATE_RESOURCE_IMAGE_GROUP_ANNOTATIONS_REQUEST_Action_Payload, UPDATE_RESOURCE_REMARKS_REQUEST_Action_Payload, UPDATE_RESOURCE_REMARKS_Action_Payload, ResourceStatus, UPDATE_DATA_REQUEST_Action_Payload, UPDATE_DATA_SUCCESS_Action_Payload, UpdateItemsCountPayload, AdditionalResource, IData, UPDATE_RESOURCE_PROMPT_Action_Payload, UPDATE_RESOURCE_VIDEO_ANNOTATIONS_Action_Payload } from './../../common/constants/interfaces/data';
import { ADD_DATA_SET_COLLECTION_IN_DATA_SET_COLLECTIONS_FILTER_ACTION_PAYLOAD, FETCH_DATA_SET_COLLECTIONS_FOR_FILTER_REQUEST_ACTION_PAYLOAD, FETCH_DATA_SET_COLLECTIONS_FOR_FILTER_SUCCESS_ACTION_PAYLOAD, IS_EDIT_TEXT_SELECTED_ACTION_PAYLOAD } from './../../common/constants/interfaces/data';
import { AnyAction } from "redux";
import { CounterAction, IDataState, } from "../../common";
import { resourceContainsDataSetCollection } from "../../services/resourceHelperService";
import { ADD_RESOURCES_TO_DATA_SET_COLLECTION_REQUEST_Action_Payload, ADD_RESOURCES_TO_DATA_SET_COLLECTION_SUCCESS_Action_Payload, deleteResourceTrimmedTextInUIActionPayload, SET_DATA_SET_COLLECTIONS_IN_RESOURCE_SUCCESS_Action_Payload, SET_IS_FETCHING_RESOURCE_TEXT_FILE_CONTENTSActionPayload, SET_RESOURCE_TEXT_FILE_CONTENTSActionPayload } from "../actions";

import {
    ADD_DATA_SET_COLLECTION_IN_DATA_SET_COLLECTIONS_FILTER,
    ADD_RESOURCES_TO_DATA_SET_COLLECTION_REQUEST,
    ADD_RESOURCES_TO_DATA_SET_COLLECTION_SUCCESS,
    CLEAR_DATA_FOR_DRAWER,
    FETCH_DATA_SET_COLLECTIONS_FOR_FILTER_REQUEST,
    FETCH_DATA_SET_COLLECTIONS_FOR_FILTER_SUCCESS,
    GET_DATA_CLEAR_ERROR,
    GET_DATA_ERROR,
    GET_DATA_REQUEST,
    GET_DATA_SUCCESS,
    SET_IS_EDIT_TEXT_SELECTED,
    SET_DATA_FOR_DRAWER,
    SET_DATA_SET_COLLECTIONS_IN_RESOURCE_REQUEST,
    SET_DATA_SET_COLLECTIONS_IN_RESOURCE_SUCCESS,
    SET_RESOURCES,
    SET_RESOURCES_TOTAL_COUNT,
    SET_SELECTED_DATA,
    SET_SELECTED_ITEMS,
    UPDATE_DATA_CLEAR_ERROR,
    UPDATE_DATA_ERROR,
    UPDATE_DATA_REQUEST,
    UPDATE_DATA_SUCCESS,
    UPDATE_VIEW_OF_DATA_MODEL,
    UPDATE_RESOURCE_IMAGE_GROUP_ANNOTATIONS,
    UPDATE_RESOURCE_IMAGE_GROUP_ANNOTATIONS_REQUEST,
    ADD_RESOURCE_REQUEST
} from '../types';
import { removeDuplicateElementsFromArray } from '../../services/arrayHelperService';
import { GetDataSuccessForSelectingAllResourcesMatchingFilterActionPayload } from '../../common/constants/interfaces/data';
import { copyByValue } from '../../services/variableHelperService';

const initialState: IDataState = {
    data: {
        resources: [],
        totalCount: 0,
    },
    shouldBlockDataTableKeyboardEvents: false,
    selectedItems: [],
    approvedWaitingList: [],
    approvalWaitingList: [],
    rejectedWaitingList: [],
    deletedWaitingList: [],
    isFetching: false,
    isFetchSuccess: false,
    isFetchError: false,
    fetchErrorMessage: '',
    isAdding: false,
    isAddError: false,
    isAddSuccess: false,
    createErrorMessage: '',
    selectedIndex: -1,
    isUpdating: false,
    isUpdateError: false,
    isUpdateSuccess: false,
    updateErrorMessage: '',
    resourcesWhoseDataSetCollectionsAreBeingUpdatedInDB: [],
    dataSetCollectionsBeingFetchedForFilter: false,
    dataSetCollectionsForFilter: [],
    dataView: 'list-view',
    isApiRequestCancelled: false
}

const data = (state: IDataState = initialState, action: AnyAction): IDataState => {
    const { type, payload } = action;
    switch (type) {
        case UPDATE_RESOURCE_IN_UI:
            return handleUPDATE_RESOURCE_IN_UI(state, payload)
        case GET_DATA_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case ADD_RESOURCE_REQUEST:
            return {
                ...state,
                isAdding: true,
            };
        case ADD_RESOURCE_SUCCESS:
            return {
                ...state,
                isAdding: false,
                isAddSuccess: true,
                // selected: payload
            };
        case CLEAR_ADD_RESOURCE_SUCCESS:
            return {
                ...state,
                isAddSuccess: false,
                // selected: undefined,
            };
        case GET_DATA_SUCCESS:
            return {
                ...state,
                data: payload,
                isFetching: false,
                isApiRequestCancelled:false
            };
        case GET_DATA_SUCCESS_FOR_SELECTING_ALL_RESOURCES_MATCHING_FILTER:
            return handleGET_DATA_SUCCESS_FOR_SELECTING_ALL_RESOURCES_MATCHING_FILTER(state, payload);
        case SET_IS_FETCHING_RESOURCE_TEXT_FILE_CONTENTS:
            return handleSET_IS_FETCHING_RESOURCE_TEXT_FILE_CONTENTS(state, payload)
        case SET_RESOURCE_TEXT_FILE_CONTENTS:
            return handleSET_RESOURCE_TEXT_FILE_CONTENTS(state, payload)
        case GET_DATA_ERROR:
            return {
                ...state,
                fetchErrorMessage: payload,
                isFetchError: true,
                isFetching: false,
            };
        case REMOVE_DATA_SET_COLLECTIONS_FROM_RESOURCES:
            const resourcesToUpdateAfterPerformingAction = [...(state?.data?.resources || [])];

            const {resourceIds, dataSetCollectionIds} = payload;

            for (let resourceIndex = 0; resourceIndex < resourcesToUpdateAfterPerformingAction.length; resourceIndex++) {
                const resource = resourcesToUpdateAfterPerformingAction[resourceIndex];
                if (resourceIds.includes(resource._id)) {
                    for (let dataSetCollectionIndex = 0; dataSetCollectionIndex < resource.dataSetCollections.length; dataSetCollectionIndex++) {
                        const dataSetCollection = resource.dataSetCollections[dataSetCollectionIndex];
                        if (dataSetCollectionIds.includes(dataSetCollection._id)) {
                            resource.dataSetCollections.splice(dataSetCollectionIndex, 1);
                            dataSetCollectionIndex -= 1;
                        }
                    }
                }
            }

            return {
                ...state,
                data: {
                    ...state.data,
                    resources: [...resourcesToUpdateAfterPerformingAction]
                }
            }
        case GET_API_REQUEST_CANCELLED :
            return {
                ...state,
                isApiRequestCancelled : true
            }
        case GET_DATA_CLEAR_ERROR:
            return {
                ...state,
                fetchErrorMessage: '',
                isFetchError: false,
            };
        case DELETE_RESOURCE_TRIMMED_TEXT_IN_UI:
            return handleDELETE_RESOURCE_TRIMMED_TEXT_IN_UI(state, payload);
        case UPDATE_DATA_REQUEST:
            return handleUPDATE_DATA_REQUEST(state, payload);
        case UPDATE_DATA_SUCCESS:
            return handleUPDATE_DATA_SUCCESS(state, payload);
        case UPDATE_DATA_ERROR:
            return {
                ...state,
                updateErrorMessage: payload,
                isUpdateError: true,
                isUpdating: false,
            };
        case UPDATE_DATA_CLEAR_ERROR:
            return {
                ...state,
                updateErrorMessage: '',
                isUpdateError: false,
            };
        case SET_DATA_FOR_DRAWER:
            return {
                ...state,
                // drawerData: payload
            }
        case CLEAR_DATA_FOR_DRAWER:
            return {
                ...state,
                // drawerData: null
            }
        case SET_SELECTED_DATA:
            return {
                ...state,
                // selected: payload ? {...payload} : undefined
                selected: payload ? payload : undefined
            }
        case SET_RESOURCES:
            return {
                ...state,
                data: {
                    ...state.data,
                    resources: payload ? [...payload] : [],
                }
            }
        case SET_RESOURCES_TOTAL_COUNT:
            return {
                ...state,
                data: {
                    ...state.data,
                    totalCount: payload
                }
            }
        case SET_SELECTED_ITEMS:
            return {
                ...state,
                selectedItems: payload
            }
        case ADD_RESOURCES_TO_DATA_SET_COLLECTION_REQUEST:
        case SET_DATA_SET_COLLECTIONS_IN_RESOURCE_REQUEST:
            let actionPayload: ADD_RESOURCES_TO_DATA_SET_COLLECTION_REQUEST_Action_Payload = payload;
            if (actionPayload.resourceIds) {
                return {
                    ...state,
                    resourcesWhoseDataSetCollectionsAreBeingUpdatedInDB: [
                        ...state.resourcesWhoseDataSetCollectionsAreBeingUpdatedInDB,
                        ...actionPayload.resourceIds
                    ]
                }
            } else {
                return {
                  ...state,
                  resourcesWhoseDataSetCollectionsAreBeingUpdatedInDB: [
                    ...state.resourcesWhoseDataSetCollectionsAreBeingUpdatedInDB
                  ],
                };
            }

        case ADD_RESOURCES_TO_DATA_SET_COLLECTION_SUCCESS:
            let actionPayload2 = payload as ADD_RESOURCES_TO_DATA_SET_COLLECTION_SUCCESS_Action_Payload;
            const updatedResourcesWhichAreBeingAddedToDataSetCollectionInDB = removeResourceIdsWhichAreAddedToDataSetCollectionInDB(actionPayload2.resourceIds, state.resourcesWhoseDataSetCollectionsAreBeingUpdatedInDB, state.data.resources)
            
            let updatedResources = state.data.resources;
            for (const resource of updatedResources) {
                if (actionPayload2.resourceIds) {
                    for (const resourceIdBeingUpdated of actionPayload2.resourceIds) {
                        if (resource._id.toString() === resourceIdBeingUpdated.toString()) {
                            if (!resource.dataSetCollections) {
                                resource.dataSetCollections = [];
                            }
                            for (const dataSetCollection of actionPayload2.dataSetCollections) {
                                if (!resourceContainsDataSetCollection(resource, dataSetCollection)) {
                                    resource.dataSetCollections.push({
                                        _id: dataSetCollection._id,
                                        name: dataSetCollection.name
                                    })
                                }
                            }
                        }
                    }
                } else {
                    for (const dataSetCollection of actionPayload2.dataSetCollections) {
                        if (!resourceContainsDataSetCollection(resource, dataSetCollection)) {
                        resource.dataSetCollections.push({
                          _id: dataSetCollection._id,
                          name: dataSetCollection.name,
                        });
                      }
                    }
                }
            }

            return {
                ...state,
                resourcesWhoseDataSetCollectionsAreBeingUpdatedInDB: [
                    ...updatedResourcesWhichAreBeingAddedToDataSetCollectionInDB
                ],
                data: {
                    ...state.data,
                    resources: [
                        ...updatedResources
                    ]
                }
            }
        case SET_DATA_SET_COLLECTIONS_IN_RESOURCE_SUCCESS:
            const setDataSetCollections_action_payload = payload as SET_DATA_SET_COLLECTIONS_IN_RESOURCE_SUCCESS_Action_Payload
            const resourceId = setDataSetCollections_action_payload.resourceId;
            const dataSetCollections = setDataSetCollections_action_payload.dataSetCollections

            const resourcesToUpdate = state.data.resources;
            const updatedResourcesWhichAreBeingAddedToDataSetCollectionInDBInSET_DATA_SET_COLLECTIONS_IN_RESOURCE_SUCCESS = removeResourceIdsWhichAreAddedToDataSetCollectionInDB([resourceId], state.resourcesWhoseDataSetCollectionsAreBeingUpdatedInDB)

            for (let resourceIndex = 0; resourceIndex < resourcesToUpdate.length; resourceIndex++) {
                const resource = resourcesToUpdate[resourceIndex];
                if (resource._id.toString() === resourceId.toString()) {
                    resource.dataSetCollections = dataSetCollections;
                    resourcesToUpdate[resourceIndex] = resource;
                    break;
                }
            }

            return {
                ...state,
                resourcesWhoseDataSetCollectionsAreBeingUpdatedInDB: [
                    ...updatedResourcesWhichAreBeingAddedToDataSetCollectionInDBInSET_DATA_SET_COLLECTIONS_IN_RESOURCE_SUCCESS
                ],                
                data: {
                    ...state.data,
                    resources: [...resourcesToUpdate]
                }
            }
        case FETCH_DATA_SET_COLLECTIONS_FOR_FILTER_REQUEST:
            return handleFETCH_DATA_SET_COLLECTIONS_FOR_FILTER_REQUEST(state, payload)
        case FETCH_DATA_SET_COLLECTIONS_FOR_FILTER_SUCCESS:
            return handleFETCH_DATA_SET_COLLECTIONS_FOR_FILTER_SUCCESS(state, payload)
        case ADD_DATA_SET_COLLECTION_IN_DATA_SET_COLLECTIONS_FILTER:
            return handleADD_DATA_SET_COLLECTION_IN_DATA_SET_COLLECTIONS_FILTER(state, payload)
        case SET_IS_EDIT_TEXT_SELECTED:
            return handleIsEditTextSelected(state, payload)
        case UPDATE_VIEW_OF_DATA_MODEL:
            return{
                ...state,
                dataView: payload
            }
        case UPDATE_RESOURCE_IMAGE_GROUP_ANNOTATIONS_REQUEST:
            return handleUPDATE_RESOURCE_IMAGE_GROUP_ANNOTATIONS_REQUEST(state, payload)
        case UPDATE_RESOURCE_IMAGE_GROUP_ANNOTATIONS:
            return handleUPDATE_RESOURCE_IMAGE_GROUP_ANNOTATIONS(state, payload)
        case UPDATE_RESOURCE_IMAGE_ANNOTATIONS_REQUEST:
            return handleUPDATE_RESOURCE_IMAGE_ANNOTATIONS_REQUEST(state, payload)
        case UPDATE_RESOURCE_IMAGE_ANNOTATIONS:
            return handleUPDATE_RESOURCE_IMAGE_ANNOTATIONS(state, payload)
        case UPDATE_RESOURCE_MULTIPLE_IMAGE_ANNOTATIONS:
            return handleUPDATE_RESOURCE_MULTIPLE_IMAGE_ANNOTATIONS(state, payload)
        case UPDATE_RESOURCE_VIDEO_ANNOTATIONS_REQUEST:
            return handleUPDATE_RESOURCE_VIDEO_ANNOTATIONS_REQUEST(state, payload)
        case UPDATE_RESOURCE_VIDEO_ANNOTATIONS:
            return handleUPDATE_RESOURCE_VIDEO_ANNOTATIONS(state, payload)
        case UPDATE_RESOURCE_REMARKS_REQUEST:
            return handleUPDATE_RESOURCE_REMARKS_REQUEST(state, payload)            
        case UPDATE_RESOURCE_PROMPT:
            return handleUPDATE_RESOURCE_PROMPT(state, payload)            
        case UPDATE_RESOURCE_REMARKS:
            return handleUPDATE_RESOURCE_REMARKS(state, payload)  
        case SET_DISCARDED_COUNT:
            return handleITEMS_UPDATED_COUNT(state, payload, CounterAction.DISCARDED)     
        case SET_SOLD_COUNT:
            return handleITEMS_UPDATED_COUNT(state, payload, CounterAction.SOLD)    
        case SET_ADD_COUNT:
            return handleITEMS_UPDATED_COUNT(state, payload, CounterAction.ADDED)
        case UPDATE_WHICH_RESOURCES_TO_BE_UPDATED_IN_DB:
            return {
                ...state,
                resourcesWhoseDataSetCollectionsAreBeingUpdatedInDB: payload
            }
        case SET_RESOURCE_DATA_BOOST:
            return{
                ...state,
                data:{
                    ...state.data,
                    resources: handleUpdateDataBoost(state.data.resources, payload)
                }
            }
        default:
            return state
    }
}

function handleUpdateDataBoost(resources: IData[], payload: {resourceId: string, dataBoost: number}): IData[] {

    const resultResources = [...resources];

    const resourceToUpdate =  resultResources.find(resource => resource._id === payload.resourceId)

    if(resourceToUpdate){
        resourceToUpdate.dataBoost = payload.dataBoost + "";
    }

    return resultResources;
}

function handleITEMS_UPDATED_COUNT(
    state: IDataState,
    payload: UpdateItemsCountPayload,
    action: string
): IDataState {
    if(state.data.resources?.length === 0){
        return state
    }
    const resources = [...state.data.resources]
    const resourceIndex = resources.findIndex( resource => resource._id === payload.resourceId);
    const isFirstItemUpdated = payload.resourceId === payload.videoId;
    let additionalItemIndexToUpdate: number | undefined = 0;
    if(!isFirstItemUpdated){
        additionalItemIndexToUpdate = resources[resourceIndex].additionalResources?.findIndex( item => item._id === payload.videoId);
    }
    if(action === CounterAction.DISCARDED){
        if(isFirstItemUpdated){
            resources[resourceIndex] = {...resources[resourceIndex], itemsDiscardedCount:payload.count, videoDurationInSeconds: payload.videoDurationInSeconds}
        }
        else if(additionalItemIndexToUpdate !== undefined && resources[resourceIndex].additionalResources.length > 0 ){
            resources[resourceIndex].additionalResources[additionalItemIndexToUpdate] = {...resources[resourceIndex].additionalResources[additionalItemIndexToUpdate], itemsDiscardedCount : payload.count, videoDurationInSeconds: payload.videoDurationInSeconds}
            resources[resourceIndex] = {...resources[resourceIndex]}
        }
    }
    else if(action === CounterAction.SOLD){
        if(isFirstItemUpdated){
            resources[resourceIndex] = {...resources[resourceIndex], itemsSoldCount:payload.count, videoDurationInSeconds: payload.videoDurationInSeconds}
        }
        else if(additionalItemIndexToUpdate !== undefined && resources[resourceIndex].additionalResources.length > 0 ){
            resources[resourceIndex].additionalResources[additionalItemIndexToUpdate] = {...resources[resourceIndex].additionalResources[additionalItemIndexToUpdate], itemsSoldCount : payload.count, videoDurationInSeconds: payload.videoDurationInSeconds}
            resources[resourceIndex] = {...resources[resourceIndex]}
        }
    }
    else if(action === CounterAction.ADDED){
        if(isFirstItemUpdated){
            resources[resourceIndex] = {...resources[resourceIndex], itemsAddCount:payload.count, videoDurationInSeconds: payload.videoDurationInSeconds}
        }
        else if(additionalItemIndexToUpdate !== undefined && resources[resourceIndex].additionalResources.length > 0 ){
            resources[resourceIndex].additionalResources[additionalItemIndexToUpdate] = {...resources[resourceIndex].additionalResources[additionalItemIndexToUpdate], itemsAddCount : payload.count, videoDurationInSeconds: payload.videoDurationInSeconds}
            resources[resourceIndex] = {...resources[resourceIndex]}
        }
    }

    return {
        ...state,
        data:{
            ...state.data,
            resources: resources
        }
    }
}
function handleGET_DATA_SUCCESS_FOR_SELECTING_ALL_RESOURCES_MATCHING_FILTER(
    state: IDataState,
    payload: GetDataSuccessForSelectingAllResourcesMatchingFilterActionPayload
): IDataState {
    return {
        ...state,
        selectedItems: [
            ...removeDuplicateElementsFromArray(
                payload
                .resources
                .map(resource=>resource._id.toString())
            )
        ],
        isFetching: false
    }
}

function handleFETCH_DATA_SET_COLLECTIONS_FOR_FILTER_REQUEST(
    state: IDataState,
    payload: FETCH_DATA_SET_COLLECTIONS_FOR_FILTER_REQUEST_ACTION_PAYLOAD
): IDataState {
    return {
        ...state,
        dataSetCollectionsBeingFetchedForFilter: true
    }
}

function handleFETCH_DATA_SET_COLLECTIONS_FOR_FILTER_SUCCESS(
    state: IDataState,
    payload: FETCH_DATA_SET_COLLECTIONS_FOR_FILTER_SUCCESS_ACTION_PAYLOAD
): IDataState {
    return {
        ...state,
        dataSetCollectionsBeingFetchedForFilter: false,
        dataSetCollectionsForFilter: [...payload.dataSetCollections]
    }
}

function handleIsEditTextSelected(
    state: IDataState,
    payload: IS_EDIT_TEXT_SELECTED_ACTION_PAYLOAD
): IDataState {
    return {
        ...state,
        shouldBlockDataTableKeyboardEvents: payload
    }
}

function handleADD_DATA_SET_COLLECTION_IN_DATA_SET_COLLECTIONS_FILTER(
    state: IDataState,
    payload: ADD_DATA_SET_COLLECTION_IN_DATA_SET_COLLECTIONS_FILTER_ACTION_PAYLOAD
): IDataState {
    let doesCollectionAlreadyExist: boolean = false;
    for (const collection of state.dataSetCollectionsForFilter) {
        if (collection._id.toString() === payload.dataSetCollectionToAdd._id.toString()) {
            doesCollectionAlreadyExist = true;
            break;  
        }
    }
    const dataSetCollections = state.dataSetCollectionsForFilter;
    if (!doesCollectionAlreadyExist) {
        dataSetCollections.push(payload.dataSetCollectionToAdd)
    }
    return {
        ...state,
        dataSetCollectionsBeingFetchedForFilter: false,
        dataSetCollectionsForFilter: [...dataSetCollections]
    }
}

// const initialState1 = {
//     data: {
//         images: [],
//         totalCount: 0
//     },
//     collections: {
//         collections: [],
//         totalCount: 0
//     },
//     isFetching: false,
//     isAdding: false,
//     isCollectionsFetching: false,
//     drawerType: 'analytics',
//     approvedWaitingList: [],
//     deletedWaitingList: [],
//     error: false,
//     selectedIndex: -1,
//     qParams: {
//         status: 'backlog',
//         limit: 10,
//         offset: 1,
//     },
//     isUpdating: false,
//     showUpdateStatus: false,
//     isTagsFetching: false,
//     tags: [],
//     model: '6094fe053bddac17dca44792',
// }

// const modelData = (state: IDataState = initialState, action: { type: string, payload: IData & IDataUpdate & { selectedIndex: number } & { data: IData, selectedIndex: number } & ITags & ICollectionsResponse }) => {
//     const { type, payload } = action;
//     console.log('test:', type, payload);
//     switch (type) {
//         case SET_QUERY_PARAMS:
//             return {
//                 ...state,
//                 qParams: payload
//             }
//         case SHOW_ADD_COLLECTION:
//             return {
//                 ...state,
//                 drawerType: 'addCollection'
//             }
//         case GET_DATA:
//             return {
//                 ...state,
//                 isFetching: true,
//             };
//         case GET_DATA_SUCCESS:
//             return {
//                 ...state,
//                 isFetching: false,
//                 data: payload,
//             };
//         case APPROVE_DATA:
//             return {
//                 ...state,
//                 isUpdating: true,
//                 approvedWaitingList: [...state.approvedWaitingList, ...payload.id]
//             };
//         case APPROVE_DATA_SUCCESS:
//             return {
//                 ...state,
//                 isUpdating: false,
//                 showUpdateStatus: true,
//             };
//         case APPROVE_DATA_COMPLETE:
//             return {
//                 ...state,
//                 showUpdateStatus: false,
//                 approvedWaitingList: state.approvedWaitingList.filter(list => list !== payload._id)
//             };
//         case DELETE_DATA:
//             return {
//                 ...state,
//                 isUpdating: true,
//                 deletedWaitingList: [...state.deletedWaitingList, ...payload.id]
//             };
//         case DELETE_DATA_SUCCESS:
//             return {
//                 ...state,
//                 isUpdating: false,
//                 showUpdateStatus: true,
//             };
//         case DELETE_DATA_COMPLETE:
//             return {
//                 ...state,
//                 showUpdateStatus: false,
//                 deletedWaitingList: state.deletedWaitingList.filter(list => list !== payload._id)
//             };
//         case CLEAR_DATA:
//             return [];
//         case SELECT_DATA:
//             const { images: temp1 } = state.data;
//             return {
//                 ...state,
//                 selected: temp1[payload?.selectedIndex || 0],
//                 selectedIndex: payload?.selectedIndex,
//                 selectedCollectionIndex: -1,
//                 selectedCollection: null,
//                 drawerType: 'dataSelected',
//             };
//         case SELECT_DATA_UPDATE:
//             const { selectedIndex } = state;
//             const { images: temp2 } = state.data;
//             return {
//                 ...state,
//                 selected: temp2[selectedIndex],
//             };
//         case GET_TAGS_INIT:
//             return {
//                 ...state,
//                 isTagsFetching: true,
//             }
//         case GET_TAGS_SUCCESS:
//             return {
//                 ...state,
//                 isTagsFetching: false,
//                 tags: payload.tags
//             }
//         case ADD_COLLECTION_INIT:
//             return {
//                 ...state,
//                 isAdding: true,
//                 isCollectionCreationComplete: false,
//             }
//         case ADD_COLLECTION_SUCCESS:
//             return {
//                 ...state,
//                 isAdding: false,
//                 selectedCollection: payload,
//                 drawerType: 'collectionSelected',
//             }
//         case ADD_COLLECTION_COMPLETE:
//             return {
//                 ...state,
//                 isCollectionCreationComplete: true,
//             }
//         case GET_COLLECTIONS_INIT:
//             return {
//                 ...state,
//                 isCollectionsFetching: true,
//             }
//         case GET_COLLECTIONS_SUCCESS:
//             return {
//                 ...state,
//                 isCollectionsFetching: false,
//                 collections: payload
//             }
//         case SELECT_COLLECTION:
//             const { collections: temp3 } = state.collections;
//             return {
//                 ...state,
//                 selectedCollection: temp3[payload?.selectedIndex || 0],
//                 selectedCollectionIndex: payload?.selectedIndex,
//                 selected: null,
//                 selectedIndex: -1,
//                 drawerType: 'collectionSelected',
//             };
//         default:
//             return state
//     }
// }

export default data;

export function removeResourceIdsWhichAreAddedToDataSetCollectionInDB(resourceIds: string[], updatedResourcesWhichAreBeingAddedToDataSetCollectionInDB: string[], resourcesInDB?: {_id: string}[]): string[] {
    if (resourceIds) {
        for (let resourceIdIndex = 0; resourceIdIndex < resourceIds.length; resourceIdIndex++) {
        const resourceId = resourceIds[resourceIdIndex];
        const resourceIdIndexWhichWasAddedToDataSetCollection = updatedResourcesWhichAreBeingAddedToDataSetCollectionInDB.indexOf(resourceId);
        if (resourceIdIndexWhichWasAddedToDataSetCollection >= 0) {
            updatedResourcesWhichAreBeingAddedToDataSetCollectionInDB.splice(resourceIdIndexWhichWasAddedToDataSetCollection, 1);
        }
    }
    } else if (resourcesInDB) {
        for (let resourceIdIndex = 0; resourceIdIndex < resourcesInDB.length; resourceIdIndex++) {
            const resourceId = resourcesInDB[resourceIdIndex]._id;
            const resourceIdIndexWhichWasAddedToDataSetCollection = updatedResourcesWhichAreBeingAddedToDataSetCollectionInDB.indexOf(resourceId);
            if (resourceIdIndexWhichWasAddedToDataSetCollection >= 0) {
                updatedResourcesWhichAreBeingAddedToDataSetCollectionInDB.splice(resourceIdIndexWhichWasAddedToDataSetCollection, 1);
            }
        }
    }
    return updatedResourcesWhichAreBeingAddedToDataSetCollectionInDB;
}

function handleUPDATE_RESOURCE_IMAGE_GROUP_ANNOTATIONS_REQUEST(state: IDataState, payload: UPDATE_RESOURCE_IMAGE_GROUP_ANNOTATIONS_REQUEST_Action_Payload): IDataState {

    const updatedResources = state.data.resources;

    for (const resource of updatedResources) {
        if (resource._id.toString() === payload.resourceId.toString()) {
            resource.areImageKeyPointsBeingUpdatedInUI = true;
            break;
        }
    }

    return {
        ...state,
        data: {
            ...state.data,
            resources: [...updatedResources]
        }
    }
}

function handleUPDATE_RESOURCE_IMAGE_GROUP_ANNOTATIONS(state: IDataState, payload: UPDATE_RESOURCE_IMAGE_GROUP_ANNOTATIONS_Action_Payload): IDataState {

    const updatedResources = state.data.resources;

    for (const resource of updatedResources) {
        if (resource._id.toString() === payload.resourceId.toString()) {
            resource.imageGroupAnnotations = payload.imageGroupAnnotations;
            resource.areImageKeyPointsBeingUpdatedInUI = false;
            break;
        }
    }

    return {
        ...state,
        data: {
            ...state.data,
            resources: [...updatedResources]
        }
    }
}

function handleUPDATE_RESOURCE_IMAGE_ANNOTATIONS_REQUEST(state: IDataState, payload: UPDATE_RESOURCE_IMAGE_ANNOTATIONS_REQUEST_Action_Payload): IDataState {

    const updatedResources = state.data.resources;

    for (const resource of updatedResources) {
        if (resource._id.toString() === payload.resourceId.toString()) {
            resource.areImageKeyPointsBeingUpdatedInUI = true;
            break;
        }
    }

    return {
        ...state,
        data: {
            ...state.data,
            resources: [...updatedResources]
        }
    }
}

function handleUPDATE_RESOURCE_IMAGE_ANNOTATIONS(state: IDataState, payload: UPDATE_RESOURCE_IMAGE_ANNOTATIONS_Action_Payload): IDataState {

    const updatedResources = state.data.resources;
    console.log(updatedResources,"UPDT")
    for (const resource of updatedResources) {
        if (resource._id.toString() === payload.resourceId.toString()) {
            resource.imageAnnotations = payload.imageAnnotations;
            resource.areImageKeyPointsBeingUpdatedInUI = false;
            break;
        }
    }

    return {
        ...state,
        data: {
            ...state.data,
            resources: [...updatedResources]
        }
    }
}
function handleUPDATE_RESOURCE_MULTIPLE_IMAGE_ANNOTATIONS(state: IDataState, payload: UPDATE_RESOURCE_MULTIPLE_IMAGE_ANNOTATIONS_Action_Payload): IDataState {

    const updatedResources = state.data.resources;
  
    for (const resource of updatedResources) {
        if (resource && resource._id && resource._id.toString() === payload.resourceId.toString()) {
            for(const file of resource?.files || [])
            {
                if(file._id.toString()===payload.fileId.toString())
                {
                    file.imageAnnotations=[...(payload.imageAnnotations || [])];
                    break;
                }
            }
           break; 
        }
    }

    return {
        ...state,
        data: {
            ...state.data,
            resources: [...updatedResources]
        }
    }
}

function handleUPDATE_RESOURCE_VIDEO_ANNOTATIONS_REQUEST(state: IDataState, payload: { resourceId: string }): IDataState {

  const updatedResources = state.data.resources;

  for (const resource of updatedResources) {
      if (resource._id.toString() === payload.resourceId.toString()) {
          resource.arevideoAnnotationsBeingUpdatedInDB = true;
          break;
      }
  }

  return {
      ...state,
      data: {
          ...state.data,
          resources: [...updatedResources]
      }
  }
}

function handleUPDATE_RESOURCE_VIDEO_ANNOTATIONS(state: IDataState, payload: UPDATE_RESOURCE_VIDEO_ANNOTATIONS_Action_Payload): IDataState {

  const updatedResources = state.data.resources;

  for (const resource of updatedResources) {
    if (resource._id.toString() === payload.resourceId.toString()) {
      resource.videoAnnotations = payload.videoAnnotations;
      resource.arevideoAnnotationsBeingUpdatedInDB = false;
      break;
    }
  }

  return {
    ...state,
    data: {
      ...state.data,
      resources: [...updatedResources]
    }
  }
}

function handleUPDATE_RESOURCE_REMARKS_REQUEST(state: IDataState, payload: UPDATE_RESOURCE_REMARKS_REQUEST_Action_Payload): IDataState {
    const resources = copyByValue(state.data.resources);
    for (const resource of resources) {
        if (resource._id === payload.resourceId) {
            resource.isResourceRemarksBeingUpdatedInDB = true;
        }
    }
    return {
        ...state,
        data: {
            ...state.data,
            resources: [
                ...resources
            ]
        }
    }
}

function handleUPDATE_RESOURCE_REMARKS(state: IDataState, payload: UPDATE_RESOURCE_REMARKS_Action_Payload): IDataState {
    const resources = copyByValue(state.data.resources);
    for (const resource of resources) {
        if (resource._id === payload.resourceId) {
            resource.isResourceRemarksBeingUpdatedInDB = false;
            resource.remarks = payload.resourceRemarks;
        }
    }
    return {
        ...state,
        data: {
            ...state.data,
            resources: [
                ...resources
            ]
        }
    }
}

function handleUPDATE_RESOURCE_PROMPT(state: IDataState, payload: UPDATE_RESOURCE_PROMPT_Action_Payload): IDataState {
    const resources = copyByValue(state.data.resources);
    for (const resource of resources) {
        if (resource._id === payload.resourceId) {
            if (payload.resource !== undefined && payload.resource !== null)
              resource.resource = payload.resource;
            if (payload.ansOfPrompt !== undefined && payload.ansOfPrompt !== null)
              resource.ansOfPrompt = payload.ansOfPrompt;
          break;
        }
    }
    return {
        ...state,
        data: {
            ...state.data,
            resources: [
                ...resources
            ]
        }
    }
}

function handleUPDATE_DATA_REQUEST(state: IDataState, payload: UPDATE_DATA_REQUEST_Action_Payload): IDataState {
    const temp1: 
    { 
        approvedWaitingList?: string[], 
        deletedWaitingList?: string[],
        approvalWaitingList?: string[],
        rejectedWaitingList?: string[]
    } 
    = {};
    if (payload.status === 'deleted') {
        temp1.deletedWaitingList = [...state.deletedWaitingList, ...payload.id]
    } else if (payload.status === 'approval') {
        temp1.approvalWaitingList = [...state.approvalWaitingList, ...payload.id]
    } else if (payload.status === 'rejected') {
        temp1.rejectedWaitingList = [...state.rejectedWaitingList, ...payload.id]
    } else {
        temp1.approvedWaitingList = [...state.approvedWaitingList, ...payload.id]
    }
    return {
        ...state,
        isUpdating: true,
        ...temp1
    };    
}

function handleDELETE_RESOURCE_TRIMMED_TEXT_IN_UI(state: IDataState, payload: deleteResourceTrimmedTextInUIActionPayload): IDataState {
    const updatedResources = state.data.resources;

    for (let resourceIndex = 0; resourceIndex < updatedResources.length; resourceIndex++) {
        const resource = updatedResources[resourceIndex];
        if (resource._id === payload.resourceId) {
            if (resource.trimmedTexts) {
                for (let trimTextObjectIndex = 0; trimTextObjectIndex < resource.trimmedTexts.length; trimTextObjectIndex++) {
                    const trimTextObject = resource.trimmedTexts[trimTextObjectIndex];
                    if (trimTextObject._id === payload.idOfTrimTextToDelete) {
                        resource.trimmedTexts.splice(trimTextObjectIndex, 1);
                        resource.trimmedTexts = [...resource.trimmedTexts];
                        break;
                    }
                }
            }
            break;
        }
    }

    return {
        ...state,
        data: {
            ...state.data,
            resources: [...updatedResources]
        }
    }
}

function handleUPDATE_DATA_SUCCESS(state: IDataState, payload: UPDATE_DATA_SUCCESS_Action_Payload): IDataState {
    const temp2: { approvedWaitingList?: string[], deletedWaitingList?: string[], approvalWaitingList?: string[], rejectedWaitingList?: string[] } = {};
    if (payload.status === 'deleted') {
        temp2.deletedWaitingList = state.deletedWaitingList.filter(list => !payload.id.includes(list))
    } else if (payload.status === 'approval') {
        temp2.approvalWaitingList = state.approvalWaitingList.filter(list => !payload.id.includes(list))
    } else if (payload.status === 'rejected') {
        temp2.rejectedWaitingList = state.rejectedWaitingList.filter(list => !payload.id.includes(list))
    } else {
        temp2.approvedWaitingList = state.approvedWaitingList.filter(list => !payload.id.includes(list))
    }
    return {
        ...state,
        isUpdating: false,
        ...temp2
    };    
}
function handleUPDATE_RESOURCE_IN_UI(state: IDataState, payload: IData): IDataState {

    const resourcesToUpdate = state.data.resources;

    for (let index = 0; index < resourcesToUpdate.length; index++) {
        let resource = resourcesToUpdate[index];
        if (resource._id.toString() === payload._id.toString()) {
            resource = {
                ...resource,
                ...payload
            };
            resourcesToUpdate[index] = resource
        }
        
    }

    return {
        ...state,
        data: {
            ...state.data,
            resources: [
                ...resourcesToUpdate
            ]
        }
    }
}

function handleSET_IS_FETCHING_RESOURCE_TEXT_FILE_CONTENTS(state: IDataState, payload: SET_IS_FETCHING_RESOURCE_TEXT_FILE_CONTENTSActionPayload): IDataState {
    const resourcesToUpdate = state.data?.resources || [];
    if (resourcesToUpdate?.length>0) {
        for (let resourceIndex = 0; resourceIndex < resourcesToUpdate.length; resourceIndex++) {
            const resource = resourcesToUpdate[resourceIndex];
            if (resource._id === payload.resourceIdToUpdate) {
                resource.isFetchingTextFileContents = payload.isFetching;
                resourcesToUpdate[resourceIndex] = {...resource}
                break;
            }
        }
    }

    return {
        ...state,
            data: {
                ...state.data,
                resources: [
                    ...resourcesToUpdate
                ]
            }
    }
}

function handleSET_RESOURCE_TEXT_FILE_CONTENTS(state: IDataState, payload: SET_RESOURCE_TEXT_FILE_CONTENTSActionPayload): IDataState {
    const resourcesToUpdate = state.data?.resources || [];
    if (resourcesToUpdate?.length>0) {
        for (let resourceIndex = 0; resourceIndex < resourcesToUpdate.length; resourceIndex++) {
            const resource = resourcesToUpdate[resourceIndex];
            if (resource._id === payload.resourceIdToUpdate) {
                resource.textFileContent = payload.textContentToSet;
                resourcesToUpdate[resourceIndex] = {...resource}
                break;
            }
        }
    }

    return {
        ...state,
            data: {
                ...state.data,
                resources: [
                    ...resourcesToUpdate
                ]
            }
    }
}