// @ts-ignore
import JSCache from "js-cache";

// type ConstructorParams = {
//     shouldSaveInLocalStorage?: false
// }

export class Cache {
  cache;

  constructor() {
    this.cache = new JSCache();
  }

  set(key: string, value: string, timeToLiveInMilliSeconds?: number) {
    this.cache.set(key, value, timeToLiveInMilliSeconds);
  }

  get(key: string): string {
    return this.cache.get(key);
  }
}
